import React from "react";
import { useDispatch } from "react-redux";
import { Chip } from "@mui/material";

import { showDevice } from "../../../../actions/devicesActions";
import { useBlockchainNetwork } from "../../../../providers/BlockchainNetworkProvider/BlockchainNetworkProvider";

interface AddressFormatterProps {
  address: string;
}

const AddressFormatter: React.FC<AddressFormatterProps> = ({ address }) => {
  const { dsReadService } = useBlockchainNetwork();
  const dispatch = useDispatch();

  const onClicked = () => {
    if (!dsReadService) return;
    dispatch(showDevice(dsReadService, address));
  };

  return (
    <Chip onClick={onClicked} color="primary" size="small" label={address} />
  );
};

export default AddressFormatter;
