import { useSelector } from "react-redux";
import { IRootReducer } from "../../../reducers";
import { Grid, Paper } from "@mui/material";

import ShowDeviceDetails from "./ShowDeviceDetails.component";
import ShowDeviceAddress from "./ShowDeviceAddress.component";
import ShowDeviceBasics from "./ShowDeviceBasics.component";
import DeviceMap from "../DeviceMap/DeviceMap.component";

const ShowDevice = () => {
  const device = useSelector((state: IRootReducer) => state.devices.show);

  if (!device) return null;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h1>{device.name}</h1>
        <h5>{device.address}</h5>
        <p>{device.description}</p>
      </Grid>
      <Grid item md={12} lg={6}>
        <Grid item sx={{ mb: 3 }}>
          <Paper sx={{ p: 1 }}>
            <ShowDeviceBasics device={device} />
          </Paper>
        </Grid>
        {device.details && (
          <Grid item>
            <Paper sx={{ p: 1 }}>
              <ShowDeviceDetails details={device.details} />
            </Paper>
          </Grid>
        )}
      </Grid>
      <Grid item md={12} lg={6}>
        {device.details?.physicalAddress && (
          <Grid item sx={{ mb: 3 }}>
            <Paper sx={{ p: 1 }}>
              <ShowDeviceAddress
                physicalAddress={device.details?.physicalAddress}
              />
            </Paper>
          </Grid>
        )}
        {device.lat && device.lng && (
          <Grid item>
            <Paper sx={{ p: 1 }}>
              <DeviceMap device={device} />
            </Paper>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ShowDevice;
