import Modal from "../../UI/Modal/Modal.component";
import { useKeeper } from "../../../providers/KeeperProvider/KeeperProvider";
import * as Views from "./views";
import { WalletStatus } from "../../../providers/KeeperProvider";
import { useMemo } from "react";

const KeeperDetectorModal = (props: any) => {
  const { status } = useKeeper();

  const renderSwitch = (status: WalletStatus) => {
    switch (status) {
      case "refused":
        return <Views.Rejected />;
      case "not_available":
        return <Views.NotAvailable />;
      case "loading":
        return <Views.Pending />;
      case "available":
        return <Views.Available />;
      default:
        return undefined;
    }
  };

  const content = useMemo(() => {
    return renderSwitch(status);
  }, [status]);

  return (
    <Modal closable={false} show={!!content}>
      {content}
    </Modal>
  );
};

export default KeeperDetectorModal;
