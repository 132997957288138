import React from "react";

import { useKeeper } from "../providers/KeeperProvider/KeeperProvider";

export type PublicKeyVerificationState =
  | "init"
  | "unavailable"
  | "fetching"
  | "verified"
  | "notVerified"
  | "fetchFailed";

export interface IPublicKeyVerificationState {
  requestState: PublicKeyVerificationState;
  setRequestState: React.Dispatch<
    React.SetStateAction<PublicKeyVerificationState>
  >;
}

const usePublicKeyVerification = (): IPublicKeyVerificationState => {
  const [requestState, setRequestState] =
    React.useState<PublicKeyVerificationState>("init");

  const { readService, publicState } = useKeeper();

  const publicAddress = React.useMemo(() => {
    return publicState?.account?.address;
  }, [publicState?.account?.address]);

  const publicKey = React.useMemo(() => {
    return publicState?.account?.publicKey;
  }, [publicState?.account?.publicKey]);

  React.useEffect(() => {
    if (!publicAddress || !readService || !publicKey) {
      setRequestState("unavailable");
      return;
    }

    setRequestState("fetching");

    readService
      .fetchPublicKey(publicAddress)
      .then((remotePublicKey) => {
        if (!remotePublicKey) {
          setRequestState("notVerified");
          return;
        }

        setRequestState(
          publicKey === remotePublicKey ? "verified" : "notVerified"
        );
      })
      .catch(() => {
        setRequestState("fetchFailed");
      });
  }, [publicAddress, readService, publicKey]);

  return { requestState, setRequestState };
};

export default usePublicKeyVerification;
