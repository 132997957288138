import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Typography, Grid } from "@mui/material";
import { clamp } from "lodash";

import Modal from "../../UI/Modal/Modal.component";

import { IRootReducer } from "../../../reducers";
import { sendTokenCancel } from "../../../actions/tokensActions";
import SendTokenForm from "../../Tokens/Forms/SendTokenForm/SendTokenForm.component";
import TokensCountChip from "./TokensCountChip.compoent";

const SendTokenModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { sendToken } = useSelector((state: IRootReducer) => state.tokens);
  const [availableTokens, setAvailableTokens] = useState<number>(0);

  let content = null;

  const onClose = () => {
    dispatch(sendTokenCancel());
  };

  const handleAmountChanged = useCallback(
    (totalTokens: number) => {
      if (!sendToken) return;

      setAvailableTokens(
        clamp(sendToken.balance - totalTokens, 0, sendToken.balance)
      );
    },
    [sendToken]
  );

  if (sendToken) {
    content = (
      <React.Fragment>
        <Typography variant="h4">
          {t("forms.sendToken.header.title")}
        </Typography>

        <Grid container spacing={2} sx={{ marginBottom: "2em" }}>
          <Grid item xs={6}>
            <Typography variant="h5">
              {t("forms.sendToken.header.tokenName", {
                name: sendToken.details?.name,
              })}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent="flex-end">
              <TokensCountChip
                available={availableTokens}
                total={sendToken.balance}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <SendTokenForm
              handleClose={onClose}
              onAmountChanged={handleAmountChanged}
              availableTokensCount={availableTokens}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <Modal closable={true} show={sendToken !== undefined} handleClose={onClose}>
      {content}
    </Modal>
  );
};

export default SendTokenModal;
