import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IRootReducer } from "../../../reducers";
import Modal from "../../UI/Modal/Modal.component";
import { deviceKeysBurnCancel } from "../../../actions/devicesActions";
import { keysRemoved } from "../../../actions/keysActions";
import { Key, KeyCollection } from "../../Keys/Key";
import * as Views from "../ConfirmBurnManyKeysModal/views";

import useMassKeyBurn, {
  BurnStatus,
  GroupedTransactions,
} from "../../../hooks/useMassKeyBurn";
import { useOrgManager } from "../../../providers/OrgManagerProvider/OrgManagerProvider";

const ConfirmBurnDeviceKeysModal = (props: any) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [result, setResult] = useState<GroupedTransactions | undefined>();
  const dispatch = useDispatch();
  const keysBurn = useSelector((state: IRootReducer) => state.devices.keysBurn);
  const { writeService } = useOrgManager();

  const burnProcessState = useMassKeyBurn();

  const onClose = useCallback(() => {
    burnProcessState.reset();
    dispatch(deviceKeysBurnCancel());
  }, [dispatch, burnProcessState]);

  const burnMultipleKeys = useCallback(async () => {
    if (!keysBurn || !writeService) {
      throw new Error("No keys found");
    }

    const { keys } = keysBurn;

    const deletedKeys: KeyCollection = {};
    const notDeletedKeys: KeyCollection = {};

    await burnProcessState
      .perform(Object.values(keys))
      .then(({ published, notPublished }) => {
        published.forEach((publishedTx) => {
          const assetId =
            writeService.getAssetIdFromBurnTransaction(publishedTx);
          if (assetId) {
            deletedKeys[assetId] = keys[assetId];
          }
        });

        notPublished.forEach((notPublishedTx) => {
          const assetId =
            writeService.getAssetIdFromBurnTransaction(notPublishedTx);
          if (assetId) {
            notDeletedKeys[assetId] = keys[assetId];
          }
        });

        setResult({ published, notPublished });

        const deletedKeysArray: Key[] = Object.values(deletedKeys);

        if (deletedKeysArray.length > 0) {
          dispatch(keysRemoved(deletedKeysArray));
        }
      });

    return { deletedKeys, notDeletedKeys };
  }, [burnProcessState, keysBurn, writeService, dispatch]);

  const onConfirm = useCallback(() => {
    if (!keysBurn) return;

    setSubmitting(true);

    burnMultipleKeys().finally(() => {
      setSubmitting(false);
    });
  }, [burnMultipleKeys, keysBurn]);

  const switchContent = useCallback(
    (status: BurnStatus): JSX.Element | undefined => {
      switch (status) {
        case "init":
          return (
            <Views.Confirm
              totalCount={keysBurn?.keys && Object.keys(keysBurn.keys).length}
              onConfirm={onConfirm}
              onClose={onClose}
            />
          );
        case "performing":
          return <Views.Perfroming counters={burnProcessState.counters} />;
        case "finished":
          return <Views.Finished transactions={result} onClose={onClose} />;
        default:
          return undefined;
      }
    },
    [burnProcessState, onClose, onConfirm, result, keysBurn?.keys]
  );

  const content = useMemo(() => {
    return switchContent(burnProcessState.status);
  }, [burnProcessState.status, switchContent]);

  return (
    <Modal
      closable={!submitting}
      show={keysBurn !== undefined}
      handleClose={submitting ? undefined : onClose}
    >
      {content}
    </Modal>
  );
};

export default ConfirmBurnDeviceKeysModal;
