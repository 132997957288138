import React from "react";
import { ButtonGroup, Button, Typography } from "@mui/material";

import Modal from "../../UI/Modal/Modal.component";
import { useTranslation } from "react-i18next";

interface ConfirmationModalProps {
  show?: boolean;
  onConfirm?: () => any;
  onClose?: () => any;
  children?: React.ReactChild;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  show,
  onConfirm,
  onClose,
  children,
}) => {
  const { t } = useTranslation();

  const content = children ?? (
    <Typography variant="h6">{t("messages.defaults.confirm")}</Typography>
  );

  const footer = (
    <ButtonGroup>
      <Button variant="contained" color="success" onClick={onConfirm}>
        {t("buttons.confirm")}
      </Button>
      <Button variant="contained" color="error" onClick={onClose}>
        {t("buttons.deny")}
      </Button>
    </ButtonGroup>
  );

  return (
    <Modal closable={true} show={show} handleClose={onClose} footer={footer}>
      {content}
    </Modal>
  );
};

export default ConfirmationModal;
