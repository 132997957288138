const KEEPER_MESSAGE_ENCRYPTION_SALT = "SkeyNetwork";

export default class KeeperMessageEncryptor {
  private account: WavesKeeper.TPublicStateAccount;
  private keeper: WavesKeeper.TWavesKeeperApi;

  constructor(
    keeper: WavesKeeper.TWavesKeeperApi,
    account: WavesKeeper.TPublicStateAccount
  ) {
    this.account = account;
    this.keeper = keeper;
  }

  get publicKey(): string {
    return this.account.publicKey;
  }

  async encrypt(message: string, recipientPublicKey?: string): Promise<string> {
    return await this.keeper.encryptMessage(
      message,
      recipientPublicKey ?? this.publicKey,
      KEEPER_MESSAGE_ENCRYPTION_SALT
    );
  }

  async decrypt(encMessage: string, senderPublicKey?: string): Promise<string> {
    return await this.keeper.decryptMessage(
      encMessage,
      senderPublicKey ?? this.publicKey,
      KEEPER_MESSAGE_ENCRYPTION_SALT
    );
  }
}
