import { Account } from "../../../../../helpers/accountUtils";
import { Grid, TextField, IconButton, Tooltip } from "@mui/material";
import { Loop, CopyAll } from "@mui/icons-material";

import CopyButton from "../../../../UI/CopyButton/CopyButton.component";
import { useTranslation } from "react-i18next";

interface SeedInputProps {
  account: Account;
  onRefreshClicked: () => any;
  refreshDisabled: boolean;
}

const SeedInput: React.FC<SeedInputProps> = ({
  account,
  onRefreshClicked,
  refreshDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} sx={{ mt: 3 }}>
        <TextField
          placeholder={t("forms.manager.fields.seed.placeholder")}
          label={t("forms.manager.fields.seed.label")}
          fullWidth
          multiline
          disabled
          maxRows={4}
          value={account.seed}
        />
      </Grid>
      <Grid item xs={12}>
        <Tooltip title={t("buttons.reload") ?? "Reload"}>
          <IconButton onClick={onRefreshClicked} disabled={refreshDisabled}>
            <Loop />
          </IconButton>
        </Tooltip>
        <CopyButton content={account.seed}>
          <CopyAll />
        </CopyButton>
      </Grid>
    </>
  );
};

export default SeedInput;
