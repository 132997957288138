import React from "react";
import { ReportProblemOutlined } from "@mui/icons-material";

import Notification from "../Notification/Notification.component";

interface ErrorNotificationProps {
  title: string;
}

const ErrorNotification: React.FC<ErrorNotificationProps> = ({ title }) => {
  return (
    <Notification
      title={title}
      Icon={ReportProblemOutlined}
      iconColor="error"
    />
  );
};

export default ErrorNotification;
