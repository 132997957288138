import { Paper, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import ManagersTable from "./ManagersTable/ManagersTable.component";

const TableCard = () => {
  const { t } = useTranslation();

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h4">{t("managers.title")}</Typography>
      <ManagersTable />
    </Paper>
  );
};

export default TableCard;
