import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const UnavailableView = () => {
  const { t } = useTranslation();

  return (
    <Grid item>
      <Typography variant="body1">
        {t("publicKeyVerification.unavailable.body")}
      </Typography>
    </Grid>
  );
};

export default UnavailableView;
