import React from "react";
import { Grid, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useOrgManager } from "../../../../providers/OrgManagerProvider/OrgManagerProvider";
import { useKeeper } from "../../../../providers/KeeperProvider/KeeperProvider";
import { PublicKeyVerificationState } from "../../../../hooks/usePublicKeyVerification";
import LoadingNotification from "../../../UI/LoadingNotification/LoadingNotification.component";
import useSnackbarNotifications from "../../../../hooks/useSnackbarNotifications";

interface NotVerifiedViewProps {
  setState: React.Dispatch<React.SetStateAction<PublicKeyVerificationState>>;
}

const NotVerifiedView: React.FC<NotVerifiedViewProps> = ({ setState }) => {
  const [pending, setPending] = React.useState<boolean>(false);
  const { writeService } = useOrgManager();
  const { publicState } = useKeeper();
  const snackbar = useSnackbarNotifications();
  const { t } = useTranslation();

  const publicKey = React.useMemo(() => {
    return publicState?.account?.publicKey;
  }, [publicState?.account?.publicKey]);

  const onButtonClicked = React.useCallback(() => {
    if (!writeService || !publicKey) return;

    setPending(true);

    writeService
      .exposePublicKey(publicKey)
      .then((res) => {
        setState("verified");
      })
      .catch((e) => {
        snackbar.error(
          t("messages.keeper.transactionFailed", { message: e.message })
        );
      })
      .finally(() => {
        setPending(false);
      });
  }, [publicKey, writeService, setState, snackbar, t]);

  return (
    <React.Fragment>
      <Grid item>
        {pending && <LoadingNotification label="Waiting for transaction" />}
        {!pending && (
          <Typography variant="body1">
            {t("publicKeyVerification.notVerified.body")}
          </Typography>
        )}
      </Grid>
      <Grid item>
        <Button
          disabled={pending}
          variant="contained"
          onClick={onButtonClicked}
        >
          {t("publicKeyVerification.notVerified.sendButton")}
        </Button>
      </Grid>
    </React.Fragment>
  );
};

export default NotVerifiedView;
