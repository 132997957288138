/// <reference types="google.maps" />

import { gql } from "graphql-request";

export const getKeysQuery = gql`
  query GetKeys(
    $take: Int!
    $skip: Int!
    $order: String!
    $orderBy: String!
    $filter: KeysFilter
    $search: String
  ) {
    keys(
      take: $take
      skip: $skip
      order: $order
      orderBy: $orderBy
      filter: $filter
      search: $search
    ) {
      objects {
        assetId
        issuer
        owner
        name
        device
        validTo
        issueTimestamp
        burned
        deviceName
      }
      meta {
        total
      }
    }
  }
`;

// getAllDevices

export const getDevicesQuery = gql`
  query GetDevices(
    $take: Int!
    $skip: Int!
    $order: String
    $orderBy: String
    $filter: DevicesFilter
    $geoSearch: GeoSearchInput
    $keysOwner: String
    $search: String
  ) {
    devices(
      take: $take
      skip: $skip
      order: $order
      orderBy: $orderBy
      filter: $filter
      geoSearch: $geoSearch
      keysOwner: $keysOwner
      search: $search
    ) {
      objects {
        address
        name
        visible
        connected
        lat
        lng
        details {
          deviceType
          deviceModel
        }
        key {
          assetId
          name
          validTo
          createdAt
          updatedAt
        }
      }
      meta {
        total
      }
    }
  }
`;

// GetDevice

export const getDeviceQuery = (address: string) => gql`
  {
    device(address: "${address}") {
      address
      supplier
      owner
      name
      description
      lat
      lng
      alt
      visible
      connected
      details {
        deviceType
        deviceModel
        additionalDescription
        assetUrl
        url
        contactInfo
        physicalAddress {
          addressLine1
          addressLine2
          city
          postcode
          state
          country
          number
          floor
        }
      }
      custom
      createdAt
      updatedAt
    }
  }
`;

export const getDeviceKeysQuery = gql`
  query GetDeviceKeys($owner: String!, $device: String!) {
    keys(
      order: "asc"
      orderBy: "address"
      skip: 0
      take: 100
      filter: { owner: $owner, device: $device, burned: false }
    ) {
      objects {
        assetId
        name
        validTo
        createdAt
        updatedAt
      }
    }
  }
`;

export const managedOrganisationsQuery = gql`
  query GetManagedOrganisations(
    $manager: String!
    $take: Int!
    $skip: Int!
    $order: String!
    $orderBy: String!
  ) {
    organisations(
      skip: $skip
      take: $take
      order: $order
      orderBy: $orderBy
      manager: $manager
    ) {
      objects {
        address
        name
        description
      }
      meta {
        total
      }
    }
  }
`;

export const organisationQuery = gql`
  query GetOrganisationQuery($address: String!) {
    organisation(address: $address) {
      address
      name
      description
    }
  }
`;

export const getStatsQuery = gql`
  query getStats($id: String!) {
    organisationStats(id: $id) {
      current {
        data {
          devices {
            all
            active_false
            active_true
            connected_false
            connected_true
            visible_false
            visible_true
          }
          keys {
            all
            burned_false
            burned_true
          }
          users {
            whitelisted
          }
        }
      }
    }
  }
`;
