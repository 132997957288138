import React from "react";
import { Grid, Typography } from "@mui/material";

import { useOrgManager } from "../../../providers/OrgManagerProvider/OrgManagerProvider";
import Modal from "../../UI/Modal/Modal.component";
import LoadingNotification from "../../UI/LoadingNotification/LoadingNotification.component";

import UnavailableView from "./views/Unavailable.view";
import NotVerifiedView from "./views/NotVerified.view";
import { useTranslation } from "react-i18next";
import FetchFailed from "./views/FetchFailed.view";

const PublicKeyVerificationModal: React.FC = () => {
  const { pkVerificationState } = useOrgManager();
  const { requestState, setRequestState } = pkVerificationState;
  const { t } = useTranslation();

  if (requestState === "verified") return <></>;

  const retry = () => {
    setRequestState("unavailable");
  };

  let content;

  switch (requestState) {
    case "unavailable":
      content = <UnavailableView />;
      break;
    case "notVerified":
      content = <NotVerifiedView setState={setRequestState} />;
      break;
    case "fetchFailed":
      content = <FetchFailed onRetry={retry} />;
      break;
    default:
      content = <LoadingNotification />;
      break;
  }

  return (
    <Modal show>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="stretch"
            justifyContent="flex-start"
          >
            <Grid item>
              <Typography variant="h6">
                {t("publicKeyVerification.head")}
              </Typography>
            </Grid>
            {content}
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default PublicKeyVerificationModal;
