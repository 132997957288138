import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const useSkeyKeeper = !!process.env.REACT_APP_USE_SKEY_KEEPER;
const keeperName = useSkeyKeeper ? "Skey" : "Waves";

const Available: React.FC = (props) => {
  const { t } = useTranslation();

  return (
    <div style={{ textAlign: "center" }}>
      <Typography variant="h3">
        {t("keeperDetector.available.header", { keeper: keeperName })}
      </Typography>
      <Typography variant="h6">
        {t("keeperDetector.available.content", { keeper: keeperName })}
      </Typography>
    </div>
  );
};

export default Available;
