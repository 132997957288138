import { Grid, Typography } from "@mui/material";
import { Key } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useStats } from "../../../../providers/StatsProvider/StatsProvider";
import BooleanChart from "../Charts/BooleanChart.component";
import LoadingNotification from "../../../UI/LoadingNotification/LoadingNotification.component";
import {
  WarningNotification,
  ErrorNotification,
} from "../../../UI/Notifications";

const KeysCard: React.FC = () => {
  const { loading, errorMessage, stats } = useStats();
  const { t } = useTranslation();

  if (loading) {
    return <LoadingNotification label={t("common.loading")} />;
  }

  if (errorMessage) {
    return <ErrorNotification title={errorMessage} />;
  }

  if (!stats) {
    return <WarningNotification title={t("messages.notFound")} />;
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={12} md={4}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Key sx={{ fontSize: 72 }} />
          <Typography variant="h5">
            <Link to="/keys" style={{ textDecoration: "none" }}>
              {t("dashboard.stats.keys.title")}
            </Link>
          </Typography>

          <Typography variant="h5">
            {t("dashboard.stats.total", {
              total: stats.keys.all || 0,
            })}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} sx={{ minHeight: "190px" }}>
        <BooleanChart
          compliant={{
            label: t("dashboard.stats.keys.burned"),
            count: stats.keys.burned_true,
          }}
          nonCompliant={{
            label: t("dashboard.stats.keys.notBurned"),
            count: stats.keys.burned_false,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default KeysCard;
