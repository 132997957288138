import { useTranslation } from "react-i18next";
import Carousel from "react-material-ui-carousel";

import LoadingNotification from "../../../UI/LoadingNotification/LoadingNotification.component";
import { useStats } from "../../../../providers/StatsProvider/StatsProvider";
import BooleanChart from "../Charts/BooleanChart.component";

const DevicesStats: React.FC = () => {
  const { loading, stats, errorMessage } = useStats();
  const { t } = useTranslation();

  if (loading) return <LoadingNotification />;

  if (errorMessage) {
    return <span>Error: {errorMessage}</span>;
  }

  if (!stats) {
    return <span>Could not fetch data.</span>;
  }

  const { devices } = stats;

  return (
    <Carousel animation="slide" autoPlay cycleNavigation={true}>
      <BooleanChart
        compliant={{
          label: t("dashboard.stats.devices.active"),
          count: devices.active_true,
        }}
        nonCompliant={{
          label: t("dashboard.stats.devices.inactive"),
          count: devices.active_false,
        }}
      />
      <BooleanChart
        compliant={{
          label: t("dashboard.stats.devices.connected"),
          count: devices.connected_true,
        }}
        nonCompliant={{
          label: t("dashboard.stats.devices.disconnected"),
          count: devices.connected_false,
        }}
      />
      <BooleanChart
        compliant={{
          label: t("dashboard.stats.devices.visible"),
          count: devices.visible_true,
        }}
        nonCompliant={{
          label: t("dashboard.stats.devices.invisible"),
          count: devices.visible_false,
        }}
      />
    </Carousel>
  );
};

export default DevicesStats;
