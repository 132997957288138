import { User } from "../../components/Users/User";
import * as transactions from "./helpers/transactions";
import {
  AddManagerParams,
  IWriteService,
  TokenForm,
  TransactionPackageResponse,
  UpdateManagersParams,
} from ".";
import { ISendTokenForm } from "../../components/Tokens/Forms/SendTokenForm/SendTokenForm.component";
import BaseWriteService from "./BaseWriteService";

export default class KeeperWriteService
  extends BaseWriteService
  implements IWriteService
{
  setScript = async (rawScript: string): Promise<any> => {
    console.log("[OrganisationWriteService] setScript init");
    return await this.publishTransaction(transactions.setScript(rawScript));
  };

  issueToken = async (form: TokenForm) => {
    return await this.publishTransaction(transactions.issueToken(form));
  };

  reissueToken = async (token: string, amount: number) => {
    return await this.publishTransaction(
      transactions.reissueToken(token, amount)
    );
  };

  burnToken = async (assetId: string, amount: number): Promise<any> => {
    return await this.publishTransaction(
      transactions.burnTransactionData(assetId, amount)
    );
  };

  burnMultipleKeys = async (
    assetIds: string[]
  ): Promise<TransactionPackageResponse> => {
    return await this.signTransactionPackage(
      assetIds.map((assetId) => transactions.burnTransactionData(assetId, 1))
    ).then(async (signedTxes) => {
      return await this.broadcastTransactionPackage(signedTxes);
    });
  };

  sendToken = async (form: ISendTokenForm) => {
    let txData;
    if (form.recipients.length === 1) {
      const recipient = form.recipients[0];
      txData = transactions.transferTransactionData({
        assetId: form.assetId,
        address: recipient.address,
        amount: recipient.amount,
      });
    } else {
      txData = transactions.massTransfer(form.recipients, form.assetId);
    }

    return await this.publishTransaction(txData);
  };

  transferAsset = async (
    assetId: string,
    address: string,
    amount: number
  ): Promise<any> => {
    return this.publishTransaction(
      transactions.transferTransactionData({ assetId, address, amount })
    );
  };

  setTokenStatus = async (token: string, status: boolean) => {
    return this.publishTransaction(transactions.setTokenStatus(token, status));
  };

  addUser = async (user: User): Promise<User> => {
    return this.publishTransaction(transactions.addUserData(user)).then(() => {
      return user;
    });
  };

  removeUserNote = async (user: User): Promise<User> => {
    return this.publishTransaction(transactions.updateUserNote(user)).then(
      () => {
        return { ...user };
      }
    );
  };

  updateNote = async (user: User, note: string) => {
    return user;
  };

  removeUser = async (user: User): Promise<User> => {
    return this.publishTransaction(
      transactions.removeUserData(user.address)
    ).then(() => {
      return user;
    });
  };

  addManager = async (address: string, params: AddManagerParams) => {
    return this.publishTransaction(
      transactions.addManager(address, params)
    ).then((res) => {
      console.log(res);
      return res.id;
    });
  };

  updateManagersEncryptionKeysDataEntries = (
    params: UpdateManagersParams
  ): WavesKeeper.TData[] => {
    const result: WavesKeeper.TData[] = [];

    params.forEach(({ address, encKey, encIv }) => {
      transactions
        .buildUpdateManagerEncryptionKeyDataEntries(address, encKey, encIv)
        .forEach((entry) => result.push(entry));
    });

    return result;
  };

  removeManager = async (address: string) => {
    return this.publishTransaction(transactions.removeManager(address)).then(
      () => {
        return address;
      }
    );
  };

  exposePublicKey = async (publicKey: string) => {
    return await this.publishTransaction(
      transactions.exposePublicKey(publicKey)
    );
  };

  removeToken = async (assetId: string) => {
    return await this.publishTransaction(transactions.removeToken(assetId));
  };

  sendFunds = async (
    address: string,
    amount: number,
    assetId?: string | undefined
  ) => {
    return await this.publishTransaction(
      transactions.send(address, amount, assetId)
    );
  };

  burnKeyData = (assetId: any): WavesKeeper.TBurnTxData => {
    return transactions.burnTransactionData(assetId, 1);
  };

  setDataEncryptionKey = async (
    encKey: string,
    iv?: string
  ): Promise<{
    encKey: string;
    iv?: string;
  }> => {
    await this.publishTransaction(
      transactions.setEncryptionKeyData(encKey, iv)
    );

    return { encKey, iv };
  };

  getAssetIdFromBurnTransaction = (tx: any): string | undefined => {
    return tx.assetId;
  };
}
