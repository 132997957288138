import React from "react";
import { Grid } from "@mui/material";

import TableCard from "./TableCard.component";
import EncryptionKeyCard from "../Users/EncryptionKeyCard/EncryptionKeyCard";

export const Managers: React.FC = () => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <EncryptionKeyCard />
      </Grid>
      <Grid item xs={12}>
        <TableCard />
      </Grid>
    </React.Fragment>
  );
};

export default Managers;
