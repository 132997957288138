import { useSelector } from "react-redux";
import { IRootReducer } from "../../../reducers";
import { TokenEntry } from "../Token";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";
import CustomTable from "../../UI/MUITable/CustomTable";
import { CellArgument } from "../../UI/MUITable/ITable";
import Actions from "./Actions/Actions.component";

export interface TokenEntryData {
  assetId: string;
  name: string;
  actions: JSX.Element;
}

const TokenEntriesTable = () => {
  const { t } = useTranslation();
  const { tokenEntries } = useSelector((state: IRootReducer) => state.tokens);

  const columns: Column<TokenEntry>[] = [
    {
      id: "name",
      accessor: (entry, _) => entry.details?.name,
      Header: t("tables.tokenEntries.columns.name") ?? "name",
    },
    {
      accessor: "assetId",
      Header: t("tables.tokenEntries.columns.assetId") ?? "assetId",
    },
    {
      id: "actions",
      Header: t("tables.tokenEntries.columns.actions") ?? "actions",
      Cell: (arg: CellArgument<TokenEntry>) => (
        <Actions entry={arg.row.original} />
      ),
    },
  ];

  return (
    <CustomTable
      mode="local"
      data={Object.values(tokenEntries)}
      columns={columns}
    />
  );
};

export default TokenEntriesTable;
