import { chunk } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import useUserNoteEncryption, {
  KEEPER_MESSAGE_REGEXP,
} from "../../hooks/useUserNoteDecryption";
import { IRootReducer } from "../../reducers";
import { useEncryptionKey } from "../OrgManagerProvider/EncryptionKeyProvider";
import { USER_NOTE_PREFIX } from "../OrgManagerProvider/helpers/constants";
import { useOrgManager } from "../OrgManagerProvider/OrgManagerProvider";

type NotesCollection = {
  [address: string]: string;
};

type NoteConversionResult = {
  converted: {
    [address: string]: { encrypted: string; decrypted: string };
  };
  notConverted: string[];
};

interface ConvertNotesProps {
  notesToConvert?: NotesCollection | undefined;
  perform: () => Promise<NoteConversionResult>;
}

export const ConvertNotesContext = React.createContext<ConvertNotesProps>({
  notesToConvert: {},
  perform: () =>
    new Promise((resolve) => resolve({ converted: {}, notConverted: [] })),
});

export const ConvertNotesProvider: React.FC = ({ children }) => {
  const { accountType, writeService } = useOrgManager();
  const { entriesPresent } = useEncryptionKey();
  const { data: users } = useSelector((state: IRootReducer) => state.users);
  const { encrypt, decrypt } = useUserNoteEncryption();

  const notesToConvert = React.useMemo(() => {
    if (
      accountType !== "admin" ||
      !entriesPresent ||
      Object.keys(users).length === 0
    )
      return undefined;

    const result: NotesCollection = {};

    Object.values(users).forEach((user) => {
      if (!user.encNote || !KEEPER_MESSAGE_REGEXP.test(user.encNote)) return;

      result[user.address] = user.encNote;
    });

    return result;
  }, [accountType, entriesPresent, users]);

  const decryptOldNotes: () => Promise<NotesCollection> =
    React.useCallback(async () => {
      if (!notesToConvert || Object.keys(notesToConvert).length === 0) {
        throw new Error("No notes to convert");
      }

      const result: NotesCollection = {};

      await Promise.all(
        Object.entries(notesToConvert).map(
          async ([address, noteEncryptedWithKeeper]) => {
            const decryptResponse = await decrypt(noteEncryptedWithKeeper);

            if (decryptResponse.error) {
              throw decryptResponse.error;
            }

            if (!decryptResponse.message) return;
            result[address] = decryptResponse.message;
          }
        )
      );

      return result;
    }, [decrypt, notesToConvert]);

  const encryptNewNotes: (
    decryptedNotes: NotesCollection
  ) => Promise<NotesCollection> = React.useCallback(
    async (decryptedNotes: NotesCollection) => {
      const result: NotesCollection = {};

      await Promise.all(
        Object.entries(decryptedNotes).map(async ([address, note]) => {
          const encryptResponse = await encrypt(note);

          result[address] = encryptResponse;
        })
      );

      return result;
    },
    [encrypt]
  );

  const publishEncrytedNotes: (
    encryptedNotes: NotesCollection
  ) => Promise<{ published: NotesCollection; notPublished: NotesCollection }> =
    React.useCallback(
      async (encryptedNotes: NotesCollection) => {
        if (!writeService) {
          throw new Error("Keeper not available");
        }

        const result: {
          published: NotesCollection;
          notPublished: NotesCollection;
        } = { published: {}, notPublished: {} };

        await Promise.all(
          chunk(Object.entries(encryptedNotes), 100).map(async (group) => {
            const dataEntries: WavesKeeper.TData[] = group.map(
              ([address, value]) => {
                return {
                  key: USER_NOTE_PREFIX + address,
                  type: "string",
                  value,
                };
              }
            );

            await writeService
              .updateAccountData(dataEntries)
              .then(() => {
                // Transaction went through on the blockchain
                group.forEach(([address, encNote]) => {
                  result.published[address] = encNote;
                });
              })
              .catch((e) => {
                // Transaction failed, don't add notes to the result
                console.error(
                  "[ConvertNoteContext] Failed to publish transaction: ",
                  e
                );
                group.forEach(([address, encNote]) => {
                  result.notPublished[address] = encNote;
                });
              });
          })
        );

        return result;
      },
      [writeService]
    );

  const perform = React.useCallback(async () => {
    const result: NoteConversionResult = { converted: {}, notConverted: [] };

    if (!notesToConvert || Object.keys(notesToConvert).length === 0)
      return result;

    let decryptedNotes: NotesCollection;

    return await decryptOldNotes()
      .then(async (decrypted) => {
        decryptedNotes = decrypted;
        return await encryptNewNotes(decrypted);
      })
      .then(async (encryptedNotes) => {
        return await publishEncrytedNotes(encryptedNotes);
      })
      .then(({ published, notPublished }) => {
        Object.keys(published).forEach((address) => {
          result.converted[address] = {
            encrypted: published[address],
            decrypted: decryptedNotes[address],
          };
        });

        Object.keys(notPublished).forEach((address) => {
          result.notConverted.push(address);
        });

        return result;
      });
  }, [notesToConvert, decryptOldNotes, encryptNewNotes, publishEncrytedNotes]);

  const state: ConvertNotesProps = {
    notesToConvert,
    perform,
  };

  return (
    <ConvertNotesContext.Provider value={state}>
      {children}
    </ConvertNotesContext.Provider>
  );
};

export const useNotesConverter = () => React.useContext(ConvertNotesContext);
