import React from "react";
import { Box, CssBaseline, AppBar, Toolbar, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

// import LanguageSelector from "./LanguageSelector.component";
// import AccountMenu from "./AccountMenu.component";
// import NetworkSettings from "../../UI/NetworkSettings/NetworkSettings.component";
// import { useKeeper } from "../../../providers/KeeperProvider/KeeperProvider";
import AccountMenu from "./AccountMenu.component";
import ActiveOrganisationBadge from "./ActiveOrganisationBadge.component";
import { useKeeper } from "../../../providers/KeeperProvider/KeeperProvider";

import Sidebar from "../Sidebar/Sidebar.component";

const Header: React.FC = () => {
  const { t } = useTranslation();
  const { publicState, status } = useKeeper();

  // let nav = <p></p>;

  // if (status === "connected" && publicState) {
  //   nav = (
  //     <Nav className="ml-auto">
  //       <LanguageSelector />
  //       <NetworkSettings />
  //       <AccountMenu />
  //     </Nav>
  //   );
  // }

  const isConnected = status === "connected" && publicState;

  return (
    <Box>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar sx={{ pr: "24px" }}>
          <Typography sx={{ mr: 2 }}>
            <img src="images/skey-logo.svg" alt="logo" height="24px" />
          </Typography>

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, flexGrow: 1 }}
          >
            {t("interface.header.title")}
          </Typography>
          {isConnected && <ActiveOrganisationBadge />}
          <Box sx={{ mr: 3 }} />
          {isConnected && <AccountMenu />}
        </Toolbar>
      </AppBar>
      <Sidebar />
    </Box>
  );
};

export default Header;
