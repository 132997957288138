import * as Crypto from "@waves/ts-lib-crypto";

export interface Account {
  seed: string;
  publicKey: string;
  privateKey: string;
  address: string;
}

export const createAccount = (chainId: string): Account => {
  const seed = Crypto.randomSeed(15);
  const privateKey = Crypto.privateKey(seed);
  const publicKey = Crypto.publicKey(privateKey);
  const address = Crypto.address({ publicKey }, chainId);

  return {
    seed,
    privateKey,
    publicKey,
    address,
  };
};
