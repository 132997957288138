import { Grid, Paper, Typography, GridProps } from "@mui/material";

interface DashboardItemProps extends GridProps {
  title?: string;
  children?: JSX.Element;
}

const DashboardItem: React.FC<DashboardItemProps> = ({
  title,
  children,
  ...gridProps
}) => {
  return (
    <Grid item {...gridProps}>
      <Paper
        sx={{
          p: 2,
        }}
      >
        {title && (
          <Typography variant="h4" sx={{ marginBottom: "1em" }}>
            {title}
          </Typography>
        )}
        {children}
      </Paper>
    </Grid>
  );
};

export default DashboardItem;
