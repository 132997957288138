import { useDispatch } from "react-redux";
import { IconButton, Typography, Grid } from "@mui/material";
import { EditTwoTone } from "@mui/icons-material";

import { User } from "../../User";
import { userEditInit } from "../../../../actions/usersActions";
import UserNoteFormatter from "./UserNoteFormatter";

const AddressFormatter: React.FC<{ user: User }> = ({ user }) => {
  const { address } = user;
  const dispatch = useDispatch();

  const onEditClicked = () => {
    dispatch(userEditInit(user));
  };

  return (
    <Grid container>
      <Grid item>
        <IconButton onClick={onEditClicked} size="small" sx={{ opacity: 0.5 }}>
          <EditTwoTone />
        </IconButton>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1">{address}</Typography>
        <UserNoteFormatter user={user} />
      </Grid>
    </Grid>
  );
};

export default AddressFormatter;
