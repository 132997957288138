import * as BCTransactions from "@waves/waves-transactions";
import { IWriteServiceBase, TransactionPackageResponse } from ".";
import { dataTransactionData } from "./helpers/transactions";

export default class BaseWriteService implements IWriteServiceBase {
  public keeper: WavesKeeper.TWavesKeeperApi;

  constructor(keeper: WavesKeeper.TWavesKeeperApi) {
    this.keeper = keeper;
  }

  publishTransaction = async (
    tx: WavesKeeper.TSignTransactionData
  ): Promise<any> => {
    if (!this.keeper) {
      throw new Error("keeper not installed");
    }

    return this.keeper
      .signAndPublishTransaction(tx)
      .then((res: string) => {
        const tx = JSON.parse(res);
        console.log(`Published transaction with ID: ${tx.id}`);
        console.log(tx);
        return Promise.resolve(tx);
      })
      .catch((e: any) => {
        console.log(`Failed to publish transaction`);
        console.log(e);
        return Promise.reject(e);
      });
  };

  publishSignedTransaction = async (tx: any): Promise<any> => {
    const nodeUrl = (await this.keeper.publicState()).network.server;

    if (!nodeUrl) {
      throw new Error("Unable to get node URL");
    }

    return await BCTransactions.broadcast(tx, nodeUrl, {
      credentials: "omit",
    }).then(async (res) => {
      await BCTransactions.waitForTx(
        res.id,
        { apiBase: nodeUrl },
        { credentials: "omit" }
      );

      return res;
    });
  };

  signTransactionPackage = async (
    txes: WavesKeeper.TSignTransactionPackageData
  ): Promise<WavesKeeper.TSignTransactionPackageData> => {
    if (!this.keeper) {
      throw new Error("Keeper not detected");
    }

    return await this.keeper
      .signTransactionPackage(txes)
      .then((res) => {
        const responseTxes = res.map((r) => JSON.parse(r));

        return Promise.resolve(responseTxes);
      })
      .catch((e: any) => {
        console.error("Failed to sign transaction package: ", e);
        return Promise.reject(e);
      });
  };

  broadcastTransactionPackage = async (
    signedTxes: WavesKeeper.TSignTransactionPackageData,
    onTxProcessed?: ((tx: any) => any) | undefined
  ): Promise<TransactionPackageResponse> => {
    const published: any[] = [];
    const notPublished: any[] = [];

    await Promise.all(
      signedTxes.map(async (signedTx) => {
        return await this.publishSignedTransaction(signedTx)
          .then((publishedTx: any) => {
            published.push(publishedTx);
          })
          .catch((e) => {
            notPublished.push(signedTx);
          })
          .finally(() => {
            if (onTxProcessed) {
              onTxProcessed(signedTx);
            }
          });
      })
    );

    return { published, notPublished };
  };

  updateAccountData = async (data: WavesKeeper.TData[]) => {
    return await this.publishTransaction(dataTransactionData(data));
  };
}
