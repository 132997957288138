import { AnyAction } from "redux";

import {
  MANAGERS_FETCHED,
  MANAGER_CREATED,
  MANAGER_DELETED,
} from "../actions/types";

import { Manager, ManagerCollection } from "../components/Managers";

export interface IManagersReducer {
  collection: ManagerCollection;
}

const initialState: IManagersReducer = {
  collection: {},
};

const deepCopy = (state: IManagersReducer): IManagersReducer => {
  return { collection: { ...state.collection } };
};

const onManagerCreated = (
  state: IManagersReducer,
  newManager: Manager
): IManagersReducer => {
  const stateCpy: IManagersReducer = deepCopy(state);
  stateCpy.collection[newManager.address] = newManager;

  return stateCpy;
};

const onManagerDeleted = (
  state: IManagersReducer,
  deletedManager: Manager
): IManagersReducer => {
  const stateCpy: IManagersReducer = deepCopy(state);
  delete stateCpy.collection[deletedManager.address];

  return stateCpy;
};

const managersReducer = (
  state = initialState,
  action: AnyAction
): IManagersReducer => {
  switch (action.type) {
    case MANAGERS_FETCHED:
      return { ...state, collection: action.payload.collection };
    case MANAGER_CREATED:
      return onManagerCreated(state, action.payload.manager);
    case MANAGER_DELETED:
      return onManagerDeleted(state, action.payload.manager);
    default:
      return state;
  }
};

export default managersReducer;
