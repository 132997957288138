import { Grid, Typography, Button } from "@mui/material";
import { useDispatch } from "react-redux";

import { VerifierStatus } from "../../../../../hooks/useOrgVerifier";
import { verifierChanged } from "../../../../../actions/wavesKeeperActions";

interface AccountNotificationProps {
  message: string;
  buttonLabel?: string;
  clickToSwitchTo?: VerifierStatus;
}

const AccountNotification: React.FC<AccountNotificationProps> = ({
  message,
  clickToSwitchTo,
  buttonLabel,
}) => {
  const dispatch = useDispatch();

  const onButtonClicked = () => {
    if (!clickToSwitchTo) return;
    dispatch(verifierChanged(clickToSwitchTo));
  };

  return (
    <Grid container direction="column" justifyItems="stretch" spacing={1}>
      <Grid item>
        <Typography variant="body1">{message}</Typography>
      </Grid>

      {clickToSwitchTo && (
        <Grid item>
          <Button variant="contained" onClick={onButtonClicked}>
            {buttonLabel || "Retry"}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default AccountNotification;
