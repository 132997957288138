import { useSnackbar, VariantType } from "notistack";

const useSnackbarNotifications = () => {
  const { enqueueSnackbar } = useSnackbar();

  const message = (msg: string) => {
    enqueueSnackbar(msg, { variant: "default" });
  };

  const messageWithVariant = (msg: string, variant: VariantType) => {
    enqueueSnackbar(msg, { variant });
  };

  const success = (msg: string) => {
    messageWithVariant(msg, "success");
  };

  const info = (msg: string) => {
    messageWithVariant(msg, "info");
  };

  const warning = (msg: string) => {
    messageWithVariant(msg, "warning");
  };

  const error = (msg: string) => {
    messageWithVariant(msg, "error");
  };

  return {
    message,
    messageWithVariant,
    success,
    info,
    warning,
    error,
  };
};

export default useSnackbarNotifications;
