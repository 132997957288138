import React from "react";
import { Grid, Typography, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";

import useOrgVerifier from "../../../../hooks/useOrgVerifier";
import LoadingNotification from "../../../UI/LoadingNotification/LoadingNotification.component";
import AccountInit from "./views/AccountInit";
import AccountVerified from "./views/AccountVerified";
import AccountTxFailed from "./views/AccountTxFailed";
import AccountScriptFetchFailed from "./views/AccountScriptFetchFailed";
import AccountScriptOutdated from "./views/AccountScriptOutdated";

interface AccountStatusProps {
  fetching: boolean;
  onOrganisationSelected: (newAddress: string) => void;
}

const AccountStatus: React.FC<AccountStatusProps> = ({
  fetching,
  onOrganisationSelected,
}) => {
  const { t } = useTranslation();
  const orgVerifier = useOrgVerifier();

  const renderContent = React.useCallback(() => {
    switch (orgVerifier) {
      case "pendingConfirm":
        return <AccountInit />;
      case "scriptFetchFailed":
        return <AccountScriptFetchFailed />;
      case "pendingTransaction":
        return (
          <LoadingNotification
            label={t("orgSelector.accountStatus.pendingTransaction.label")}
          />
        );
      case "failedTransaction":
        return <AccountTxFailed />;
      case "verified":
        return (
          <AccountVerified onOrganisationSelected={onOrganisationSelected} />
        );
      case "scriptOutdated":
        return <AccountScriptOutdated />;
      default:
        return <LoadingNotification label={t("common.loading")} />;
    }
  }, [t, orgVerifier, onOrganisationSelected]);

  if (fetching) {
    return <Skeleton height="200px" />;
  }

  return (
    <Grid container direction="column" justifyItems="stretch" spacing={1}>
      <Grid item>
        <Typography variant="h6">
          {t("orgSelector.accountStatus.title")}
        </Typography>
      </Grid>

      <Grid item>{renderContent()}</Grid>
    </Grid>
  );
};

export default AccountStatus;
