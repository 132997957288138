import { Table, TableContainer, Paper, Box, Checkbox } from "@mui/material";
import React from "react";
import CustomTableBody from "./CustomTableBody";
import CustomTableBottom from "./CustomTableBottom";
import CustomTableHead from "./CustomTableHead";
import CustomTableTop from "./CustomTableTop";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useRowSelect,
} from "react-table";
import { CustomTableProps, TableInstanceProps } from "./ITable";

const CustomTable: React.FC<CustomTableProps> = (props) => {
  const [currentSelection, setCurrentSelection] = React.useState<any[]>([]);
  const { data, columns } = props;

  const instance = useTable(
    { columns, data } as any,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (props.options?.useSelect) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "select",
            defaultCanSort: false,
            Header: ({ getToggleAllRowsSelectedProps }) => {
              const { indeterminate, checked, onChange } =
                getToggleAllRowsSelectedProps();

              return (
                <div>
                  <Checkbox
                    checked={checked}
                    indeterminate={indeterminate}
                    onChange={onChange}
                  />
                </div>
              );
            },
            Cell: ({ row }) => {
              const { checked, onChange } = row.getToggleRowSelectedProps();
              return (
                <div>
                  <Checkbox checked={checked} onChange={onChange} />
                </div>
              );
            },
          },
          ...columns,
        ]);
      }
    }
  );

  React.useEffect(() => {
    if (!props.onSelectionChange) return;

    const currentlySelectedIds = instance.state.selectedRowIds;

    if (Object.keys(currentlySelectedIds).length === 0) {
      if (currentSelection.length > 0) {
        setCurrentSelection([]);
        props.onSelectionChange([]);
      }

      return;
    }

    const selectedIds = Object.entries(currentlySelectedIds)
      .filter(([key, value]) => Boolean(value))
      .map(([key, value]) => Number(key));

    if (selectedIds.length !== currentSelection.length) {
      setCurrentSelection(selectedIds);

      props.onSelectionChange(selectedIds);
    }
  }, [props, instance.state.selectedRowIds, currentSelection]);

  const instanceProps: TableInstanceProps = {
    instance,
    options: props.options ?? {},
    mode: props.mode,
    onTableChange: props.onTableChange ?? (() => {}),
    state: props.state!,
    rowFormatter: props.rowFormatter,
  };

  const component = props.component ?? "Box" ? Box : Paper;

  return (
    <TableContainer component={component} sx={{ padding: "10px" }}>
      <CustomTableTop {...instanceProps} />
      <Table>
        <CustomTableHead {...instanceProps} />
        <CustomTableBody {...instanceProps} />
      </Table>
      <CustomTableBottom {...instanceProps} />
    </TableContainer>
  );
};

export default CustomTable;
