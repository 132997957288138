import { Key } from "../../Key";
import { Tooltip } from "@mui/material";
import { useBlockchainNetwork } from "../../../../providers/BlockchainNetworkProvider/BlockchainNetworkProvider";
import { buildExplorerUrl } from "../../../../helpers/helpers";

const DeviceFormatter = (props: { row: Key }): JSX.Element => {
  const { network, name: networkName } = useBlockchainNetwork();
  const explorerUrl = network?.explorer.url;

  const renderExplorerUrl = () => {
    if (!explorerUrl || !networkName) {
      return null;
    }

    return buildExplorerUrl(
      explorerUrl,
      networkName,
      `address/${props.row.device}`
    );
  };

  const url = renderExplorerUrl();

  return (
    <Tooltip title={props.row.device}>
      {url ? (
        <a href={url} target="_blank" rel="noreferrer">
          {props.row.deviceName}
        </a>
      ) : (
        <span>{props.row.deviceName}</span>
      )}
    </Tooltip>
  );
};

export default DeviceFormatter;
