// import { base58Encode, base64Decode } from "@waves/ts-lib-crypto";
import * as Crypto from "@waves/ts-lib-crypto";
import { DataEntry, EntryValueTypes } from "../api/blockchain/blockchain";

export const isObjectEmpty = (obj: object) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

// Helper method to parse current search value
//
export const searchToSearchQuery = (search: string | undefined) => {
  if (!search || search.length >= 3) return search;

  return undefined;
};

// Convert Data entries response to an object

export const dataEntriesToObject = (
  entries: DataEntry[]
): { [key: string]: EntryValueTypes } => {
  const result: { [key: string]: EntryValueTypes } = {};

  entries.forEach(({ key, type, value }) => {
    result[key] = value;
  });

  return result;
};

export interface PaginationItemsMeta {
  total: number;
}

export const base58BinaryEntryToString = (value: BigInt64Array) => {
  return Crypto.base58Encode(
    Crypto.base64Decode(value.toString().replace("base64:", ""))
  );
};

export const buildEndpointUrl = (baseUrl: string, endpoint: string): string => {
  return `${removeTrailingSlash(baseUrl)}/${removeTrailingSlash(endpoint)}`;
};

export const removeTrailingSlash = (input: string): string => {
  return input.endsWith("/") ? input.slice(0, -1) : input;
};

export const removePreceedingSlash = (input: string): string => {
  return input.startsWith("/") ? input.slice(1) : input;
};

export const buildExplorerUrl = (
  baseUrl: string,
  networkName: string,
  endpoint?: string
) => {
  let slashedBaseUrl: string = removeTrailingSlash(baseUrl);

  if (!slashedBaseUrl.includes(networkName)) {
    slashedBaseUrl = `${slashedBaseUrl}/${networkName}`;
  }

  return `${slashedBaseUrl}${
    endpoint ? "/" + removePreceedingSlash(endpoint) : null
  }`;
};

export const validateAddressChainId = (
  address: string,
  expectedChainId: string
): boolean => {
  return Crypto.verifyAddress(address, { chainId: expectedChainId });
};
