import { Grid, Button, Skeleton, FormGroup, TextField } from "@mui/material";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import Yup from "../../../helpers/yup-extended";
import usePublicAddress from "../../../hooks/usePublicAddress";
import useSnackbarNotifications from "../../../hooks/useSnackbarNotifications";
import { useKeeper } from "../../../providers/KeeperProvider/KeeperProvider";

interface ManualInputFormProps {
  fetching: boolean;
  onOrganisationSelected: (newAddress: string) => void;
}

const ManualInputForm: React.FC<ManualInputFormProps> = ({
  fetching,
  onOrganisationSelected,
}) => {
  const { t } = useTranslation();
  const publicAddress = usePublicAddress();
  const { readService } = useKeeper();
  const snackbar = useSnackbarNotifications();

  const validationSchema = Yup.object({
    orgAddress: Yup.string()
      .label(t("orgSelector.manualInput.form.orgAddress.label"))
      .required(),
  });

  const formik = useFormik({
    validationSchema,
    initialValues: {
      orgAddress: "",
    },
    onSubmit: (values, helpers) => {
      if (!readService || !publicAddress) {
        return;
      }

      helpers.setSubmitting(true);

      readService
        .findManager(values.orgAddress, publicAddress)
        .then((isManager) => {
          if (isManager) {
            onOrganisationSelected(values.orgAddress);
          } else {
            snackbar.error(
              t("orgSelector.manualInput.form.messages.notAManager")
            );
          }
        })
        .catch((e) => {
          console.error("[OrganisationSelector][Manual] failed fetch: ", e);
        })
        .finally(() => {
          helpers.setSubmitting(false);
        });
    },
  });

  if (fetching) {
    return <Skeleton height="200px" />;
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container direction="column" spacing={1} alignItems="stretch">
        <Grid item xs={12}>
          <FormGroup>
            <TextField
              fullWidth
              type="text"
              label={t("orgSelector.manualInput.form.orgAddress.label")}
              name="orgAddress"
              value={formik.values.orgAddress}
              onChange={formik.handleChange}
              error={!!formik.errors.orgAddress}
              helperText={formik.errors.orgAddress}
            />
          </FormGroup>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            color="success"
            onClick={formik.submitForm}
            disabled={!formik.touched || !formik.isValid}
            fullWidth={false}
          >
            {t("orgSelector.manualInput.form.actions.submit")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ManualInputForm;
