import { Typography, ButtonGroup, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import "./views.css";

interface ConfirmProps {
  totalCount?: number;
  onConfirm?: () => any;
  onClose?: () => any;
}

const Confirm: React.FC<ConfirmProps> = ({
  onConfirm,
  onClose,
  totalCount,
}) => {
  const { t } = useTranslation();

  return (
    <div className="burn-many-keys-modal-content">
      <Typography variant="h5" sx={{ textAlign: "center" }}>
        {t("keys.modals.burnManyKeys.confirm.title", {
          keysCount: totalCount ?? 0,
        })}
      </Typography>
      <ButtonGroup>
        <Button variant="contained" color="success" onClick={onConfirm}>
          {t("buttons.confirm") ?? "Yes"}
        </Button>
        <Button variant="contained" color="error" onClick={onClose}>
          {t("buttons.deny") ?? "No"}
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default Confirm;
