import {
  AddManagerParams,
  IWriteService,
  TokenForm,
  TransactionPackageResponse,
  UpdateManagersParams,
} from ".";
import { ISendTokenForm } from "../../components/Tokens/Forms/SendTokenForm/SendTokenForm.component";
import { User } from "../../components/Users/User";
import BaseWriteService from "./BaseWriteService";
import * as managerScripts from "./helpers/managerScripts";
import * as transactions from "./helpers/transactions";

interface ManagerWriteServiceProps {
  keeper: WavesKeeper.TWavesKeeperApi;
  organisation: string;
  organisationPublicKey: string;
  manager: string;
}

export default class ManagerWriteService
  extends BaseWriteService
  implements IWriteService
{
  public organisationAddress: string;
  public organisationPublicKey: string;
  public manager: string;

  constructor(props: ManagerWriteServiceProps) {
    super(props.keeper);
    this.organisationAddress = props.organisation;
    this.organisationPublicKey = props.organisationPublicKey;
    this.manager = props.manager;
  }

  get defaultProps() {
    return {
      dApp: this.organisationAddress,
      manager: this.manager,
      orgPublicKey: this.organisationPublicKey,
    };
  }

  issueToken = async (form: TokenForm) => {
    return await this.publishTransaction(
      managerScripts.issueToken({
        ...this.defaultProps,
        ...form,
      })
    );
  };

  reissueToken = async (token: string, quantity: number) => {
    return await this.publishTransaction(
      managerScripts.reissueToken({
        ...this.defaultProps,
        token,
        quantity,
      })
    );
  };

  burnToken = async (token: string, quantity: number) => {
    return await this.publishTransaction(
      managerScripts.burnAsset({
        ...this.defaultProps,
        asset: token,
        quantity,
      })
    );
  };

  sendToken = async (form: ISendTokenForm) => {
    if (form.recipients.length === 1) {
      const recipient = form.recipients[0];

      return await this.publishTransaction(
        managerScripts.transferToken({
          ...this.defaultProps,
          token: form.assetId,
          recipient: recipient.address,
          quantity: recipient.amount,
        })
      );
    } else {
      const transactions = form.recipients.map((recipient) => {
        return async () => {
          return await this.publishTransaction(
            managerScripts.transferToken({
              ...this.defaultProps,
              token: form.assetId,
              recipient: recipient.address,
              quantity: recipient.amount,
            })
          );
        };
      });

      console.log("transactions: ", transactions);
      return await Promise.all(transactions);
    }
  };

  burnMultipleKeys = async (
    assetIds: string[]
  ): Promise<TransactionPackageResponse> => {
    return await this.signTransactionPackage(
      assetIds.map((assetId) =>
        managerScripts.burnAsset({
          ...this.defaultProps,
          asset: assetId,
          quantity: 1,
        })
      )
    ).then(async (signedTxes) => {
      return await this.broadcastTransactionPackage(signedTxes);
    });
  };

  transferAsset = async (
    asset: string,
    recipient: string,
    quantity: number
  ) => {
    return await this.publishTransaction(
      managerScripts.transferToken({
        ...this.defaultProps,
        token: asset,
        recipient,
        quantity,
      })
    );
  };

  setTokenStatus = async (token: string, status: boolean) => {
    return await this.publishTransaction(
      managerScripts.setTokenStatus({
        ...this.defaultProps,
        token,
        enabled: status,
      })
    );
  };

  addUser = async (user: User) => {
    await this.publishTransaction(
      managerScripts.addUser({
        ...this.defaultProps,
        address: user.address,
        mobileId: user.mobileId,
        encryptedNote: user.encNote,
      })
    );

    return user;
  };

  removeUser = async (user: User) => {
    await this.publishTransaction(
      managerScripts.removeUser({
        ...this.defaultProps,
        address: user.address,
      })
    );

    return user;
  };

  exposePublicKey = async (publicKey: string) => {
    return await this.publishTransaction(
      transactions.exposePublicKey(publicKey)
    );
  };

  removeUserNote = async (user: User) => {
    await this.publishTransaction(
      managerScripts.addUser({
        ...this.defaultProps,
        address: user.address,
        mobileId: user.mobileId,
        encryptedNote: undefined,
      })
    );

    return { ...user, encNote: undefined, note: undefined } as User;
  };

  updateNote = async (user: User, note: string) => {
    throw new Error("Not implemented");
  };

  setScript = (script: string) => {
    throw new Error("Not an Organisation");
  };

  addManager = (address: string, params: AddManagerParams) => {
    throw new Error("Not an Organisation");
  };

  removeManager = (address: string) => {
    throw new Error("Not an Organisation");
  };

  removeToken = (assetId: string) => {
    throw new Error("Not an Organisation");
  };

  updateManagersEncryptionKeysDataEntries = (params: UpdateManagersParams) => {
    throw new Error("Not an Organisation");
  };

  setDataEncryptionKey = async (
    encKey: string,
    iv?: string
  ): Promise<{
    encKey: string;
    iv?: string;
  }> => {
    throw new Error("Not an Organisation");
  };

  sendFunds = async (
    address: string,
    amount: number,
    assetId?: string | undefined
  ) => {
    return await this.publishTransaction(
      transactions.send(address, amount, assetId)
    );
  };

  burnKeyData = (assetId: any): WavesKeeper.TScriptInvocationTxData => {
    return managerScripts.burnAsset({
      ...this.defaultProps,
      asset: assetId,
      quantity: 1,
    });
  };

  getAssetIdFromBurnTransaction = (tx: any): string | undefined => {
    return tx.data ? tx.data.call.args[1].value : tx.call.args[1].value;
  };
}
