import React from "react";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import { Manager } from "../../..";
import { useOrgManager } from "../../../../../providers/OrgManagerProvider/OrgManagerProvider";
import ConfirmationModal from "../../../../Modals/ConfirmationModal/ConfirmationModal.component";

interface DeleteManagerProps {
  manager: Manager;
  onCompleted: (manager: Manager) => any;
}

const DeleteManager: React.FC<DeleteManagerProps> = ({
  manager,
  onCompleted,
}) => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const { writeService } = useOrgManager();
  const { t } = useTranslation();

  const onDeleteButtonClicked = () => {
    setModalOpen(true);
  };

  const onConfirm = () => {
    if (!writeService) {
      setModalOpen(false);
      return;
    }

    writeService
      .removeManager(manager.address)
      .then((res) => {
        onCompleted(res);
      })
      .catch((e) => {})
      .finally(() => {
        setModalOpen(false);
      });
  };

  const onClose = () => {
    setModalOpen(false);
  };

  return (
    <React.Fragment>
      <Tooltip title={t("tables.managers.actions.delete") ?? "Delete Manager"}>
        <IconButton onClick={onDeleteButtonClicked} color="error">
          <Delete />
        </IconButton>
      </Tooltip>
      <ConfirmationModal
        show={modalOpen}
        onConfirm={onConfirm}
        onClose={onClose}
      >
        <Typography variant="h6">
          {t("tables.managers.actions.confirmDelete")}
        </Typography>
      </ConfirmationModal>
    </React.Fragment>
  );
};

export default DeleteManager;
