import React from "react";
import { useTranslation } from "react-i18next";

import { useBlockchainNetwork } from "../BlockchainNetworkProvider/BlockchainNetworkProvider";
import { OrganisationStatsResponse } from "../BlockchainNetworkProvider/DataServiceReadService";
import { useOrgManager } from "../OrgManagerProvider/OrgManagerProvider";

interface StatsProviderProps {
  loading: boolean;
  errorMessage?: string;
  stats?: OrganisationStatsResponse;
}

export const StatsContext = React.createContext<StatsProviderProps>(
  null as any
);

export const StatsProvider: React.FC = ({ children }) => {
  const { dsReadService } = useBlockchainNetwork();
  const { organisationAddress } = useOrgManager();
  const { t } = useTranslation();

  const [stats, setStats] =
    React.useState<OrganisationStatsResponse | undefined>();
  const [error, setError] = React.useState<string | undefined>();
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!dsReadService || !organisationAddress) {
      setError(
        t("messages.dataService.disconnected") ||
          "Disconnected from DataService"
      );
      setStats(undefined);
      setLoading(false);
      return;
    }

    setError(undefined);
    setStats(undefined);
    setLoading(true);

    const { request } = dsReadService.getStats({
      id: organisationAddress,
    });

    request
      .then((res) => {
        setStats(res);
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [t, dsReadService, organisationAddress]);

  const state: StatsProviderProps = { stats, errorMessage: error, loading };

  return (
    <StatsContext.Provider value={state}>{children}</StatsContext.Provider>
  );
};

export const useStats = () => React.useContext(StatsContext);
