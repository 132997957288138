import { Tooltip, IconButton } from "@mui/material";
import { Toll } from "@mui/icons-material";

import { Token } from "../../Token";
import { reissueTokenInit } from "../../../../actions/tokensActions";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const ReissueToken: React.FC<{ token: Token }> = ({ token }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClicked = () => {
    dispatch(reissueTokenInit(token));
  };

  return (
    <Tooltip title={t("buttons.tokens.reissue") || "Reissue"}>
      <IconButton key="reissue" color="info" onClick={handleClicked}>
        <Toll />
      </IconButton>
    </Tooltip>
  );
};

export default ReissueToken;
