import { Button } from "@mui/material";
import { Done } from "@mui/icons-material";
import React from "react";

import { Manager } from "../..";
import useSetManagerEncryptionKey from "../../../../hooks/managers/useSetManagerEncryptionKey";
import { useOrgManager } from "../../../../providers/OrgManagerProvider/OrgManagerProvider";
import { useEncryptionKey } from "../../../../providers/OrgManagerProvider/EncryptionKeyProvider";

interface EncryptionKeyProps {
  manager: Manager;
}

const EncryptionKey: React.FC<EncryptionKeyProps> = ({ manager }) => {
  const [performing, setPerforming] = React.useState<boolean>(false);
  const { accountType } = useOrgManager();
  const { encParams } = useEncryptionKey();
  const { perform } = useSetManagerEncryptionKey();

  const onSetButtonClicked = () => {
    setPerforming(true);
    perform(manager.address)
      .then((res) => {
        manager.encryptionKeyPresent = true;
      })
      .finally(() => {
        setPerforming(false);
      });
  };

  if (manager.encryptionKeyPresent) {
    return <Done color="success" />;
  }

  return (
    <>
      <Button
        disabled={accountType !== "admin" || performing || !encParams}
        onClick={onSetButtonClicked}
      >
        Set Encryption Key
      </Button>
    </>
  );
};

export default EncryptionKey;
