import { useState } from "react";
import { ButtonGroup, Button } from "@mui/material";
import { Whatshot, Send } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import { Key } from "../Key";
import useMassKeyTransfer from "../../../hooks/useMassKeyTransfer";
import MassTransferKeysModal from "../../Modals/MassTranferKeysModal/MassTransferKeysModal.component";
import useMassKeyBurn from "../../../hooks/useMassKeyBurn";
import ConfirmBurnManyKeysModal from "../../Modals/ConfirmBurnManyKeysModal/ConfirmBurnManyKeysModal.component";

interface KeysTableHeaderProps {
  selectedRows: Key[];
  fetchKeys: () => any;
}

const KeysTableHeader: React.FC<KeysTableHeaderProps> = ({
  selectedRows,
  fetchKeys,
}) => {
  const { t } = useTranslation();

  const sendState = useMassKeyTransfer();
  const burnState = useMassKeyBurn();
  const [sendModalOpen, setSendModalOpen] = useState<boolean>(false);
  const [burnModalOpen, setBurnModalOpen] = useState<boolean>(false);

  const handleSendClicked = () => {
    sendState.reset();
    setSendModalOpen(true);
  };

  const onSendClosed = () => {
    setSendModalOpen(false);
  };

  const handleBurnClicked = () => {
    burnState.reset();
    setBurnModalOpen(true);
  };

  const onBurnClosed = () => {
    setBurnModalOpen(false);
  };

  const handleKeysRemoved = (removedKeys: Key[]) => {
    fetchKeys();
  };

  return (
    <ButtonGroup>
      <Button
        variant="contained"
        color="primary"
        disabled={selectedRows.length === 0}
        onClick={handleSendClicked}
        startIcon={<Send />}
      >
        {t("buttons.keys.sendMany")}
      </Button>
      <Button
        variant="contained"
        color="error"
        disabled={selectedRows.length === 0}
        onClick={handleBurnClicked}
        startIcon={<Whatshot />}
      >
        {t("buttons.keys.burnMany")}
      </Button>
      <MassTransferKeysModal
        keys={selectedRows}
        open={sendModalOpen}
        state={sendState}
        onClose={onSendClosed}
        onComplete={handleKeysRemoved}
      />
      <ConfirmBurnManyKeysModal
        open={burnModalOpen}
        keys={selectedRows}
        onClose={onBurnClosed}
        state={burnState}
        onComplete={handleKeysRemoved}
      />
    </ButtonGroup>
  );
};

export default KeysTableHeader;
