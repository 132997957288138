import { Grid, Typography, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";

import ManualInputForm from "./ManualInputForm";

interface ManualInputProps {
  fetching: boolean;
  onOrganisationSelected: (newAddress: string) => void;
}

const ManualInput: React.FC<ManualInputProps> = ({
  fetching,
  onOrganisationSelected,
}) => {
  const { t } = useTranslation();

  if (fetching) {
    return <Skeleton height="200px" />;
  }

  return (
    <Grid container direction="column" alignItems="stretch" spacing={3}>
      <Grid item>
        <Typography variant="h6">
          {t("orgSelector.manualInput.title")}
        </Typography>
      </Grid>

      <Grid item>
        <Typography variant="body1">
          {t("orgSelector.manualInput.description")}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <ManualInputForm
          fetching={fetching}
          onOrganisationSelected={onOrganisationSelected}
        />
      </Grid>
    </Grid>
  );
};

export default ManualInput;
