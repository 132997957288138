import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableSortLabelProps,
} from "@mui/material";
import React from "react";
import { TableInstanceProps } from "./ITable";
import type { ColumnInstance } from "react-table";

const CustomTableHead: React.FC<TableInstanceProps> = ({
  instance,
  mode,
  onTableChange,
  state,
}) => {
  const sortLabelProps = (column: ColumnInstance<any>): TableSortLabelProps => {
    let props: any;

    if (mode === "local") {
      props = {
        active: column.isSorted,
        direction: column.isSortedDesc ? "desc" : "asc",
        disabled: !column.canSort,
      };
    } else {
      props = {
        active: state.orderBy === column.id,
        direction: state.order,
        disabled: !column.canSort,
      };
    }

    if (column.canSort) {
      props.onClick = () => onSortLabelClick(column);
    }

    return props;
  };

  const onSortLabelClick = (column: ColumnInstance<any>) => {
    if (mode === "local") {
      instance.toggleSortBy(
        column.id,
        column.isSorted ? !column.isSortedDesc : false
      );
    } else {
      onTableChange("sort", {
        sortField: column.id,
        sortOrder:
          state.orderBy === column.id
            ? state.order === "asc"
              ? "desc"
              : "asc"
            : "asc",
      });
    }
  };

  return (
    <TableHead>
      <TableRow>
        {instance.headers.map((column: ColumnInstance<any>, index) => (
          <TableCell
            {...column.getHeaderProps()}
            align={index === 0 ? "left" : "right"}
          >
            {column.canSort ? (
              <TableSortLabel {...sortLabelProps(column)}>
                <b>{column.render("Header")}</b>
              </TableSortLabel>
            ) : (
              <b>{column.render("Header")}</b>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHead;
