import React from "react";
import { useTranslation } from "react-i18next";
import { BlockchainNetworkState } from ".";
import DataServiceReadService from "./DataServiceReadService";
import useNetworkPicker from "./useNetworkPicker";

export const BlockchainNetworkContext =
  React.createContext<BlockchainNetworkState>(null as any);

export const BlockchainNetworkProvider: React.FC = (props) => {
  const {
    currentNetwork,
    currentNetworkName,
    setCustomNetwork,
    refetchRemoteConfigs,
    remoteConfigRequestStatus,
  } = useNetworkPicker();

  const { t } = useTranslation();

  const dsReadService = React.useMemo(() => {
    if (!currentNetwork) {
      return undefined;
    }

    const dataServiceUrl = currentNetwork.dataService.url;

    if (!dataServiceUrl || dataServiceUrl.length === 0) {
      return undefined;
    }

    return new DataServiceReadService(
      currentNetwork.dataService.url,
      t,
      currentNetwork.dataService.apiKey
    );
  }, [currentNetwork, t]);

  const state: BlockchainNetworkState = {
    dsReadService,
    name: currentNetworkName,
    network: currentNetwork,
    remoteConfigRequest: {
      status: remoteConfigRequestStatus,
      refetch: refetchRemoteConfigs,
    },
    setCustomNetwork,
  };

  return (
    <BlockchainNetworkContext.Provider value={state}>
      {props.children}
    </BlockchainNetworkContext.Provider>
  );
};

export const useBlockchainNetwork = () =>
  React.useContext(BlockchainNetworkContext);
