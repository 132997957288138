import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip, Chip, ChipProps } from "@mui/material";

import { useKeeper } from "../../../providers/KeeperProvider/KeeperProvider";

interface BlockchainAddressChipProps extends ChipProps {
  address: string;
}

const truncateLabel = (value: string, length: number): string => {
  if (value.length < length) return value;

  return `${value.slice(0, length)}...${value.slice(value.length - length)}`;
};

const BlockchainAddressChip: React.FC<BlockchainAddressChipProps> = (props) => {
  const { address, ...rest } = props;
  const { t } = useTranslation();
  const [tooltip, setTooltip] = useState<string>(
    t("common.clickToCopyToClipboard")
  );
  const { publicState } = useKeeper();

  const isCurrent = publicState?.account?.address === address;

  useEffect(() => {
    setTooltip(t("common.clickToCopyToClipboard"));
  }, [t]);

  const onClicked = () => {
    navigator.clipboard.writeText(address);
    setTooltip(t("common.copiedToClipboard"));
    setTimeout(() => {
      setTooltip(t("common.clickToCopyToClipboard"));
    }, 1000);
  };

  return (
    <Tooltip title={tooltip}>
      <Chip
        label={truncateLabel(address, 5)}
        variant="outlined"
        onClick={onClicked}
        color={isCurrent ? "secondary" : "default"}
        {...rest}
      />
    </Tooltip>
  );
};

export default BlockchainAddressChip;
