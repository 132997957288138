import React from "react";
import { Button } from "@mui/material";
import { ReportProblemOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import Notification from "../Notification/Notification.component";
import LoadingNotification from "../LoadingNotification/LoadingNotification.component";
import { useBlockchainNetwork } from "../../../providers/BlockchainNetworkProvider/BlockchainNetworkProvider";

const BlockchainNetworkFetchFailedNotification: React.FC = (props) => {
  const { t } = useTranslation();

  const { status, refetch } = useBlockchainNetwork().remoteConfigRequest;

  const fetching = React.useMemo(() => status === "fetching", [status]);

  if (fetching) {
    return <LoadingNotification />;
  }

  const actions = (
    <Button
      variant="contained"
      color="success"
      onClick={refetch}
      disabled={fetching}
    >
      {t("buttons.retry")}
    </Button>
  );

  return (
    <Notification
      title={t("messages.networkSettings.fetchFailed.title")}
      Icon={ReportProblemOutlined}
      iconColor="warning"
    >
      {actions}
    </Notification>
  );
};

export default BlockchainNetworkFetchFailedNotification;
