import * as Views from "./views";
import { UseMassKeyBurnState } from "../../../hooks/useMassKeyBurn";
import { Key } from "../../Keys/Key";
import Modal from "../../UI/Modal/Modal.component";

interface ConfirmBurnManyKeysModalProps {
  open: boolean;
  keys: Key[];
  state: UseMassKeyBurnState;
  onComplete?: (removedKeys: Key[]) => any;
  onClose?: () => any;
}

const ConfirmBurnManyKeysModal: React.FC<ConfirmBurnManyKeysModalProps> = ({
  open,
  keys,
  state,
  onComplete,
  onClose,
}) => {
  const perform = async () => {
    await state.perform(keys).then(({ published, notPublished }) => {
      if (!onComplete) return;

      const burnedKeyIds: string[] = published.map((tx) => tx.assetId);
      const burnedKeys = keys.filter((key) =>
        burnedKeyIds.includes(key.assetId)
      );

      if (burnedKeys) onComplete(burnedKeys);
    });
  };

  return (
    <Modal
      show={open}
      closable={state.status !== "performing"}
      handleClose={onClose}
    >
      {state.status === "init" && (
        <Views.Confirm
          onConfirm={perform}
          onClose={onClose}
          totalCount={keys.length}
        />
      )}
      {state.status === "performing" && (
        <Views.Perfroming counters={state.counters} />
      )}
      {state.status === "finished" && (
        <Views.Finished onClose={onClose} transactions={state.transactions} />
      )}
    </Modal>
  );
};

export default ConfirmBurnManyKeysModal;
