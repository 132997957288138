import React from "react";
import { Column } from "react-table";
import { useDispatch, useSelector } from "react-redux";

import CustomTable from "../../UI/MUITable/CustomTable";
import LoadingNotification from "../../UI/LoadingNotification/LoadingNotification.component";
import { Manager, ManagerCollection } from "..";
import { useKeeper } from "../../../providers/KeeperProvider/KeeperProvider";
import { useOrgManager } from "../../../providers/OrgManagerProvider/OrgManagerProvider";
import ManagerActions from "./Columns/Actions/ManagerActions.component";
import { CellArgument } from "../../UI/MUITable/ITable";
import AddManagerModal from "../../Modals/AddManagerModal/AddManagerModal.component";
import { IRootReducer } from "../../../reducers";
import { managersFetched } from "../../../actions/managersActions";
import { useTranslation } from "react-i18next";
import axios from "axios";
import useGetManagersEncryptionKeys from "../../../hooks/managers/useGetManagersEncryptionKeys";
import EncryptionKey from "./Columns/EncryptionKey.component";
import { useEncryptionKey } from "../../../providers/OrgManagerProvider/EncryptionKeyProvider";

const ManagersTable = () => {
  const [fetching, setFetching] = React.useState<boolean>(false);
  const [addModalOpen, setModalOpen] = React.useState<boolean>(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { collection } = useSelector((state: IRootReducer) => state.managers);

  const { organisationAddress } = useOrgManager();
  const { readService } = useKeeper();
  const { checkMany } = useGetManagersEncryptionKeys();
  const { entriesPresent, loading: encryptionKeyLoading } = useEncryptionKey();

  const buttonDisabled: boolean = React.useMemo(() => {
    return !entriesPresent && !encryptionKeyLoading;
  }, [encryptionKeyLoading, entriesPresent]);

  const fetchManagers = React.useCallback(
    (orgAddress: string) => {
      if (!readService || !orgAddress) return;
      setFetching(true);

      const cancelToken = axios.CancelToken;
      const source = cancelToken.source();

      readService
        .fetchManagers(orgAddress, { cancelToken: source.token })
        .then(async (res) => {
          const newManagers: ManagerCollection = {};

          const managerEncryptionKeysPresent: { [key: string]: boolean } =
            await checkMany(Object.keys(res)).catch((e) => ({}));

          Object.keys(res).forEach((address: string) => {
            newManagers[address] = {
              address,
              encryptionKeyPresent: managerEncryptionKeysPresent[address],
            };
          });

          dispatch(managersFetched(newManagers));
        })
        .catch((e) => {
          console.error("[managersTable] error: ", e);
        })
        .finally(() => {
          setFetching(false);
        });

      return () => {
        source.cancel();
      };
    },
    [checkMany, dispatch, readService]
  );

  const onModalOpen = () => {
    setModalOpen(true);
  };

  const onModalClose = () => {
    setModalOpen(false);
  };

  React.useEffect(() => {
    if (!organisationAddress) return;

    fetchManagers(organisationAddress);
  }, [organisationAddress, fetchManagers]);

  if (fetching) {
    return <LoadingNotification />;
  }

  const columns: Column<Manager>[] = [
    {
      Header: t("tables.managers.columns.address") ?? "Address",
      accessor: "address",
    },
    {
      id: "encryptionKeyPresent",
      Header:
        t("tables.managers.columns.encryptionKeySet") ?? "Encryption Key set",
      accessor: "encryptionKeyPresent",
      Cell: (arg: CellArgument<Manager>) => (
        <EncryptionKey manager={arg.row.original} />
      ),
    },
    {
      id: "actions",
      Header: t("tables.managers.columns.actions") ?? "Actions",
      Cell: (arg: CellArgument<Manager>) => (
        <ManagerActions manager={arg.row.original} />
      ),
    },
  ];

  return (
    <React.Fragment>
      <AddManagerModal show={addModalOpen} handleClose={onModalClose} />
      <CustomTable
        mode="local"
        data={Object.values(collection)}
        columns={columns}
        options={{
          addItemButton: true,
          addItemButtonLabel: t("tables.managers.actions.add"),
          addItemButtonDisabled: buttonDisabled,
          addItemButtonTooltip: buttonDisabled
            ? t("messages.encryptionKey.notFound")
            : undefined,
          onAddItem: onModalOpen,
        }}
      />
    </React.Fragment>
  );
};

export default ManagersTable;
