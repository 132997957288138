import React from "react";
import Crypto from "crypto";

import { useKeeper } from "../providers/KeeperProvider/KeeperProvider";
import { useOrgManager } from "../providers/OrgManagerProvider/OrgManagerProvider";
import usePublicAddress from "./usePublicAddress";
import {
  dataEntriesToObject,
  validateAddressChainId,
} from "../helpers/helpers";
import {
  ORG_ENC_IV_DATA_ENTRY_KEY,
  MANAGER_ENC_KEY_PREFIX,
  MANAGER_ENC_IV_PREFIX,
  ORG_ENC_KEY_DATA_ENTRY_KEY,
} from "../providers/OrgManagerProvider/helpers/constants";

const useConcealEncryptionKey = () => {
  const { encryptor, readService, publicState } = useKeeper();
  const {
    writeService,
    organisationAddress,
    organisationPublicKey,
    accountType,
  } = useOrgManager();
  const publicAddress = usePublicAddress();

  const saveNewEncryptionKey = React.useCallback(async (): Promise<any> => {
    if (!encryptor || !writeService) {
      throw new Error("Not loaded yet");
    }
    const newKey = Crypto.randomBytes(16).toString("hex");
    const newIv = Crypto.randomBytes(8).toString("hex");

    const concealedNewKey = await encryptor.encrypt(newKey);
    const concealedIv = await encryptor.encrypt(newIv);

    return await writeService.setDataEncryptionKey(
      concealedNewKey,
      concealedIv
    );
  }, [encryptor, writeService]);

  const buildManagerEncryptionDataEntries = React.useCallback(() => {
    if (!publicAddress) {
      throw new Error("");
    }

    return {
      encKey: MANAGER_ENC_KEY_PREFIX + publicAddress,
      iv: MANAGER_ENC_IV_PREFIX + publicAddress,
    };
  }, [publicAddress]);

  const buildOrganisationEncryptionDataEntries = React.useCallback(() => {
    return {
      encKey: ORG_ENC_KEY_DATA_ENTRY_KEY,
      iv: ORG_ENC_IV_DATA_ENTRY_KEY,
    };
  }, []);

  const getEncryptedEncryptionKey = React.useCallback(async (): Promise<{
    encKey?: string;
    encIv?: string;
  }> => {
    if (!encryptor || !readService || !organisationAddress) {
      throw new Error("Keeper not available");
    }

    if (
      publicState?.network &&
      !validateAddressChainId(organisationAddress, publicState.network.code)
    ) {
      throw new Error("Invalid blockchain network");
    }

    const { encKey: keyEntry, iv: ivEntry } =
      accountType === "admin"
        ? buildOrganisationEncryptionDataEntries()
        : buildManagerEncryptionDataEntries();

    return await readService
      .fetchAddressData(organisationAddress, [keyEntry, ivEntry].join("|"))
      .then(async (entries) => {
        const entriesObj = dataEntriesToObject(entries);

        return {
          encKey: entriesObj[keyEntry] as string | undefined,
          encIv: entriesObj[ivEntry] as string | undefined,
        };
      });
  }, [
    encryptor,
    readService,
    organisationAddress,
    accountType,
    publicState?.network,
    buildOrganisationEncryptionDataEntries,
    buildManagerEncryptionDataEntries,
  ]);

  const decryptEncryptionKeys = React.useCallback(
    async (params: {
      encKey?: string;
      encIv?: string;
    }): Promise<{ encKey?: string; encIv?: string }> => {
      if (!encryptor) {
        throw new Error("Keeper not available");
      }

      return Object.fromEntries(
        await Promise.all(
          Object.entries(params).map(async ([key, value]) => {
            return [key, await encryptor.decrypt(value, organisationPublicKey)];
          })
        )
      );
    },
    [encryptor, organisationPublicKey]
  );

  return {
    saveNewEncryptionKey,
    getEncryptedEncryptionKey,
    decryptEncryptionKeys,
  };
};

export default useConcealEncryptionKey;
