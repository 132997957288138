import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

interface FetchFailedProps {
  onRetry: () => any;
}

const FetchFailed: React.FC<FetchFailedProps> = ({ onRetry }) => {
  const { t } = useTranslation();

  return (
    <Grid item>
      <Typography variant="body1">
        Failed to fetch data from the blockchain.
      </Typography>
      <Button onClick={onRetry}>{t("buttons.retry")}</Button>
    </Grid>
  );
};

export default FetchFailed;
