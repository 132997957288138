import React from "react";
import { Button, ButtonGroup } from "@mui/material";
import { CheckCircleOutline, WarningAmber } from "@mui/icons-material";
import { TFunction, useTranslation } from "react-i18next";

import Notification from "../../../UI/Notification/Notification.component";
import { GroupedTransactions } from "../../../../hooks/useMassKeyBurn";

interface FinishedProps {
  onClose?: () => any;
  transactions?: GroupedTransactions;
}

const AllKeysBurned: React.FC<{ t: TFunction }> = ({ t }) => {
  return (
    <Notification
      title={
        t("keys.modals.burnManyKeys.finished.allBurned") ??
        "Keys burn completed"
      }
      Icon={CheckCircleOutline}
      iconColor="success"
    />
  );
};

const SomeKeysBurned: React.FC<{ t: TFunction }> = ({ t }) => {
  return (
    <Notification
      title={
        t("keys.modals.burnManyKeys.finished.someBurned") ??
        "Only some of keys were burned"
      }
      Icon={WarningAmber}
      iconColor="warning"
    />
  );
};

const NoKeysBurned: React.FC<{ t: TFunction }> = ({ t }) => {
  return (
    <Notification
      title={
        t("keys.modals.burnManyKeys.finished.noneBurned") ??
        "No keys were burned"
      }
      Icon={WarningAmber}
      iconColor="error"
    />
  );
};

const TransactionsNotFinished: React.FC<{ t: TFunction }> = ({ t }) => {
  return (
    <Notification
      title={
        t("keys.modals.burnManyKeys.finished.notFinished") ??
        "Transactions haven't finished yet"
      }
      Icon={WarningAmber}
      iconColor="error"
    />
  );
};

const pickNotification = (
  t: TFunction,
  transactions?: GroupedTransactions
): JSX.Element => {
  if (!transactions) return <TransactionsNotFinished t={t} />;

  const anyPublished = transactions.published.length > 0;
  const anyNotPublished = transactions.notPublished.length > 0;

  if (anyPublished) {
    if (anyNotPublished) {
      return <SomeKeysBurned t={t} />;
    } else {
      return <AllKeysBurned t={t} />;
    }
  } else {
    return <NoKeysBurned t={t} />;
  }
};

const Finished: React.FC<FinishedProps> = ({ onClose, transactions }) => {
  const { t } = useTranslation();

  return (
    <div className="burn-many-keys-modal-content">
      {pickNotification(t, transactions)}
      <ButtonGroup>
        <Button variant="contained" onClick={onClose} color="primary">
          {t("buttons.close")}
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default Finished;
