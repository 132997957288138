import { Grid, Typography } from "@mui/material";
import { Person } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useStats } from "../../../../providers/StatsProvider/StatsProvider";
import LoadingNotification from "../../../UI/LoadingNotification/LoadingNotification.component";
import {
  WarningNotification,
  ErrorNotification,
} from "../../../UI/Notifications";

const KeysCard: React.FC = () => {
  const { loading, errorMessage, stats } = useStats();
  const { t } = useTranslation();

  if (loading) {
    return <LoadingNotification label={t("common.loading")} />;
  }

  if (errorMessage) {
    return <ErrorNotification title={errorMessage} />;
  }

  if (!stats) {
    return <WarningNotification title={t("messages.notFound")} />;
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: "190px" }}
    >
      <Person sx={{ fontSize: 72 }} />

      <Typography variant="h5">
        <Link to="/users" style={{ textDecoration: "none" }}>
          {t("dashboard.stats.users.title")}
        </Link>
      </Typography>
      <Typography variant="h5">
        {t("dashboard.stats.total", {
          total: stats.users.whitelisted || 0,
        })}
      </Typography>
    </Grid>
  );
};

export default KeysCard;
