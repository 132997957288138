import React from "react";

import { useKeeper } from "../../providers/KeeperProvider/KeeperProvider";
import { useEncryptionKey } from "../../providers/OrgManagerProvider/EncryptionKeyProvider";
import { useOrgManager } from "../../providers/OrgManagerProvider/OrgManagerProvider";

const useSetManagerEncryptionKey = () => {
  const { readService, encryptor } = useKeeper();
  const { encParams } = useEncryptionKey();
  const { writeService } = useOrgManager();

  const fetchManagerPublicKey = React.useCallback(
    async (managerAddress): Promise<string | undefined> => {
      if (!readService) {
        throw new Error("Keeper not available");
      }

      return await readService.fetchPublicKey(managerAddress);
    },
    [readService]
  );

  const encryptWithKeeper = React.useCallback(
    async (publicKey: string): Promise<{ encKey: string; encIv?: string }> => {
      if (!encParams) {
        throw new Error("Encryption key not available. Unlock Keeper maybe?");
      } else if (!encryptor) {
        throw new Error("Keeper not available");
      }

      const encKey = await encryptor.encrypt(encParams.encKey, publicKey);
      let encIv: string | undefined;

      if (encParams.encIv) {
        encIv = await encryptor.encrypt(encParams.encKey, publicKey);
      }

      return { encKey, encIv };
    },
    [encParams, encryptor]
  );

  const perform = React.useCallback(
    async (managerAddress: string) => {
      if (!writeService) {
        throw new Error("Keeper not available");
      }

      const managerPublicKey = await fetchManagerPublicKey(managerAddress);

      if (!managerPublicKey) {
        throw new Error("Manager does not have set public key in data storage");
      }

      const { encKey, encIv } = await encryptWithKeeper(managerPublicKey);

      return await writeService.addManager(managerAddress, {
        publicKey: managerPublicKey,
        encKey,
        encIv,
      });
    },
    [writeService, fetchManagerPublicKey, encryptWithKeeper]
  );

  return { perform };
};

export default useSetManagerEncryptionKey;
