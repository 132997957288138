import { useState } from "react";

import { Key } from "../components/Keys/Key";
import { transferTransactionData } from "../providers/OrgManagerProvider/helpers/transactions";
import {
  TRANSACTION_PACKAGE_LIMIT,
  useOrgManager,
} from "../providers/OrgManagerProvider/OrgManagerProvider";
import { GroupedTransactions } from "./useMassKeyBurn";
import { chunk } from "lodash";

export type TransferStatus = "init" | "performing" | "finished" | "error";

export interface TransferCounters {
  total: { transactions: number; packages: number };
  processed: { transactions: number; packages: number };
}

export interface UseMassKeyTransferState {
  status: TransferStatus;
  perform: (
    keys: Key[],
    recipient: string
  ) => Promise<{ published: any[]; notPublished: any[] }>;
  reset: () => void;
  counters: TransferCounters;
  transactions?: GroupedTransactions;
}

const EMPTY_COUNTERS: TransferCounters = {
  total: { transactions: 0, packages: 0 },
  processed: { transactions: 0, packages: 0 },
};

/**
 * Hook used to handle mass transfer of Keys
 * Transactions are grouped into packages to limit amount of required
 * confirmations from the user
 *
 * State also contains counters for processed transactions and packages
 */
const useMassKeyTransfer = (): UseMassKeyTransferState => {
  const [status, setStatus] = useState<TransferStatus>("init");
  const [counters, setCounters] = useState<TransferCounters>(EMPTY_COUNTERS);
  const [transactions, setTransactions] =
    useState<GroupedTransactions | undefined>();

  const { writeService } = useOrgManager();

  const reset = () => {
    setStatus("init");
    setTransactions(undefined);
    setCounters({ ...EMPTY_COUNTERS });
  };

  const buildTransferTransactions = (
    keys: Key[],
    recipient: string
  ): WavesKeeper.TTransferTxData[] => {
    return keys.map(({ assetId }) => {
      return transferTransactionData({
        address: recipient,
        assetId,
        amount: 1,
      });
    });
  };

  const updateTotalCounters = (transactions: any[], packages: any[]) => {
    setCounters((prev) => ({
      ...prev,
      total: { transactions: transactions.length, packages: packages.length },
    }));
  };

  // Callback to perform when a single transaction is signed and published
  const onTransferTxProcessed = (singedTx: any) => {
    setCounters((prev) => ({
      ...prev,
      processed: {
        ...prev.processed,
        transactions: prev.processed.transactions + 1,
      },
    }));
  };

  /**
   * Perform transfer of all Keys to a chosen recipient
   * @param {Key[]} keys - list of keys to transfer
   * @param {string} recipient - blockchain address to receive the keys
   */
  const perform = async (keys: Key[], recipient: string) => {
    // first check if Keeper is available
    if (!writeService) {
      throw new Error("Keeper not available");
    }

    // let's clear the state first
    reset();

    // Okay, now we're performing
    setStatus("performing");

    // build Transfer Transactions
    const transferTxes = buildTransferTransactions(keys, recipient);

    // Chunk transactions into groups
    const packages = chunk(transferTxes, TRANSACTION_PACKAGE_LIMIT);

    // Update counters of transactions and packages
    updateTotalCounters(transferTxes, packages);

    const published: any[] = [];
    const notPublished: any[] = [];

    // build Promises
    const promises = packages.map(async (group) => {
      console.log(group);
      // sign the package
      return await writeService
        .signTransactionPackage(group)
        .then(async (signedTxes) => {
          // broadcast signed transactions
          return await writeService.broadcastTransactionPackage(
            signedTxes,
            onTransferTxProcessed
          );
        })
        .then((txes) => {
          // insert published and not published transactions
          // into appropiate variables
          published.push(...txes.published);
          notPublished.push(...txes.notPublished);
        })
        .catch((e) => {
          // broadcasting has failed
          setCounters((prev) => ({
            ...prev,
            processed: {
              ...prev.processed,
              transactions: prev.processed.transactions + group.length,
            },
          }));

          // add all unpublished transactions
          // to not published group
          notPublished.push(...group);
        })
        .finally(() => {
          // Package is processed, update the counter
          setCounters((prev) => ({
            ...prev,
            processed: {
              ...prev.processed,
              packages: prev.processed.packages + 1,
            },
          }));
        });
    });

    // Wait for all transactions to finish
    await Promise.all(promises)
      .then((res) => {
        setStatus("finished");
      })
      .catch((e) => {
        setStatus("error");
      });

    setTransactions({ published, notPublished });

    return { published, notPublished };
  };

  return {
    status,
    perform,
    reset,
    counters,
    transactions,
  };
};

export default useMassKeyTransfer;
