import { ButtonGroup } from "@mui/material";
import { useOrgManager } from "../../../../providers/OrgManagerProvider/OrgManagerProvider";

import { TokenEntry } from "../../Token";
import ActivateTokenEntry from "./ActivateTokenEntry.component";
import DeactivateTokenEntry from "./DeactivateTokenEntry.component";
import DeleteTokenEntry from "./DeleteTokenEntry.component";

interface ActionsProps {
  entry: TokenEntry;
}

const Actions: React.FC<ActionsProps> = ({ entry }) => {
  const { accountType } = useOrgManager();
  const buttons: JSX.Element[] = [];

  if (entry.active) {
    buttons.push(<ActivateTokenEntry key="activate" entry={entry} />);
  } else {
    buttons.push(<DeactivateTokenEntry key="deactivate" entry={entry} />);
  }

  if (accountType === "admin") {
    buttons.push(<DeleteTokenEntry key="delete" entry={entry} />);
  }

  return <ButtonGroup size="small">{buttons}</ButtonGroup>;
};

export default Actions;
