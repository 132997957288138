import React from "react";
import { useTranslation } from "react-i18next";

import { Organisation } from "../../components/Organisations/Organisation";
import usePublicAddress from "../../hooks/usePublicAddress";
import { useBlockchainNetwork } from "../BlockchainNetworkProvider/BlockchainNetworkProvider";
import { useKeeper } from "../KeeperProvider/KeeperProvider";
import { IWriteService } from ".";
import OrganisationWriteService from "./OrganisationWriteService";
import ManagerWriteService from "./ManagerWriteService";
import usePublicKeyVerification, {
  IPublicKeyVerificationState,
} from "../../hooks/usePublicKeyVerification";
import SkeyLibService from "./SkeyLibService";
import { EncryptionKeyProvider } from "./EncryptionKeyProvider";
import useDebounce from "../../hooks/useDebounce";
import { PUBLIC_KEY_DATA_ENTRY_KEY } from "./helpers/constants";
import {
  base58BinaryEntryToString,
  validateAddressChainId,
} from "../../helpers/helpers";

export type OrgManagerType = "admin" | "manager";
export const TRANSACTION_PACKAGE_LIMIT = 7;

export interface OrgManagerState {
  setOrganisationAddress: (address: string) => any;
  reset: () => any;
  loading: boolean;
  pkVerificationState: IPublicKeyVerificationState;
  organisationAddress?: string;
  organisation?: Organisation;
  accountType?: OrgManagerType;
  writeService?: IWriteService;
  skeyService?: SkeyLibService;
  organisationPublicKey?: string;
}

export const OrgManagerContext = React.createContext<OrgManagerState>(
  null as any
);

export const OrgManagerProvider: React.FC = (props) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [organisation, setOrganisation] =
    React.useState<Organisation | undefined>();
  const [organisationAddress, setOrganisationAddress] =
    React.useState<string | undefined>();
  const [accountType, setOrgManagerType] =
    React.useState<OrgManagerType | undefined>();
  const [writeService, setWriteService] =
    React.useState<IWriteService | undefined>();
  const [organisationPublicKey, setOrganisationPublicKey] =
    React.useState<string>();

  const debouncedOrganisationPublicKey = useDebounce(
    organisationPublicKey,
    500
  );

  const debouncedOrganisationAddress = useDebounce(organisationAddress, 500);

  const manager = usePublicAddress();
  const { keeper, publicState, readService } = useKeeper();
  const { dsReadService } = useBlockchainNetwork();
  const pkVerificationState = usePublicKeyVerification();
  const { t } = useTranslation();

  const keeperNetwork = React.useMemo(() => {
    return publicState?.network;
  }, [publicState?.network]);

  const reset = () => {
    setOrganisationAddress(undefined);
    setOrganisation(undefined);
    setOrgManagerType(undefined);
    setLoading(false);
  };

  const skeyService = React.useMemo(() => {
    if (!keeperNetwork) return undefined;

    return new SkeyLibService(keeperNetwork);
  }, [keeperNetwork]);

  React.useEffect(() => {
    reset();
  }, [manager]);

  React.useEffect(() => {
    if (!organisationAddress) {
      setOrgManagerType(undefined);
      return;
    }

    const accType: OrgManagerType =
      manager === organisationAddress ? "admin" : "manager";

    setOrgManagerType(accType);
  }, [manager, organisationAddress]);

  React.useEffect(() => {
    if (
      !dsReadService ||
      !organisationAddress ||
      !keeperNetwork ||
      !validateAddressChainId(organisationAddress, keeperNetwork.code)
    )
      return;

    setLoading(true);

    dsReadService
      .getOrganisation(organisationAddress)
      .then((org: Organisation) => {
        setOrganisation(org);
      })
      .catch((e) => {
        setOrganisation({
          address: organisationAddress,
          name: "",
          description: "",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dsReadService, keeperNetwork, organisationAddress, t]);

  React.useEffect(() => {
    if (!keeper || !manager || !readService) {
      setWriteService(undefined);
      return;
    }

    switch (accountType) {
      case "manager":
        if (!organisationAddress) return;
        readService
          .fetchPublicKey(organisationAddress)
          .then((publicKey) => {
            if (!publicKey) return;

            setWriteService(
              new ManagerWriteService({
                keeper,
                manager,
                organisation: organisationAddress as string,
                organisationPublicKey: publicKey,
              })
            );
          })
          .catch((e) => {
            return;
          });

        break;
      default:
        setWriteService(new OrganisationWriteService(keeper));
        break;
    }
  }, [keeper, accountType, manager, organisationAddress, readService]);

  React.useEffect(() => {
    if (
      !readService ||
      !organisationAddress ||
      !keeperNetwork ||
      !validateAddressChainId(organisationAddress, keeperNetwork.code)
    )
      return;

    // Fetch public key of Organisation
    // Used to decrypt data encryption keys

    readService
      .fetchAddressData(organisationAddress, PUBLIC_KEY_DATA_ENTRY_KEY)
      .then((res) => {
        if (res.length === 0) {
          setOrganisationPublicKey(undefined);
        } else {
          const { type: dataType, value } = res[0];

          if (dataType !== "binary") return;

          setOrganisationPublicKey(
            base58BinaryEntryToString(value as BigInt64Array)
          );
        }
      });
  }, [keeperNetwork, organisationAddress, readService]);

  const state: OrgManagerState = {
    organisation,
    organisationAddress: debouncedOrganisationAddress,
    accountType,
    loading,
    writeService,
    setOrganisationAddress,
    reset,
    pkVerificationState,
    skeyService,
    organisationPublicKey: debouncedOrganisationPublicKey,
  };

  return (
    <OrgManagerContext.Provider value={state}>
      <EncryptionKeyProvider>{props.children}</EncryptionKeyProvider>
    </OrgManagerContext.Provider>
  );
};

export const useOrgManager = () => React.useContext(OrgManagerContext);
