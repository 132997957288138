import React from "react";
import { Chip, CircularProgress, Typography, Button } from "@mui/material";

import { useTranslation } from "react-i18next";

import useDAppFatherVerification from "../../../hooks/useDAppFatherVerification";
import { useOrgManager } from "../../../providers/OrgManagerProvider/OrgManagerProvider";
import BlockchainAddressChip from "../../UI/BlockchainAddressChip/BlockchainAddressChip";

const VerifiedBadge: React.FC = () => {
  const { t } = useTranslation();

  const { verified, loading } = useDAppFatherVerification();
  const { organisation, reset } = useOrgManager();

  if (loading) {
    return <CircularProgress />;
  } else if (!organisation) {
    return <></>;
  } else {
    return (
      <>
        {organisation.name && (
          <Typography variant="h6" sx={{ mr: 1 }}>
            {organisation.name}
          </Typography>
        )}
        {!verified && (
          <Chip
            label={t("interface.header.verifiedBadge.notVerified")}
            color="error"
            variant="filled"
            size="small"
            sx={{ mr: 1 }}
          />
        )}

        <BlockchainAddressChip
          address={organisation.address}
          sx={{ mr: 1 }}
          size="small"
        />
        <Button onClick={reset} variant="contained" color="secondary">
          {t("buttons.change")}
        </Button>
      </>
    );
  }
};

export default VerifiedBadge;
