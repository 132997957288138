interface IInvokeAsManagerProps {
  dApp: string;
  manager: string;
  orgPublicKey: string;
}

interface AddUserProps extends IInvokeAsManagerProps {
  address: string;
  mobileId: string;
  encryptedNote?: string | null | undefined;
}

interface RemoveUserProps extends IInvokeAsManagerProps {
  address: string;
}

interface SetTokenStatusProps extends IInvokeAsManagerProps {
  token: string;
  enabled: boolean;
}

interface IssueTokenProps extends IInvokeAsManagerProps {
  name: string;
  description: string;
  quantity: number;
}

interface ReissueTokenProps extends IInvokeAsManagerProps {
  token: string;
  quantity: number;
}

interface BurnAssetProps extends IInvokeAsManagerProps {
  asset: string;
  quantity: number;
}

interface TransferTokenProps extends IInvokeAsManagerProps {
  token: string;
  recipient: string;
  quantity: number;
}

// func MNGAddUser(manager:String,address:String,mobileId:String)
export const addUser = (
  props: AddUserProps
): WavesKeeper.TScriptInvocationTxData => {
  const { dApp, manager, address, mobileId, encryptedNote, orgPublicKey } =
    props;
  return {
    type: 16,
    data: {
      dApp,
      senderPublicKey: orgPublicKey,
      call: {
        function: "MNGAddUser",
        args: [
          {
            type: "string",
            value: manager,
          },
          {
            type: "string",
            value: address,
          },
          {
            type: "string",
            value: mobileId,
          },
          {
            type: "string",
            value: encryptedNote ?? "",
          },
        ],
      },
      payment: [],
      fee: {
        tokens: 0.009,
        assetId: "WAVES",
      },
    },
  };
};

// func MNGRemoveUser(manager:String,address:String)
export const removeUser = (
  props: RemoveUserProps
): WavesKeeper.TScriptInvocationTxData => {
  const { dApp, manager, address, orgPublicKey } = props;

  return {
    type: 16,
    data: {
      dApp,
      senderPublicKey: orgPublicKey,
      call: {
        function: "MNGRemoveUser",
        args: [
          {
            type: "string",
            value: manager,
          },
          {
            type: "string",
            value: address,
          },
        ],
      },
      payment: [],
      fee: {
        tokens: 0.009,
        assetId: "WAVES",
      },
    },
  };
};

// func MNGSetTokenStatus(manager:String,token:String, enabled:Boolean)
export const setTokenStatus = (
  props: SetTokenStatusProps
): WavesKeeper.TScriptInvocationTxData => {
  const { dApp, manager, token, enabled, orgPublicKey } = props;

  return {
    type: 16,
    data: {
      dApp,
      senderPublicKey: orgPublicKey,
      call: {
        function: "MNGSetTokenStatus",
        args: [
          {
            type: "string",
            value: manager,
          },
          {
            type: "string",
            value: token,
          },
          {
            type: "boolean",
            value: enabled,
          },
        ],
      },
      payment: [],
      fee: {
        tokens: 0.009,
        assetId: "WAVES",
      },
    },
  };
};

// func MNGIssueToken(manager:String,name:String,description:String,amount:Int)
export const issueToken = (
  props: IssueTokenProps
): WavesKeeper.TScriptInvocationTxData => {
  const { dApp, manager, name, description, quantity, orgPublicKey } = props;

  return {
    type: 16,
    data: {
      dApp,
      senderPublicKey: orgPublicKey,
      call: {
        function: "MNGIssueToken",
        args: [
          {
            type: "string",
            value: manager,
          },
          {
            type: "string",
            value: name,
          },
          {
            type: "string",
            value: description,
          },
          {
            type: "integer",
            value: quantity,
          },
        ],
      },
      payment: [],
      fee: {
        tokens: 1.009,
        assetId: "WAVES",
      },
    },
  };
};

// MNGReissueToken(manager:String,token:String,amount:Int)
export const reissueToken = (
  props: ReissueTokenProps
): WavesKeeper.TScriptInvocationTxData => {
  const { dApp, manager, token, quantity, orgPublicKey } = props;

  return {
    type: 16,
    data: {
      dApp,
      senderPublicKey: orgPublicKey,
      call: {
        function: "MNGReissueToken",
        args: [
          {
            type: "string",
            value: manager,
          },
          {
            type: "string",
            value: token,
          },
          {
            type: "integer",
            value: quantity,
          },
        ],
      },
      payment: [],
      fee: {
        tokens: 0.009,
        assetId: "WAVES",
      },
    },
  };
};

// func MNGBurnAsset(manager:String,key:String,amount:Int)
export const burnAsset = (
  props: BurnAssetProps
): WavesKeeper.TScriptInvocationTxData => {
  const { dApp, manager, asset, quantity, orgPublicKey } = props;

  return {
    type: 16,
    data: {
      dApp,
      senderPublicKey: orgPublicKey,
      call: {
        function: "MNGBurnAsset",
        args: [
          {
            type: "string",
            value: manager,
          },
          {
            type: "string",
            value: asset,
          },
          {
            type: "integer",
            value: quantity,
          },
        ],
      },
      payment: [],
      fee: {
        tokens: 0.009,
        assetId: "WAVES",
      },
    },
  };
};

// func MNGTransferToken(manager:String,token:String,recipient:String,amount:Int)
export const transferToken = (
  props: TransferTokenProps
): WavesKeeper.TScriptInvocationTxData => {
  const { dApp, manager, token, recipient, quantity, orgPublicKey } = props;

  return {
    type: 16,
    data: {
      dApp,
      senderPublicKey: orgPublicKey,
      call: {
        function: "MNGTransferToken",
        args: [
          {
            type: "string",
            value: manager,
          },
          {
            type: "string",
            value: token,
          },
          {
            type: "string",
            value: recipient,
          },
          {
            type: "integer",
            value: quantity,
          },
        ],
      },
      payment: [],
      fee: {
        tokens: 0.009,
        assetId: "WAVES",
      },
    },
  };
};
