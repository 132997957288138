import { Formik, FormikHelpers } from "formik";
import { useDispatch } from "react-redux";
import { tokenCreated } from "../../../../actions/tokensActions";
import { Token } from "../../Token";
import { useTranslation } from "react-i18next";
import buildValidationSchema from "./ValidationSchema";
import {
  // Checkbox,
  TextField,
  FormGroup,
  // FormControlLabel,
  // FormHelperText,
  Button,
} from "@mui/material";
import { useOrgManager } from "../../../../providers/OrgManagerProvider/OrgManagerProvider";
import { TokenForm as CreateTokenForm } from "../../../../providers/OrgManagerProvider";
import useSnackbarNotifications from "../../../../hooks/useSnackbarNotifications";

function buildTokenFromIssueTx(txData: any): Token {
  return {
    assetId: txData.assetId,
    balance: txData.quantity,
    reissuable: txData.reissuable,
    quantity: txData.quantity,
  };
}

function TokenForm(props: { handleSubmit?: () => void }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { writeService } = useOrgManager();
  const snackbar = useSnackbarNotifications();

  function onSubmit(
    values: CreateTokenForm,
    formikProps: FormikHelpers<CreateTokenForm>
  ) {
    if (!writeService) return;
    formikProps.setSubmitting(true);
    writeService
      .issueToken(values)
      .then((res: string) => {
        const txData = JSON.parse(res);

        snackbar.success(t("messages.forms.createToken.tokensCreated"));
        if (props.handleSubmit) {
          props.handleSubmit();
        }
        dispatch(tokenCreated(buildTokenFromIssueTx(txData)));
      })
      .catch((e) => {
        snackbar.error(
          t("messages.keeper.broadcastFailed", {
            message: e.message,
          })
        );
      });
    formikProps.setSubmitting(false);
  }

  return (
    <div>
      <Formik
        initialValues={{
          name: "",
          description: "",
          quantity: 0,
          reissuable: true,
        }}
        onSubmit={onSubmit}
        validationSchema={buildValidationSchema(t)}
      >
        {({
          values,
          errors,
          isSubmitting,
          handleChange,
          submitForm,
          dirty,
          isValid,
          setFieldValue,
          handleSubmit,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              {/* <Form.Group as={Row} className="mb-3" controlId="name">
                <Form.Label column sm={2}>
                  {t("forms.createToken.fields.name.label")}
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="string"
                    placeholder={t("forms.createToken.fields.name.placeholder")}
                    value={values.name}
                    onChange={handleChange}
                    isInvalid={!!errors.name}
                  />
                  {errors.name && (
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Form.Group> */}

              <FormGroup>
                <TextField
                  margin="normal"
                  name="name"
                  type="text"
                  label={t("forms.createToken.fields.name.label")}
                  placeholder={t("forms.createToken.fields.name.placeholder")}
                  value={values.name}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name}
                  required
                />
              </FormGroup>

              <FormGroup>
                <TextField
                  multiline
                  minRows={3}
                  margin="normal"
                  name="description"
                  type="text"
                  label={t("forms.createToken.fields.description.label")}
                  placeholder={t(
                    "forms.createToken.fields.description.placeholder"
                  )}
                  value={values.description}
                  onChange={handleChange}
                  error={!!errors.description}
                  helperText={errors.description}
                />
              </FormGroup>

              <FormGroup>
                <TextField
                  margin="normal"
                  name="quantity"
                  type="number"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  label={t("forms.createToken.fields.quantity.label")}
                  placeholder={t(
                    "forms.createToken.fields.quantity.placeholder"
                  )}
                  value={values.quantity}
                  onChange={handleChange}
                  error={!!errors.quantity}
                  helperText={errors.quantity}
                  required
                />
              </FormGroup>

              {/* <FormGroup>
                <FormControlLabel
                  label={t(
                    "forms.createToken.fields.reissuable.label"
                  ).toString()}
                  control={
                    <Checkbox
                      name="reissuable"
                      checked={values.reissuable}
                      onChange={(e) => {
                        setFieldValue("reissuable", e.target.checked);
                      }}
                    />
                  }
                />
                {errors.reissuable && (
                  <FormHelperText error>{errors.reissuable}</FormHelperText>
                )}
              </FormGroup> */}

              <br />
              <Button
                type="submit"
                variant="contained"
                disabled={!dirty || !isValid || isSubmitting}
              >
                {t("forms.createToken.buttons.submit")}
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

export default TokenForm;
