import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import {
  FormGroup,
  InputLabel,
  TextField,
  Button,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import buildSchema from "./ValidationSchema";
import { useBlockchainNetwork } from "../../../providers/BlockchainNetworkProvider/BlockchainNetworkProvider";
import { BlockchainNetworkConfig } from "../../../providers/BlockchainNetworkProvider";
import { removeTrailingSlash } from "../../../helpers/helpers";

interface NetworkSettingsFormProps {
  handleClose: () => any;
  handleSubmit: (form: BlockchainNetworkConfig) => Promise<any>;
}

const EMPTY_CONFIG: BlockchainNetworkConfig = {
  dataService: {
    url: "",
    apiKey: "",
  },
  explorer: {
    url: "",
  },
  dappFather: {
    address: "",
  },
  chainId: "R",
};

const NetworkSettingsForm: React.FC<NetworkSettingsFormProps> = ({
  handleClose,
  handleSubmit,
}) => {
  const [hidden, setHidden] = useState<boolean>(true);

  const { network } = useBlockchainNetwork();
  const { t } = useTranslation();

  const validationSchema = buildSchema(t);

  const parseConfigUrls = (
    values: BlockchainNetworkConfig
  ): BlockchainNetworkConfig => {
    if (values.dataService.url) {
      values.dataService.url = removeTrailingSlash(values.dataService.url);
    }
    if (values.explorer.url) {
      values.explorer.url = removeTrailingSlash(values.explorer.url);
    }
    return values;
  };

  const formik = useFormik({
    initialValues: network || EMPTY_CONFIG,
    validationSchema,
    onSubmit: (values, helpers) => {
      helpers.setSubmitting(true);
      handleSubmit(parseConfigUrls(values))
        .catch((e) => {})
        .finally(() => {
          helpers.setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    if (!network) return;
    formik.setValues(network, true);
    //eslint-disable-next-line
  }, [network]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormGroup sx={{ mb: 3 }}>
        <TextField
          type="text"
          label={t("forms.networkSettings.fields.dataServiceUrl.label")}
          name="dataService.url"
          value={formik.values.dataService.url}
          onChange={formik.handleChange}
          error={!!formik.errors.dataService?.url}
          helperText={formik.errors.dataService?.url}
        />
      </FormGroup>

      <FormGroup sx={{ mb: 3 }}>
        <FormControl variant="outlined">
          <InputLabel htmlFor="data-service-api-key">
            {t("forms.networkSettings.fields.dataServiceApiKey.label")}
          </InputLabel>
          <OutlinedInput
            id="data-service-api-key"
            name="dataService.apiKey"
            type={hidden ? "password" : "text"}
            value={formik.values.dataService.apiKey}
            onChange={formik.handleChange}
            error={!!formik.errors.dataService?.apiKey}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setHidden(!hidden)}>
                  {hidden ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label={t("forms.networkSettings.fields.dataServiceApiKey.label")}
          />
        </FormControl>
      </FormGroup>

      <FormGroup sx={{ mb: 3 }}>
        <TextField
          type="text"
          label={t("forms.networkSettings.fields.explorerUrl.label")}
          name="explorer.url"
          value={formik.values.explorer.url}
          onChange={formik.handleChange}
          error={!!formik.errors.explorer?.url}
          helperText={formik.errors.explorer?.url}
        />
      </FormGroup>

      <FormGroup sx={{ mb: 3 }}>
        <TextField
          type="text"
          label={t("forms.networkSettings.fields.dAppFatherAddress.label")}
          name="dappFather.address"
          value={formik.values.dappFather.address}
          onChange={formik.handleChange}
          error={!!formik.errors.dappFather}
          helperText={formik.errors.dappFather?.address}
        />
      </FormGroup>

      <Button
        variant="contained"
        onClick={formik.submitForm}
        disabled={!formik.isValid}
      >
        {t("forms.networkSettings.buttons.submit")}
      </Button>
      <Button variant="contained" onClick={handleClose}>
        {t("forms.networkSettings.buttons.close")}
      </Button>
    </form>
  );
};

export default NetworkSettingsForm;
