import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Grid, Button } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import TokenInfoTab from "./TokenInfoTab.component";

import Modal from "../../UI/Modal/Modal.component";

interface TokenInfoModalProps {
  open: boolean;
  onClose?: () => void;
}

const TokenInfoModal: React.FC<TokenInfoModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();

  const ImageContainer: React.FC = (props) => {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid item xs={12}>
          {props.children}
        </Grid>
      </Grid>
    );
  };

  const renderImg = (name: string) => {
    return (
      <ImageContainer>
        <img
          src={`/images/tutorials/tokens/${name}`}
          alt={name}
          style={{
            width: "auto",
            maxWidth: "100%",
            minWidth: "50%",
            // height: "auto",
            // height: "200px;",
          }}
        />
      </ImageContainer>
    );
  };

  const items: React.ReactNode[] = [
    <TokenInfoTab key="introduction">
      <Typography variant="h4">
        {t("tutorials.tokens.introduction.header")}
      </Typography>
      <Typography variant="body1">
        {t("tutorials.tokens.introduction.body1")}
      </Typography>
      <Typography variant="body1">
        {t("tutorials.tokens.introduction.body2")}
      </Typography>
      {renderImg("connecting.svg")}
    </TokenInfoTab>,
    <TokenInfoTab key="howToCreate">
      <Typography variant="h4">
        {t("tutorials.tokens.howToCreate.header")}
      </Typography>
      <Typography variant="body1">
        {t("tutorials.tokens.howToCreate.body1", {
          newTokenButton: t("buttons.tokens.create"),
        })}
      </Typography>
      {renderImg("createActivationToken.png")}
    </TokenInfoTab>,
    <TokenInfoTab key="createTokenForm">
      <Typography variant="h4">
        {t("tutorials.tokens.createTokenForm.header")}
      </Typography>
      <Typography variant="body1">
        {t("tutorials.tokens.createTokenForm.body1")}
      </Typography>
      {renderImg("createActivationTokenForm.png")}
      <Typography variant="body1">
        {t("tutorials.tokens.createTokenForm.body2")}
        {t("tutorials.tokens.createTokenForm.body3", {
          createButton: t("forms.createToken.buttons.submit"),
        })}
      </Typography>
      <ul>
        <li>{t("tutorials.tokens.createTokenForm.attributes.name")}</li>
        <li>{t("tutorials.tokens.createTokenForm.attributes.description")}</li>
        <li>{t("tutorials.tokens.createTokenForm.attributes.quantity")}</li>
        <li>{t("tutorials.tokens.createTokenForm.attributes.reissuable")}</li>
      </ul>
      <Typography variant="body1">
        {t("tutorials.tokens.createTokenForm.body3", {
          createButton: t("forms.createToken.buttons.submit"),
        })}
      </Typography>
    </TokenInfoTab>,
    <TokenInfoTab key="keeper">
      <Typography variant="h4">
        {t("tutorials.tokens.keeper.header")}
      </Typography>
      <Typography variant="body1">
        {t("tutorials.tokens.keeper.body1")}
      </Typography>
      {renderImg("skeyKeeper.png")}
      <Typography variant="body1">
        {t("tutorials.tokens.keeper.body2")}
      </Typography>
    </TokenInfoTab>,
    <TokenInfoTab key="sendInit">
      <Typography variant="h4">
        {t("tutorials.tokens.sendInit.header")}
      </Typography>
      {renderImg("sendTokenInit.png")}
      <Typography variant="body1">
        {t("tutorials.tokens.sendInit.body1", {
          sendButton: t("buttons.tokens.send"),
        })}
      </Typography>
    </TokenInfoTab>,
    <TokenInfoTab key="sendForm">
      <Typography variant="h4">
        {t("tutorials.tokens.sendForm.header")}
      </Typography>
      {renderImg("sendTokenForm.png")}
      <Typography variant="body1">
        {t("tutorials.tokens.sendForm.body1", {
          addRecipientButton: t("forms.sendToken.buttons.addRecipient"),
        })}
      </Typography>

      <Typography variant="body1">
        {t("tutorials.tokens.sendForm.body2", {
          removeButton: t("buttons.users.delete"),
        })}
      </Typography>
      {renderImg("deleteRecipient.png")}

      {/* <Typography variant="body1">
        {t("tutorials.tokens.sendForm.note")}
      </Typography> */}
    </TokenInfoTab>,
    <TokenInfoTab key="qrExplained">
      <Typography variant="h4">
        {t("tutorials.tokens.qrExplained.header")}
      </Typography>
      <Typography variant="body1">
        {t("tutorials.tokens.qrExplained.body")}
      </Typography>
      {renderImg("qrCode.svg")}
    </TokenInfoTab>,
    <TokenInfoTab key="qrScan">
      <Typography variant="h4">
        {t("tutorials.tokens.qrScan.header")}
      </Typography>
      <Typography variant="body1">
        {t("tutorials.tokens.qrScan.body1")}
      </Typography>
      {renderImg("scanQrCode.png")}
      <Typography variant="body1">
        {t("tutorials.tokens.qrScan.body2", { allow: t("common.allow") })}
      </Typography>

      <Typography variant="body1">
        {t("tutorials.tokens.qrScan.body3")}
      </Typography>
      {renderImg("scanFromCamera.svg")}
    </TokenInfoTab>,
  ];

  return (
    <Modal closable={true} show={open} handleClose={onClose} maxWidth="md">
      <Carousel
        navButtonsAlwaysVisible
        animation="slide"
        autoPlay={false}
        cycleNavigation={false}
      >
        {items.map((item, i) => (
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            key={`tab-${i}`}
          >
            <Grid item xs={1} />
            <Grid item xs={10}>
              {item}
            </Grid>
            <Grid item xs={1} />
          </Grid>
        ))}
      </Carousel>

      <Grid container justifyContent="flex-end">
        <Button color="error" variant="contained" onClick={onClose}>
          {t("buttons.close")}
        </Button>
      </Grid>
    </Modal>
  );
};

export default TokenInfoModal;
