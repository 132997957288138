import React from "react";
import { useKeeper } from "../../providers/KeeperProvider/KeeperProvider";
import { MANAGER_ENC_KEY_PREFIX } from "../../providers/OrgManagerProvider/helpers/constants";
import { useOrgManager } from "../../providers/OrgManagerProvider/OrgManagerProvider";

const useGetManagersEncryptionKeys = () => {
  const { readService } = useKeeper();
  const { organisationAddress } = useOrgManager();

  const checkOne = React.useCallback(
    async (managerAddress: string): Promise<boolean> => {
      if (!readService) {
        throw new Error("Keeper not available");
      } else if (!organisationAddress) {
        throw new Error("Organisation address not available");
      }

      const dataEntryKey = MANAGER_ENC_KEY_PREFIX + managerAddress;

      return await readService
        .fetchAddressData(organisationAddress, dataEntryKey)
        .then((entries) => {
          if (entries.length === 0) return false;
          const entry = entries.find((e) => e.key === dataEntryKey);

          if (!entry || entry.type !== "string") return false;

          return false;
        });
    },
    [organisationAddress, readService]
  );

  const checkMany = React.useCallback(
    async (managerAddresses: string[]): Promise<{ [key: string]: boolean }> => {
      if (!readService) {
        throw new Error("Keeper not available");
      } else if (!organisationAddress) {
        throw new Error("Organisation address not available");
      }

      const dataEntryKeys = managerAddresses.map(
        (managerAddress) => MANAGER_ENC_KEY_PREFIX + managerAddress
      );

      const result: { [key: string]: boolean } = {};

      const entries = await readService.fetchAddressData(
        organisationAddress,
        dataEntryKeys.join("|")
      );

      entries.forEach(({ key, type }) => {
        const address = key.replace(MANAGER_ENC_KEY_PREFIX, "");

        result[address] = type === "string";
      });

      return result;
    },
    [organisationAddress, readService]
  );

  return { checkOne, checkMany };
};

export default useGetManagersEncryptionKeys;
