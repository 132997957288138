import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { IRootReducer } from "../../../reducers";
import { usersFetched } from "../../../actions/usersActions";
import { UserCollection } from "../User";
import render from "./Renderer";
import LoadingNotification from "../../UI/LoadingNotification/LoadingNotification.component";
import { useKeeper } from "../../../providers/KeeperProvider/KeeperProvider";
import { useOrgManager } from "../../../providers/OrgManagerProvider/OrgManagerProvider";
import useStatusVerifier from "../../../hooks/useStatusVerifier";
import useSnackbarNotifications from "../../../hooks/useSnackbarNotifications";

const UsersTable = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState<boolean>(false);

  const { data } = useSelector((state: IRootReducer) => state.users);
  const orgVerified = useStatusVerifier();
  const { readService } = useKeeper();
  const snackbar = useSnackbarNotifications();
  const { organisationAddress } = useOrgManager();

  useEffect(() => {
    if (!readService || !organisationAddress || fetched) return;

    readService
      .getUsers(organisationAddress)
      .then(async (res: UserCollection) => {
        dispatch(usersFetched(res));
      })
      .catch((e) => {
        snackbar.error(
          t("messages.users.fetchFailed", {
            message: e.message,
          })
        );
      })
      .finally(() => {
        setFetched(true);
      });
  }, [
    readService,
    orgVerified,
    dispatch,
    t,
    snackbar,
    fetched,
    organisationAddress,
  ]);

  useEffect(() => {
    setFetched(false);
  }, [organisationAddress]);

  if (!fetched)
    return (
      <div>
        <LoadingNotification />
      </div>
    );

  return render(Object.values(data), t, props.showModal);
};

export default UsersTable;
