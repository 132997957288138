import { MoveUp } from "@mui/icons-material";

import { useOrgManager } from "../../../../providers/OrgManagerProvider/OrgManagerProvider";
import { tokenEntryActivated } from "../../../../actions/tokensActions";

import TokenAction from "../../TokensTable/Actions/TokenAction.component";
import { TokenEntry } from "../../Token";

const ActivateTokenEntry: React.FC<{ entry: TokenEntry }> = ({ entry }) => {
  const { writeService } = useOrgManager();

  const onClicked = async () => {
    return await writeService?.setTokenStatus(entry.assetId, true);
  };

  return (
    <TokenAction
      key="activate"
      actionKey="activate"
      onClicked={onClicked}
      onSuccessTlKey="messages.tokens.tokenActivated"
      onSuccessDispatchAction={tokenEntryActivated(entry)}
      tooltipTlKey="buttons.tokens.activate"
      color="success"
      Icon={MoveUp}
    />
  );
};

export default ActivateTokenEntry;
