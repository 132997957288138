import { IconButton, Tooltip } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useDispatch } from "react-redux";

import { Device } from "../../Device";
import { KeyCollection } from "../../../Keys/Key";
import { deviceKeysBurnInit } from "../../../../actions/devicesActions";
import { useBlockchainNetwork } from "../../../../providers/BlockchainNetworkProvider/BlockchainNetworkProvider";
import { useTranslation } from "react-i18next";
import { useOrgManager } from "../../../../providers/OrgManagerProvider/OrgManagerProvider";
import useSnackbarNotifications from "../../../../hooks/useSnackbarNotifications";

const BurnKeysButton = (props: { device: Device }): JSX.Element => {
  const dispatch = useDispatch();
  const { organisationAddress } = useOrgManager();
  const { device } = props;
  const { dsReadService } = useBlockchainNetwork();
  const snackbar = useSnackbarNotifications();
  const { t } = useTranslation();

  const burnKeysInit = () => {
    if (!organisationAddress || !dsReadService) return;
    dsReadService
      .getDeviceKeys(organisationAddress, device.address)
      .then((res: KeyCollection) => {
        dispatch(deviceKeysBurnInit(device.address, res));
      })
      .catch((e: any) => {
        snackbar.error(
          t("messages.deviceKeys.fetchFailed", {
            defaultValue: "Failed to fetch keys of device: " + e.message,
            message: e.message,
          })
        );
      });
  };

  return (
    <Tooltip
      title={t("tables.devices.actions.deleteKeys") || "Delete all keys"}
    >
      <IconButton color="error" onClick={burnKeysInit}>
        <Delete />
      </IconButton>
    </Tooltip>
  );
};

export default BurnKeysButton;
