import * as yup from "yup";
import * as Crypto from "@waves/ts-lib-crypto";
import { AnyObject, Maybe } from "yup/lib/types";

// validate if input is a valid Blockchain Address
yup.addMethod<yup.StringSchema>(
  yup.string,
  "blockchainAddress",
  function (errorMessage) {
    return this.test("test-blockchain-address", errorMessage, function (value) {
      const { path, createError } = this;

      if (!value || value === "") {
        return false;
      }

      return (
        Crypto.verifyAddress(value) ||
        createError({ path, message: errorMessage })
      );
    });
  }
);

// validate if input is from expected network
yup.addMethod<yup.StringSchema>(
  yup.string,
  "addressChainId",
  function (expectedChainId, errorMessage) {
    return this.test(
      "test-blockchain-chain-id",
      errorMessage,
      function (value) {
        const { path, createError } = this;

        if (!value || value === "") {
          return false;
        }

        return (
          Crypto.verifyAddress(value, expectedChainId) ||
          createError({ path, message: errorMessage })
        );
      }
    );
  }
);

declare module "yup" {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    // emptyAsUndefined(): StringSchema<TType, TContext>;
    blockchainAddress(msg: string): StringSchema<TType, TContext>;
    addressChainId(
      expectedChainId: string | undefined,
      msg: string
    ): StringSchema<TType, TContext>;
  }
}

export default yup;
