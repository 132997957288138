import { useTranslation } from "react-i18next";
import AccountNotification from "./AccountNotification";

const AccountScriptFetchFailed: React.FC = () => {
  const { t } = useTranslation();
  return (
    <AccountNotification
      message={t("orgSelector.accountStatus.scriptFetchFailed.body")}
      clickToSwitchTo="waiting"
    />
  );
};

export default AccountScriptFetchFailed;
