import React from "react";
import { useDispatch } from "react-redux";

import { Manager } from "../../..";
import DeleteManager from "./DeleteManager.component";
import { managerDeleted } from "../../../../../actions/managersActions";

interface ManagerActionsProps {
  manager: Manager;
}

const ManagerActions: React.FC<ManagerActionsProps> = ({ manager }) => {
  const dispatch = useDispatch();

  const onRemoved = () => {
    dispatch(managerDeleted(manager));
  };

  return (
    <div>
      <DeleteManager manager={manager} onCompleted={onRemoved} />
    </div>
  );
};

export default ManagerActions;
