import LoadingNotification from "../../../UI/Notifications/LoadingNotification";
import { TransferCounters } from "../../../../hooks/useMassKeyTransfer";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface PerformingProps {
  counters: TransferCounters;
}

const Performing: React.FC<PerformingProps> = ({ counters }) => {
  const { t } = useTranslation();
  const { total, processed } = counters;

  return (
    <LoadingNotification title="Performing">
      <Typography variant="body1">
        {t("keys.modals.transferManyKeys.performing.transactions", {
          done: processed.transactions,
          total: total.transactions,
        })}
      </Typography>
      <Typography variant="body1">
        {t("keys.modals.transferManyKeys.performing.packages", {
          done: processed.packages,
          total: total.packages,
        })}
      </Typography>
    </LoadingNotification>
  );
};

export default Performing;
