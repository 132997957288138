import React from "react";
import { useOrgManager } from "../providers/OrgManagerProvider/OrgManagerProvider";
import useConcealEncryptionKey from "./useConcealEncryptionKey";
import useDebounce from "./useDebounce";

export type EncryptionKeyCheckStatus =
  | "waiting"
  | "loading"
  | "encryptionKeySet"
  | "encryptionKeyNotSet"
  | "keeperLocked"
  | "settingKey"
  | "error";

export interface OrgEncryptionKeyCheckerProps {
  encParams?: { encKey: string; encIv?: string };
  loading: boolean;
  keeperLocked: boolean;
}

export interface OrgEncryptionKeyCheckerResponse {
  status: EncryptionKeyCheckStatus;
  error: any;
  perform: () => any;
}

const useOrgEncryptionKeyChecker = (
  params: OrgEncryptionKeyCheckerProps
): OrgEncryptionKeyCheckerResponse => {
  const { encParams, loading: encKeyLoading, keeperLocked } = params;

  const [status, setStatus] =
    React.useState<EncryptionKeyCheckStatus>("waiting");
  const [error, setError] = React.useState<any>();

  const { saveNewEncryptionKey } = useConcealEncryptionKey();

  const {
    loading: orgLoading,
    accountType,
    pkVerificationState,
    organisationAddress,
  } = useOrgManager();

  const initLoading = React.useMemo(() => {
    return encKeyLoading || orgLoading;
  }, [encKeyLoading, orgLoading]);

  const debouncedInitLoading = useDebounce(initLoading, 500);

  React.useEffect(() => {
    if (
      !organisationAddress ||
      pkVerificationState.requestState !== "verified"
    ) {
      setStatus("waiting");
    } else if (debouncedInitLoading) {
      setStatus("loading");
    } else if (keeperLocked && status !== "encryptionKeySet") {
      setStatus("keeperLocked");
    } else {
      setStatus(encParams?.encKey ? "encryptionKeySet" : "encryptionKeyNotSet");
    }
  }, [
    debouncedInitLoading,
    pkVerificationState.requestState,
    encParams,
    organisationAddress,
    keeperLocked,
    status,
  ]);

  const perform = React.useCallback(async () => {
    if (accountType !== "admin") {
      throw new Error("Only Organisation can set new encryption keys");
    } else if (status === "encryptionKeySet") {
      throw new Error("Encryption key already set");
    }

    setStatus("settingKey");
    setError(undefined);

    return await saveNewEncryptionKey()
      .then((res) => {
        setStatus("encryptionKeySet");
      })
      .catch((e) => {
        setError(e);
        setStatus("error");
      });
  }, [accountType, saveNewEncryptionKey, status]);

  return { status, error, perform };
};

export default useOrgEncryptionKeyChecker;
