import "../Notification/notification.css";

import { CircularProgress, Typography } from "@mui/material";
interface LoadingNotificationProps {
  title: string;
  description?: string;
  spinnerColor?: string;
  children?: React.ReactNode;
}

const LoadingNotification: React.FC<LoadingNotificationProps> = ({
  title,
  description,
  spinnerColor,
  children,
}) => {
  let renderedIcon;

  return (
    <div className="notice">
      {renderedIcon && <div className="icon">{renderedIcon}</div>}
      <CircularProgress
        size="72px"
        color={(spinnerColor ?? "primary") as any}
      />
      <Typography variant="h4" sx={{ marginBottom: "10px" }}>
        {title}
      </Typography>
      {description && <Typography variant="body1">{description}</Typography>}
      {children}
    </div>
  );
};

export default LoadingNotification;
