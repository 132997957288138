import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const useSkeyKeeper = !!process.env.REACT_APP_USE_SKEY_KEEPER;
const keeperName = useSkeyKeeper ? "Skey" : "Waves";

const Rejected: React.FC = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <Typography variant="h3">
          {t("keeperDetector.rejected.header", { keeper: keeperName })}
        </Typography>
        <Typography variant="h6">
          {t("keeperDetector.rejected.instructionsHeader")}
        </Typography>
      </div>
      <ul>
        <li>
          {t("keeperDetector.rejected.instructions.step1", {
            keeper: keeperName,
          })}{" "}
        </li>
        <li>{t("keeperDetector.rejected.instructions.step2")}</li>
        <li>
          {t("keeperDetector.rejected.instructions.step3", {
            host: window.location.host,
          })}
        </li>
        <li>{t("keeperDetector.rejected.instructions.step4")}</li>
        <li>{t("keeperDetector.rejected.instructions.step5")}</li>
      </ul>
    </div>
  );
};

export default Rejected;
