import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

interface CopyButtonProps {
  children: React.ReactNode;
  content: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ children, content }) => {
  const { t } = useTranslation();
  const [copyTooltip, setCopyTooltip] = React.useState<string>(
    t("common.clickToCopyToClipboard")
  );

  const handleClicked = () => {
    navigator.clipboard.writeText(content);
    setCopyTooltip(t("common.copiedToClipboard"));
    setTimeout(() => {
      setCopyTooltip(t("common.clickToCopyToClipboard"));
    }, 1000);
  };

  return (
    <Tooltip title={copyTooltip}>
      <IconButton onClick={handleClicked}>{children}</IconButton>
    </Tooltip>
  );
};

export default CopyButton;
