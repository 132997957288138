import { Fragment, useState } from "react";

import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Skeleton,
  SelectChangeEvent,
} from "@mui/material";
import { Organisation } from "../../Organisations/Organisation";
import { useTranslation } from "react-i18next";

interface SelectorProps {
  organisations: Organisation[];
  onOrganisationSelected: (newAddress: string) => void;
}

const Selector: React.FC<SelectorProps> = ({
  organisations,
  onOrganisationSelected,
}) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<string | undefined>(
    organisations[0]?.address
  );

  const onChange = (e: SelectChangeEvent<string>, child: any) => {
    setSelected(e.target.value);
  };

  const onButtonClicked = () => {
    if (!selected) return;
    onOrganisationSelected(selected);
  };

  return (
    <Fragment>
      <FormControl fullWidth sx={{ mb: 1 }}>
        <InputLabel>{t("orgSelector.selector.label")}</InputLabel>
        <Select
          label={t("orgSelector.selector.label")}
          value={selected}
          onChange={onChange}
        >
          {organisations.map((org) => {
            return (
              <MenuItem value={org.address} key={org.address}>
                {org.name || t(t("orgSelector.selector.noName"))}({org.address})
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Button variant="contained" onClick={onButtonClicked}>
        {t("orgSelector.selector.select")}
      </Button>
    </Fragment>
  );
};

interface OrganisationSelectorProps {
  fetching: boolean;
  organisations: Organisation[];
  onOrganisationSelected: (newAddress: string) => void;
}

const OrganisationSelector: React.FC<OrganisationSelectorProps> = ({
  organisations,
  fetching,
  onOrganisationSelected,
}) => {
  const { t } = useTranslation();

  if (fetching) {
    return <Skeleton height="100px" />;
  }

  if (organisations.length === 0) {
    return (
      <Typography variant="h6">
        {t("orgSelector.selector.noOrganisations")}
      </Typography>
    );
  }

  return (
    <Selector
      organisations={organisations}
      onOrganisationSelected={onOrganisationSelected}
    />
  );
};

export default OrganisationSelector;
