import React from "react";
import { Grid, Paper, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useNotesConverter } from "../../../providers/users/ConvertNotesContext";
import useSnackbarNotifications from "../../../hooks/useSnackbarNotifications";
import { useEncryptionKey } from "../../../providers/OrgManagerProvider/EncryptionKeyProvider";
import { userNotesConverted } from "../../../actions/usersActions";
import { useOrgManager } from "../../../providers/OrgManagerProvider/OrgManagerProvider";
import useDebounce from "../../../hooks/useDebounce";

const ConvertNotesCard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [performing, setPerforming] = React.useState<boolean>(false);
  const { notesToConvert, perform } = useNotesConverter();
  const { keeperLocked } = useEncryptionKey();
  const { accountType } = useOrgManager();
  const snackbar = useSnackbarNotifications();

  const shouldDisplay: boolean = React.useMemo(() => {
    return (
      accountType === "admin" &&
      !!notesToConvert &&
      Object.keys(notesToConvert).length > 0
    );
  }, [accountType, notesToConvert]);

  const debouncedShouldDisplay = useDebounce(shouldDisplay, 500);

  const onButtonClicked = () => {
    setPerforming(true);

    perform()
      .then(({ converted, notConverted }) => {
        const anyConverted = Object.keys(converted).length > 0;
        const anyNotConverted = notConverted.length > 0;

        if (anyConverted) {
          if (anyNotConverted) {
            snackbar.success(t("messages.userNoteConverter.success"));
          } else {
            snackbar.info(t("messages.userNoteConverter.partialSuccess"));
          }
          dispatch(userNotesConverted(converted));
        } else if (anyNotConverted) {
          snackbar.error(t("messages.userNoteConverter.error"));
        }
      })
      .catch((e) => {
        snackbar.error(
          t("messages.userNoteConverter.errorWithMessage", {
            message: e.message,
          })
        );
      })
      .finally(() => {
        setPerforming(false);
      });
  };

  if (!debouncedShouldDisplay) return <></>;

  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Convert user notes
        </Typography>
        <Button
          variant="outlined"
          disabled={keeperLocked || performing}
          onClick={onButtonClicked}
        >
          {t(
            keeperLocked
              ? "messages.keeper.locked"
              : "buttons.users.convertNotes"
          )}
        </Button>
      </Paper>
    </Grid>
  );
};

export default ConvertNotesCard;
