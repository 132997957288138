import { FormikHelpers } from "formik";

import { ITransferForm } from "../../Forms/TransferManyKeysForm/TransferManyKeysForm.component";
import Modal from "../../UI/Modal/Modal.component";
import { UseMassKeyTransferState } from "../../../hooks/useMassKeyTransfer";
import { Key } from "../../Keys/Key";
import * as Views from "./views";

interface MassTransferKeysModalProps {
  open: boolean;
  keys: Key[];
  state: UseMassKeyTransferState;
  onComplete?: (removedKeys: Key[]) => any;
  onClose?: () => any;
}

const MassTransferKeysModal: React.FC<MassTransferKeysModalProps> = ({
  open,
  state,
  keys,
  onComplete,
  onClose,
}) => {
  const onFormSubmit = async (
    values: ITransferForm,
    formikProps: FormikHelpers<ITransferForm>
  ): Promise<any> => {
    return await state
      .perform(keys, values.address)
      .then((res) => {
        if (!onComplete) return;

        const removedKeysIds: string[] = res.published.map((tx) => tx.assetId);
        const removedKeys = keys.filter((key) =>
          removedKeysIds.includes(key.assetId)
        );

        if (removedKeys && removedKeys.length > 0) onComplete(removedKeys);
      })
      .catch((e) => {
        console.error("Failed to submit: ", e);
      });
  };

  return (
    <Modal
      show={open}
      closable={state.status !== "performing"}
      handleClose={onClose}
    >
      {state.status === "init" && (
        <Views.Form onClose={onClose} onFormSubmit={onFormSubmit} />
      )}
      {state.status === "performing" && (
        <Views.Performing counters={state.counters} />
      )}
      {state.status === "finished" && (
        <Views.Finished onClose={onClose} transactions={state.transactions} />
      )}
    </Modal>
  );
};

export default MassTransferKeysModal;
