import { CheckCircleOutline, WarningAmber } from "@mui/icons-material";
import { ButtonGroup, Button } from "@mui/material";
import { useTranslation, TFunction } from "react-i18next";

import { GroupedTransactions } from "../../../../hooks/useMassKeyBurn";
import Notification from "../../../UI/Notification/Notification.component";

import "./views.css";

interface FinishedProps {
  onClose?: () => any;
  transactions?: GroupedTransactions;
}

const AllKeysTransferred: React.FC<{ t: TFunction }> = ({ t }) => {
  return (
    <Notification
      title={
        t("keys.modals.transferManyKeys.finished.all") ??
        "All keys have been transferred"
      }
      Icon={CheckCircleOutline}
      iconColor="success"
    />
  );
};

const SomeKeysTransferred: React.FC<{ t: TFunction }> = ({ t }) => {
  return (
    <Notification
      title={
        t("keys.modals.transferManyKeys.finished.some") ??
        "Only some of keys were transferred"
      }
      Icon={WarningAmber}
      iconColor="warning"
    />
  );
};

const NoKeysTransferred: React.FC<{ t: TFunction }> = ({ t }) => {
  return (
    <Notification
      title={
        t("keys.modals.transferManyKeys.finished.none") ??
        "No keys were transferred"
      }
      Icon={WarningAmber}
      iconColor="error"
    />
  );
};

const TransactionsNotFinished: React.FC<{ t: TFunction }> = ({ t }) => {
  return (
    <Notification
      title={
        t("keys.modals.transferManyKeys.finished.notFinished") ??
        "Transactions haven't finished yet"
      }
      Icon={WarningAmber}
      iconColor="error"
    />
  );
};

const pickNotification = (
  t: TFunction,
  transactions?: GroupedTransactions
): JSX.Element => {
  if (!transactions) return <TransactionsNotFinished t={t} />;

  const anyPublished = transactions.published.length > 0;
  const anyNotPublished = transactions.notPublished.length > 0;

  if (anyPublished) {
    if (anyNotPublished) {
      return <SomeKeysTransferred t={t} />;
    } else {
      return <AllKeysTransferred t={t} />;
    }
  } else {
    return <NoKeysTransferred t={t} />;
  }
};

const Finished: React.FC<FinishedProps> = ({ onClose, transactions }) => {
  const { t } = useTranslation();
  return (
    <div className="transfer-many-keys-modal-content">
      {pickNotification(t, transactions)}
      <ButtonGroup>
        <Button variant="contained" onClick={onClose}>
          {t("buttons.close") ?? "Close"}
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default Finished;
