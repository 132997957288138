import { TFunction } from "react-i18next";
import yup from "../../../../helpers/yup-extended";

import { User } from "../../User";

const getLabel = (t: TFunction, field: string) => {
  return t(`forms.user.fields.${field}.label`);
};

const addressEditValidation = (t: TFunction, chainId: string) => {
  return yup
    .string()
    .blockchainAddress(
      t("forms.validation.blockchainAddress.invalid", {
        field: getLabel(t, "address"),
      })
    )
    .addressChainId(
      chainId,
      t("forms.validation.blockchainAddress.invalidChainId", {
        field: getLabel(t, "address"),
      })
    );
};

const addressAddValidation = (
  t: TFunction,
  users: { [key: string]: User },
  chainId: string
) => {
  return addressEditValidation(t, chainId)
    .required(
      t("forms.validation.required", {
        field: getLabel(t, "address"),
      })
    )
    .test(
      "address-uniqueness",
      t("forms.validation.string.exists", {
        field: getLabel(t, "address"),
      }),
      (add) => {
        if (!add) return false;

        return !users[add];
      }
    );
};

const mobileIdEditValidation = () => {
  return yup.string();
};

const mobileIdAddValidation = (
  t: TFunction,
  users: { [key: string]: User }
) => {
  return mobileIdEditValidation().test(
    "mobileId-uniqueness",
    t("forms.validation.string.exists", {
      field: getLabel(t, "mobileId"),
    }),
    (add) => {
      if (!add || add === "?" || add === "*") return true;

      const existing = Object.values(users).find((user) => {
        return user.mobileId === add;
      });

      return !existing;
    }
  );
};

// const noteValidation = (t: TFunction) => {
//   return yup
//     .string()
//     .optional()
//     .max(
//       1000,
//       t("forms.validation.string.max", {
//         field: getLabel(t, "note"),
//         max: 1000,
//       })
//     );
// };

export const buildValidationSchema = (
  t: TFunction,
  users: { [key: string]: User },
  chainId: string
) => {
  return yup.object().shape({
    address: addressAddValidation(t, users, chainId),
    mobileId: mobileIdAddValidation(t, users),
    // note: noteValidation(t),
  });
};

export const buildEditValidationSchema = (t: TFunction, chainId: string) => {
  return yup.object().shape({
    address: addressEditValidation(t, chainId),
    mobileId: mobileIdEditValidation(),
    // note: noteValidation(t),
  });
};
