/// <reference types="google.maps" />

import { useState, useEffect, useCallback } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerClusterer,
} from "@react-google-maps/api";
import { GOOGLE_MAPS_DARK_MODE_STYLE } from "../../../helpers/MapStyles";

import { DevicesPaginationParams } from "../../../providers/BlockchainNetworkProvider/DataServiceReadService";
import { Device, DeviceCollection } from "../../Devices/Device";
import DeviceMarker from "./DeviceMarker/DeviceMarker.component";
import { useDispatch } from "react-redux";
import * as mapLocalStorage from "./LocalStorage";

import { useBlockchainNetwork } from "../../../providers/BlockchainNetworkProvider/BlockchainNetworkProvider";
import DataServiceDisconnectedNotification from "../../UI/Notifications/DataServiceDisconnectedNotification.component";
import { useLayout } from "../../../providers/LayoutProvider/LayoutProvider";
import { useOrgManager } from "../../../providers/OrgManagerProvider/OrgManagerProvider";

const DevicesMap = (props: any) => {
  const dispatch = useDispatch();
  const { dsReadService } = useBlockchainNetwork();
  const [map, setMap] = useState<google.maps.Map>();
  const [devices, setDevices] = useState<DeviceCollection>({});
  const [markers, setMarkers] = useState<any>();

  const { darkMode } = useLayout();

  const mapContainerStyle = { width: "100%", height: "600px" };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  });

  const { organisationAddress } = useOrgManager();

  const parseLatLng = (point: google.maps.LatLng) => {
    return { lat: point.lat(), lng: point.lng() };
  };

  const buildParams = useCallback(
    (bounds: google.maps.LatLngBounds): DevicesPaginationParams => {
      const bottomLeft = bounds.getSouthWest();
      const upperRight = bounds.getNorthEast();
      return {
        page: 1,
        perPage: 100,
        keysOwner: organisationAddress,
        geoSearch: {
          bottomLeft: parseLatLng(bottomLeft),
          upperRight: parseLatLng(upperRight),
        },
      };
    },
    [organisationAddress]
  );

  const renderMarkers = useCallback(() => {
    return (
      <MarkerClusterer>
        {(clusterer) => {
          return Object.values(devices).map((device: Device) => (
            <DeviceMarker
              key={device.address}
              device={device}
              clusterer={clusterer}
              dispatch={dispatch}
            />
          ));
        }}
      </MarkerClusterer>
    );
  }, [devices, dispatch]);

  const updateDevices = useCallback(() => {
    if (!map || !organisationAddress || !dsReadService) return;

    const bounds = map.getBounds() as google.maps.LatLngBounds;
    if (!bounds) {
      setTimeout(() => {
        updateDevices();
      }, 200);
      return;
    }

    const { request, controller } = dsReadService.getDevices(
      buildParams(bounds)
    );

    request
      .then(({ devices }) => {
        setDevices(devices);
      })
      .catch((e) => {
        console.error("Failed to fetch devices: ", e);
      });

    return () => {
      controller.abort();
    };
  }, [map, organisationAddress, buildParams, dsReadService]);

  useEffect(() => {
    setMarkers(renderMarkers());
  }, [devices, renderMarkers]);

  useEffect(() => {
    if (!map || !organisationAddress) return;

    updateDevices();
  }, [map, organisationAddress, updateDevices]);

  const setCenter = () => {
    if (!map) return;

    const center = map.getCenter();
    if (!center) return;

    mapLocalStorage.setCenter(center);
  };

  const setZoom = () => {
    if (!map) return;

    const zoom = map.getZoom();
    if (!zoom) return;

    mapLocalStorage.setZoom(zoom);
  };

  const onDragEnd = () => {
    updateDevices();
    setCenter();
  };

  const onZoomChanged = () => {
    updateDevices();
    setZoom();
  };

  const onLoad = (map: google.maps.Map) => {
    setMap(map);

    setTimeout(() => {
      map.panTo(mapLocalStorage.getCenter());
      map.setZoom(mapLocalStorage.getZoom());
    }, 1);
  };

  if (!dsReadService) return <DataServiceDisconnectedNotification />;

  return isLoaded ? (
    <GoogleMap
      id="devicesMap"
      mapContainerStyle={mapContainerStyle}
      onDragEnd={onDragEnd}
      onZoomChanged={onZoomChanged}
      onLoad={onLoad}
      options={{
        styles: darkMode ? GOOGLE_MAPS_DARK_MODE_STYLE : null,
      }}
    >
      {markers}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default DevicesMap;
