import { Delete } from "@mui/icons-material";

import { useOrgManager } from "../../../../providers/OrgManagerProvider/OrgManagerProvider";
import { tokenEntryDeactivated } from "../../../../actions/tokensActions";

import TokenAction from "../../TokensTable/Actions/TokenAction.component";
import { TokenEntry } from "../../Token";

const DeleteTokenEntry: React.FC<{ entry: TokenEntry }> = ({ entry }) => {
  const { writeService } = useOrgManager();

  const onClicked = async () => {
    return await writeService?.removeToken(entry.assetId);
  };

  return (
    <TokenAction
      key="delete"
      actionKey="delete"
      onClicked={onClicked}
      onSuccessTlKey="messages.tokenEntries.entryDeleted"
      onSuccessDispatchAction={tokenEntryDeactivated(entry)}
      tooltipTlKey="buttons.tokens.remove"
      color="error"
      Icon={Delete}
    />
  );
};

export default DeleteTokenEntry;
