import { useState } from "react";

import { Key } from "../components/Keys/Key";
import { TRANSACTION_PACKAGE_LIMIT } from "../providers/OrgManagerProvider/OrgManagerProvider";
import { chunk } from "lodash";
import { useOrgManager } from "../providers/OrgManagerProvider/OrgManagerProvider";

export type BurnStatus = "init" | "performing" | "finished" | "error";

export interface BurnCounters {
  total: { transactions: number; packages: number };
  processed: { transactions: number; packages: number };
}

export interface GroupedTransactions {
  published: any[];
  notPublished: any[];
}

export interface UseMassKeyBurnState {
  status: BurnStatus;
  perform: (keys: Key[]) => Promise<GroupedTransactions>;
  reset: () => void;
  counters: BurnCounters;
  transactions?: GroupedTransactions;
}

const EMPTY_COUNTERS: BurnCounters = {
  total: { transactions: 0, packages: 0 },
  processed: { transactions: 0, packages: 0 },
};

/**
 * Hook used to handle mass burn of Keys
 * Transactions are grouped into packages to limit amount of required
 * confirmations from the user
 *
 * State also contains counters for processed transactions and packages
 */
const useMassKeyBurn = (): UseMassKeyBurnState => {
  const [status, setStatus] = useState<BurnStatus>("init");
  const [counters, setCounters] = useState<BurnCounters>(EMPTY_COUNTERS);
  const [transactions, setTransactions] =
    useState<GroupedTransactions | undefined>();

  const { writeService } = useOrgManager();

  const reset = () => {
    setStatus("init");
    setCounters({ ...EMPTY_COUNTERS });
    setTransactions(undefined);
  };

  const buildBurnTransactions = (
    keys: Key[]
  ): (WavesKeeper.TBurnTxData | WavesKeeper.TScriptInvocationTxData)[] => {
    if (!writeService) {
      throw new Error("Keeper not found");
    }
    return keys.map(({ assetId }) => {
      return writeService.burnKeyData(assetId);
    });
  };

  const updateTotalCounters = (transactions: any[], packages: any[]) => {
    setCounters((prev) => ({
      ...prev,
      total: { transactions: transactions.length, packages: packages.length },
    }));
  };

  // Callback to perform when a single transaction is signed and published
  const onBurnTxProcessed = (singedTx: any) => {
    setCounters((prev) => ({
      ...prev,
      processed: {
        ...prev.processed,
        transactions: prev.processed.transactions + 1,
      },
    }));
  };

  /**
   * Perform burn of all Keys
   * @param {Key[]} keys - list of keys to burn
   */
  const perform = async (keys: Key[]) => {
    // first check if Keeper is available
    if (!writeService) {
      throw new Error("Keeper not available");
    }

    // let's clear the state first
    reset();

    // Okay, now we're performing
    setStatus("performing");

    // build Burn Transactions
    const burnTxes = buildBurnTransactions(keys);

    // Chunk transactions into groups
    const packages = chunk(burnTxes, TRANSACTION_PACKAGE_LIMIT);

    // Update counters of transactions and packages
    updateTotalCounters(burnTxes, packages);

    const published: any[] = [];
    const notPublished: any[] = [];

    // build Promises
    const promises = packages.map(async (group) => {
      // sign the package
      return await writeService
        .signTransactionPackage(group)
        .then(async (signedTxes) => {
          // broadcast signed transactions
          return await writeService.broadcastTransactionPackage(
            signedTxes,
            onBurnTxProcessed
          );
        })
        .then((txes) => {
          // insert published and not published transactions
          // into appropiate variables
          published.push(...txes.published);
          notPublished.push(...txes.notPublished);
        })
        .catch((e) => {
          // broadcasting has failed
          setCounters((prev) => ({
            ...prev,
            processed: {
              ...prev.processed,
              transactions: prev.processed.transactions + group.length,
            },
          }));

          // add all unpublished transactions
          // to not published group
          notPublished.push(...group);
        })
        .finally(() => {
          // Package is processed, update the counter
          setCounters((prev) => ({
            ...prev,
            processed: {
              ...prev.processed,
              packages: prev.processed.packages + 1,
            },
          }));
        });
    });

    // Wait for all transactions to finish
    await Promise.all(promises)
      .then((res) => {
        setStatus("finished");
      })
      .catch((e) => {
        setStatus("error");
        console.error("[useMassKeyBurn] Failed to broadcast: ", e.message);
      });

    setTransactions({ published, notPublished });

    return { published, notPublished };
  };

  return {
    status,
    perform,
    reset,
    counters,
    transactions,
  };
};

export default useMassKeyBurn;
