import { AnyAction } from "redux";

import * as types from "../actions/types";
import { Key, KeyCollection } from "../components/Keys/Key";
import { PaginationItemsMeta } from "../helpers/helpers";

export type MassKeysActionType = "init" | "performing" | "finished";

export interface IKeysReducer {
  data: KeyCollection;
  devices: { [key: string]: string };
  transferKey?: Key;
  meta: PaginationItemsMeta;
}

const initialValue: IKeysReducer = {
  data: {},
  devices: {},
  transferKey: undefined,
  meta: { total: 0 },
};

const removeKey = (state: IKeysReducer, key: Key): KeyCollection => {
  const keysCopy = { ...state.data };
  delete keysCopy[key.assetId];

  return keysCopy;
};

const removeDevice = (
  state: IKeysReducer,
  key: Key
): { [key: string]: string } => {
  const devicesCopy = { ...state.devices };
  delete devicesCopy[key.assetId];

  return devicesCopy;
};

const removeMultipleKeys = (
  state: IKeysReducer,
  burnedKeys: KeyCollection
): IKeysReducer => {
  const keysCopy: KeyCollection = { ...state.data };
  const devicesCopy = { ...state.devices };
  const metaCopy = { ...state.meta };

  Object.values(burnedKeys).forEach((deletedKey: Key) => {
    delete keysCopy[deletedKey.assetId];
    delete devicesCopy[deletedKey.assetId];
  });

  metaCopy.total = Math.max(metaCopy.total - Object.keys(burnedKeys).length, 0);

  return {
    ...state,
    data: keysCopy,
    devices: devicesCopy,
    meta: metaCopy,
  };
};

const keyBurned = (state: IKeysReducer, action: AnyAction) => {
  return {
    ...state,
    data: removeKey(state, action.payload.key),
    devices: removeDevice(state, action.payload.key),
  };
};

const keysFetched = (state: IKeysReducer, action: AnyAction): IKeysReducer => {
  const keys: KeyCollection = action.payload.keys;

  const keyDevices: { [key: string]: string } = {};

  Object.values(keys).forEach((key: Key) => {
    keyDevices[key.assetId] = key.device as string;
  });

  return {
    ...state,
    data: keys,
    devices: keyDevices,
    meta: action.payload.meta,
  };
};

const keysReducer = (state = initialValue, action: AnyAction): IKeysReducer => {
  switch (action.type) {
    case types.KEYS_FETCHED:
      return keysFetched(state, action);
    case types.KEY_BURNED:
      return keyBurned(state, action);
    case types.KEY_TRANSFER_INIT:
      return {
        ...state,
        transferKey: action.payload.key,
      };
    case types.KEY_TRANSFER_CANCEL:
      return {
        ...state,
        transferKey: undefined,
      };
    case types.KEY_TRANSFERED:
      return {
        ...state,
        data: removeKey(state, action.payload.key),
        devices: removeDevice(state, action.payload.key),
        transferKey: undefined,
      };
    case types.DEVICE_KEYS_BURN_PARTIALLY_BURNED:
      return removeMultipleKeys(state, action.payload.keys);
    case types.DEVICE_KEYS_BURN_FINISHED:
      return removeMultipleKeys(state, action.payload.keys);
    case types.KEYS_REMOVED:
      return removeMultipleKeys(state, action.payload.keys);
    default:
      return state;
  }
};

export default keysReducer;
