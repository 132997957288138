import Modal from "../../UI/Modal/Modal.component";
import CreateManagerForm from "../../Managers/Forms/CreateManagerForm/CreateManagerForm.component";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

const AddManagerModal = (props: { show: boolean; handleClose: () => void }) => {
  const { t } = useTranslation();

  return (
    <Modal
      closable={true}
      show={props.show}
      maxWidth="sm"
      handleClose={props.handleClose}
      title={t("tables.managers.actions.add")}
    >
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid item xs={12} sx={{ mt: 2 }}>
          <CreateManagerForm onClose={props.handleClose} />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AddManagerModal;
