import { MoveDown } from "@mui/icons-material";

import { useOrgManager } from "../../../../providers/OrgManagerProvider/OrgManagerProvider";
import { tokenDeactivated } from "../../../../actions/tokensActions";

import TokenAction from "./TokenAction.component";
import { Token } from "../../Token";

const DeactivateToken: React.FC<{ token: Token }> = ({ token }) => {
  const { writeService } = useOrgManager();

  const onClicked = async () => {
    return await writeService?.setTokenStatus(token.assetId, false);
  };

  return (
    <TokenAction
      actionKey="deactivate"
      onClicked={onClicked}
      onSuccessTlKey="messages.tokens.tokenDeactivated"
      onSuccessDispatchAction={tokenDeactivated(token)}
      tooltipTlKey="buttons.tokens.deactivate"
      color="warning"
      Icon={MoveDown}
    />
  );
};

export default DeactivateToken;
