import React from "react";
import { Account } from "../../../../helpers/accountUtils";
import { Grid, Typography, Button } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { useOrgManager } from "../../../../providers/OrgManagerProvider/OrgManagerProvider";
import LoadingNotification from "../../../UI/LoadingNotification/LoadingNotification.component";
import { managerCreated } from "../../../../actions/managersActions";
import { Manager } from "../..";
import { useKeeper } from "../../../../providers/KeeperProvider/KeeperProvider";
import SeedInput from "./components/SeedInput";
import Notification from "../../../UI/Notification/Notification.component";
import { useEncryptionKey } from "../../../../providers/OrgManagerProvider/EncryptionKeyProvider";
import useSnackbarNotifications from "../../../../hooks/useSnackbarNotifications";

interface CreateManagerFormProps {
  onClose: () => any;
}

const CreateManagerForm: React.FC<CreateManagerFormProps> = ({ onClose }) => {
  // Hooks
  const dispatch = useDispatch();
  const snackbar = useSnackbarNotifications();
  const { t } = useTranslation();

  const [account, setAccount] = React.useState<Account | undefined>();
  const [completed, setCompleted] = React.useState<boolean>(false);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [submittingLabel, setSubmittingLabel] = React.useState<string>("");
  const [submittingSubLabel, setSubmittingSubLabel] =
    React.useState<string>("");

  const { skeyService, writeService } = useOrgManager();
  const { readService, encryptor } = useKeeper();
  const { encParams } = useEncryptionKey();

  // Functions

  const refreshAccount = () => {
    if (!skeyService) return;

    setAccount(skeyService.generateAccount());
  };

  const addManager = async (account: Account): Promise<void> => {
    if (!writeService || !skeyService) {
      return Promise.reject(t("messages.keeper.unavailable"));
    }

    if (!encryptor) {
      return Promise.reject(t("messages.keeper.locked"));
    }

    if (!encParams) {
      return Promise.reject("Cannot create manager - encryption key not set");
    }

    // Encryption key for the manager

    const encKey = await encryptor.encrypt(encParams.encKey, account.publicKey);
    let encIv: string | undefined;

    if (encParams.encIv) {
      encIv = await encryptor.encrypt(encParams.encIv, account.publicKey);
    }

    setSubmittingSubLabel(t("messages.keeper.awaitingUserInput"));
    return await writeService
      .addManager(account.address, {
        publicKey: account.publicKey,
        encKey,
        encIv,
      })
      .then(async (txId) => {
        setSubmittingSubLabel(t("messages.keeper.waitingForConfirmation"));
        await skeyService.waitForConfirm(txId);
      });
  };

  const checkPublicKey = async (account: Account): Promise<boolean> => {
    if (!readService) {
      return Promise.reject(t("messages.keeper.unavailable"));
    }

    return await readService
      .fetchPublicKey(account.address)
      .then((res) => {
        return !!res && res === account.publicKey;
      })
      .catch((e: any) => {
        return false;
      });
  };

  const setPublicKey = async (account: Account): Promise<void> => {
    if (!skeyService) {
      return Promise.reject(t("messages.keeper.unavailable"));
    }

    const publicKeyPresent = await checkPublicKey(account);

    if (publicKeyPresent) {
      return;
    }

    setSubmittingSubLabel(t("messages.keeper.broadcasting"));
    return await skeyService.exposePublicKey(account).then(async (txId) => {
      setSubmittingSubLabel(t("messages.keeper.waitingForConfirmation"));
      await skeyService.waitForConfirm(txId);
    });
  };

  const checkBalance = async (
    account: Account,
    threshold: number
  ): Promise<boolean> => {
    if (!readService) {
      return Promise.reject(t("messages.keeper.unavailable"));
    }

    setSubmittingSubLabel(
      t("messages.keeper.checkingBalance", { account: account.address })
    );

    let enoughFunds = false;

    await readService.fetchBalance(account.address).then((balance) => {
      if (balance && balance > threshold) {
        enoughFunds = true;
      }
    });

    return enoughFunds;
  };

  const transferFunds = async (account: Account): Promise<void> => {
    if (!writeService || !readService || !skeyService) {
      return Promise.reject(t("messages.keeper.unavailable"));
    }

    // check if public key on the account already exists
    const publicKeyPresent = await checkPublicKey(account);

    if (publicKeyPresent) {
      return;
    }

    // check current balance of account and skip it if there's enough for the transaction
    const enoughFunds = await checkBalance(account, 10 * 4).catch((e) => {
      return false;
    });

    if (enoughFunds) return;

    // broadcast the transaction

    setSubmittingSubLabel(t("messages.keeper.awaitingUserInput"));

    return await writeService
      .sendFunds(account.address, 0.001)
      .then(async (res) => {
        setSubmittingSubLabel(t("messages.keeper.waitingForConfirmation"));
        await skeyService.waitForConfirm(res.id);
      });
  };

  const handleSubmit = async (account: Account): Promise<Manager> => {
    if (!skeyService || !writeService) {
      return Promise.reject(t("messages.keeper.unavailable"));
    }

    // Adding a Manager has 3 steps:

    // 1. Transfer funds needed to set public key on manager account,
    // 2. Set public key in data storage of manager account,
    // 3. Add managers' public key on the list of managers.

    setSubmittingLabel(t("messages.managers.create.steps.transferFunds"));
    setSubmittingSubLabel(t("messages.keeper.awaitingUserInput"));
    return await transferFunds(account)
      .then(async () => {
        setSubmittingLabel(t("messages.managers.create.steps.setPublicKey"));

        return await setPublicKey(account);
      })
      .then(async () => {
        setSubmittingLabel(t("messages.managers.create.steps.addManager"));
        return await addManager(account);
      })
      .then(async () => {
        return Promise.resolve({ address: account.address });
      })
      .catch((e) => {
        return Promise.reject(e.message ? e.message : e);
      });
  };

  const onSubmit = () => {
    if (!account) {
      return;
    }

    setSubmitting(true);

    handleSubmit(account)
      .then((manager) => {
        snackbar.success(
          t("messages.managers.create.added") || "Manager added"
        );
        dispatch(managerCreated(manager));
        setCompleted(true);
      })
      .catch((e) => {
        snackbar.error(
          t("messages.managers.create.failed", { message: e }) ||
            `Failed to submit: ${e}`
        );
      })
      .finally(() => {
        setSubmitting(false);
        setSubmittingLabel("");
        setSubmittingSubLabel("");
      });
  };

  // Callbacks

  React.useEffect(() => {
    if (!skeyService || account) return;

    setAccount(skeyService.generateAccount());
  }, [skeyService, account]);

  // Content
  if (!account) {
    return (
      <Typography variant="h6">
        {t("messages.managers.create.unavailable")}
      </Typography>
    );
  }

  let header;

  if (submitting) {
    header = (
      <LoadingNotification
        label={submittingLabel}
        subLabel={submittingSubLabel}
      />
    );
  } else if (completed) {
    header = (
      <Grid container direction="column" alignItems="stretch" spacing={2}>
        <Grid item>
          <Notification
            title={t("messages.managers.create.added") || "Manager added"}
            Icon={CheckCircleOutline}
            iconColor="success"
          />
        </Grid>
      </Grid>
    );
  } else {
    header = (
      <Grid item xs={12}>
        <Typography variant="body1">
          {t("managers.create.body2", { submit: t("buttons.save") })}
        </Typography>
        <Typography variant="body1">
          {t("managers.create.body3", { reload: t("buttons.reload") })}
        </Typography>
      </Grid>
    );
  }

  return (
    <>
      <Grid container direction="column" alignItems="stretch" spacing={2}>
        {header}
        <SeedInput
          account={account}
          onRefreshClicked={refreshAccount}
          refreshDisabled={submitting || completed}
        />
        {completed && (
          <Grid item xs={12}>
            <Typography variant="body1">
              {t("managers.create.completed")}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            onClick={onSubmit}
            variant="contained"
            disabled={submitting || completed}
          >
            {t("buttons.save")}
          </Button>
          <Button
            onClick={onClose}
            variant="contained"
            color="error"
            disabled={submitting}
          >
            {t("buttons.close")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateManagerForm;
