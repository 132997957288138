import { Tooltip, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { TokenActionProps } from "./Actions";
import useSnackbarNotifications from "../../../../hooks/useSnackbarNotifications";

const TokenAction: React.FC<TokenActionProps> = ({
  actionKey,
  onClicked,
  onSuccessTlKey,
  onSuccessDispatchAction,
  tooltipTlKey,
  color,
  Icon,
}) => {
  const { t } = useTranslation();
  const snackbar = useSnackbarNotifications();

  const dispatch = useDispatch();

  const handleClicked = () => {
    onClicked()
      .then(() => {
        snackbar.success(t(onSuccessTlKey));
        dispatch(onSuccessDispatchAction);
      })
      .catch((e: any) => {
        snackbar.error(
          t("messages.keeper.broadcastFailed", { message: e.message })
        );
      });
  };

  return (
    <Tooltip title={t(tooltipTlKey) || "Do something"}>
      <IconButton
        key={actionKey}
        color={(color || "success") as any}
        onClick={handleClicked}
      >
        {<Icon />}
      </IconButton>
    </Tooltip>
  );
};

export default TokenAction;
