import { Manager, ManagerCollection } from "../components/Managers";
import { MANAGERS_FETCHED, MANAGER_CREATED, MANAGER_DELETED } from "./types";

export const managersFetched = (managers: ManagerCollection) => {
  return {
    type: MANAGERS_FETCHED,
    payload: {
      collection: managers,
    },
  };
};

export const managerCreated = (manager: Manager) => {
  return {
    type: MANAGER_CREATED,
    payload: { manager },
  };
};

export const managerDeleted = (manager: Manager) => {
  return {
    type: MANAGER_DELETED,
    payload: { manager },
  };
};
