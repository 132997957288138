import { MoveUp } from "@mui/icons-material";

import { useOrgManager } from "../../../../providers/OrgManagerProvider/OrgManagerProvider";
import { tokenActivated } from "../../../../actions/tokensActions";

import TokenAction from "./TokenAction.component";
import { Token } from "../../Token";

const ActivateToken: React.FC<{ token: Token }> = ({ token }) => {
  const { writeService } = useOrgManager();

  const onClicked = async () => {
    return await writeService?.setTokenStatus(token.assetId, true);
  };

  return (
    <TokenAction
      actionKey="activate"
      onClicked={onClicked}
      onSuccessTlKey="messages.tokens.tokenActivated"
      onSuccessDispatchAction={tokenActivated(token)}
      tooltipTlKey="buttons.tokens.activate"
      color="success"
      Icon={MoveUp}
    />
  );
};

export default ActivateToken;
