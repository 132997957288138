import { useTranslation } from "react-i18next";
import AccountNotification from "./AccountNotification";

const AccountTxFailed: React.FC = () => {
  const { t } = useTranslation();

  return (
    <AccountNotification
      message={t("orgSelector.accountStatus.failedTransaction.body")}
      clickToSwitchTo="pendingTransaction"
      buttonLabel={t(
        "orgSelector.accountStatus.failedTransaction.proceedButton"
      )}
    />
  );
};

export default AccountTxFailed;
