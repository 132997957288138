import React from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";

interface LoadingNotificationProps {
  label?: string;
  subLabel?: string;
  children?: React.ReactNode;
}

const LoadingNotification: React.FC<LoadingNotificationProps> = ({
  label,
  children,
  subLabel,
}) => {
  return (
    <Grid container direction="column" alignItems="center" spacing={2}>
      <Grid item sm={12}>
        <Grid container direction="column" alignItems="stretch">
          <CircularProgress size={100} />
        </Grid>
      </Grid>
      {label && (
        <Grid item justifyContent="center" alignContent="center">
          <Typography variant="h5" align="center">
            {label}
          </Typography>
          {subLabel && (
            <Typography variant="body2" align="center">
              {subLabel}
            </Typography>
          )}
          {children}
        </Grid>
      )}
    </Grid>
  );
};

export default LoadingNotification;
