import { TFunction } from "i18next";
import { Column } from "react-table";

import renderActions from "./DeviceActions";
import VisibleFormatter from "./Formatters/VisibleFormatter";
import ConnectedFormatter from "./Formatters/ConnectedFormatter";
import ValidToFormatter from "./Formatters/ValidToFormatter";
import { CellArgument } from "../../UI/MUITable/ITable";
import { Device } from "../Device";
import AddressFormatter from "./Formatters/AddressFormatter";

const buildColumns = (t: TFunction): Column<Device>[] => {
  return [
    {
      accessor: "name",
      Header: t("tables.devices.columns.name") ?? "name",
    },
    {
      accessor: "address",
      Header: t("tables.devices.columns.address") ?? "address",
      Cell: (arg: CellArgument<Device>) => (
        <AddressFormatter address={arg.row.original.address} />
      ),
    },
    {
      accessor: "visible",
      Header: t("tables.devices.columns.visible") ?? "visible",
      Cell: (arg: CellArgument<Device>) => (
        <VisibleFormatter device={arg.row.original} />
      ),
    },
    {
      accessor: "connected",
      Header: t("tables.devices.columns.connected") ?? "connected",
      Cell: (arg: CellArgument<Device>) => (
        <ConnectedFormatter device={arg.row.original} />
      ),
    },
    {
      accessor: "key.validTo" as any,
      Header: t("tables.devices.columns.validTo") ?? "Valid To",
      Cell: (arg: CellArgument<Device>) => (
        <ValidToFormatter device={arg.row.original} />
      ),
    },
    {
      id: "actions",
      Header: t("tables.devices.columns.actions") ?? "actions",
      Cell: (arg: CellArgument<Device>) => renderActions(arg.row.original),
    },
  ];
};

export default buildColumns;
