import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import * as Semver from "semver";

import { IRootReducer } from "../reducers";
import { verifierChanged } from "../actions/wavesKeeperActions";
import { useKeeper } from "../providers/KeeperProvider/KeeperProvider";
import { useOrgManager } from "../providers/OrgManagerProvider/OrgManagerProvider";
import { useTranslation } from "react-i18next";
import useSnackbarNotifications from "./useSnackbarNotifications";
import { useAccountScript } from "../providers/KeeperProvider/AccountScriptProvider";
import useAccountScriptVersionCheck from "./useAccountScriptVersionCheck";

// Workflow

// First verifier waits until WavesKeeper is successfully authorized
// Then it fetches the script of the account from the blockchain
// If it fails, informs the user about failed verification
// Compare fetched script with provided one
// If scripts match, verification is successful
// If scripts don't match, ask user if he wants to set the script
// User rejects, verification fails
// User accepts, then create a setScript transaction and send it to Waves Keeper
// If transaction goes through, verfification is successful
// Otherwise ask user if he wants to send it again

export type VerifierStatus =
  | "loading"
  | "waiting" // waiting for WavesKeeper to verify account
  | "init" // Initialized
  | "scriptFetching" // fetching script data from blockchain
  | "scriptFetchFailed" // script fetch has failed
  | "scriptOutdated" // Script is outdated
  | "pendingConfirm" // Awaiting confirmation from user
  | "pendingTransaction" // Transaction has been created, waiting for broadcast
  | "verified" // user is either confirmed or has broadcasted a transaction
  | "failedTransaction" // Transaction has failed
  | "denied" // User denied verification
  | "noAccount"; // Keeper does not have an account

// const DAPPS_URL =
//   "https://raw.githubusercontent.com/skey-network/skey-client-config/master/dapps.json";

const DAPP =
  "https://raw.githubusercontent.com/a-jasinski/sandbox/main/org.base64";

const CURRENT_SCRIPT_VERSION: Semver.SemVer = Semver.coerce(
  "2"
) as Semver.SemVer;

const useOrgVerifier = () => {
  const dispatch = useDispatch();
  const { orgVerifier } = useSelector(
    (store: IRootReducer) => store.wavesKeeper
  );

  const { publicState, readService, status } = useKeeper();
  const {
    script: accountScript,
    fetched: accountScriptFetched,
    loading: accountScriptLoading,
  } = useAccountScript();
  const { findScriptVersion } = useAccountScriptVersionCheck();
  const { writeService } = useOrgManager();
  const snackbar = useSnackbarNotifications();
  const { t } = useTranslation();

  const [orgScript, setOrgScript] = useState<string | undefined>();
  const [pendingTx, setPendingTx] = useState<boolean>(false);

  const setOrgVerified = useCallback(
    (newValue: VerifierStatus) => {
      if (orgVerifier === newValue) {
        return;
      }

      dispatch(verifierChanged(newValue));
    },
    [orgVerifier, dispatch]
  );

  const currentScript = useMemo(async (): Promise<string | undefined> => {
    return await axios.get(DAPP).then((res) => {
      return (res.data as string).trim();
    });
  }, []);

  // loading
  React.useEffect(() => {
    if (
      orgVerifier !== "loading" ||
      !accountScriptFetched ||
      accountScriptLoading
    )
      return;
    setOrgVerified("waiting");
  }, [accountScriptFetched, orgVerifier, setOrgVerified, accountScriptLoading]);

  // waiting - script is not present
  React.useEffect(() => {
    if (orgVerifier !== "waiting" && !!orgScript) return;

    currentScript
      .then((script) => {
        setOrgScript(script);
      })
      .catch((e) => {
        setOrgVerified("scriptFetchFailed");
      });
  }, [orgVerifier, currentScript, orgScript, setOrgVerified]);

  // waiting - script is present
  React.useEffect(() => {
    if (orgVerifier !== "waiting" || !orgScript || !accountScriptFetched)
      return;

    if (!readService) {
      setOrgVerified("scriptFetchFailed");
      return;
    }

    if (!accountScriptFetched) return;

    if (accountScript === orgScript) {
      setOrgVerified("verified");
    } else {
      if (!accountScript) {
        setOrgVerified("pendingConfirm");
        return;
      }

      const accountScriptVersion = findScriptVersion(accountScript);

      if (!accountScriptVersion) {
        // Not an organisation script
        setOrgVerified("pendingConfirm");
        return;
      }

      const coercedAccountScriptVersion = Semver.coerce(
        accountScriptVersion
      ) as Semver.SemVer;

      if (Semver.gt(CURRENT_SCRIPT_VERSION, coercedAccountScriptVersion)) {
        setOrgVerified("scriptOutdated");
        return;
      }
    }
  }, [
    status,
    orgVerifier,
    orgScript,
    publicState,
    readService,
    setOrgVerified,
    accountScript,
    accountScriptFetched,
    findScriptVersion,
  ]);

  // pendingTransaction
  React.useEffect(() => {
    if (orgVerifier !== "pendingTransaction" || !orgScript || pendingTx) return;

    setPendingTx(true);
    writeService
      ?.setScript(orgScript)
      .then(() => {
        setOrgVerified("verified");
      })
      .catch((e) => {
        setOrgVerified("failedTransaction");
        snackbar.error(
          t("messages.keeper.transactionFailed", { message: e.message })
        );
      })
      .finally(() => {
        setPendingTx(false);
      });
  }, [
    orgScript,
    orgVerifier,
    setOrgVerified,
    writeService,
    pendingTx,
    snackbar,
    t,
  ]);

  useEffect(() => {
    if (accountScriptFetched || !accountScriptLoading) return;
    setOrgVerified("loading");
  }, [accountScriptFetched, accountScriptLoading, setOrgVerified]);

  return orgVerifier;
};

export default useOrgVerifier;
