import React from "react";

import {
  Dialog as MaterialModal,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  Breakpoint,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export interface IModal {
  show?: boolean;
  handleClose?: () => any;
  closable?: boolean;
  title?: string;
  footer?: JSX.Element | null;
  maxWidth?: Breakpoint;
}

const defaults: IModal = {
  show: false,
  closable: true,
};

const Modal: React.FunctionComponent<IModal> = (props) => {
  props = { ...defaults, ...props };

  const handleClose = () => {
    if (!props.closable || !props.handleClose) return;

    props.handleClose();
  };

  const closeButton = React.useMemo(() => {
    if (!props.closable) return;

    return (
      <IconButton onClick={props.handleClose} style={{ padding: "0px" }}>
        <CloseIcon />
      </IconButton>
    );
  }, [props.closable, props.handleClose]);

  const title = React.useMemo(() => {
    if (!props.title) return;

    return (
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "30px",
        }}
      >
        {props.title}
        {closeButton}
      </DialogTitle>
    );
  }, [closeButton, props.title]);

  const footer = React.useMemo(() => {
    if (!props.footer) return;

    return <DialogActions>{props.footer}</DialogActions>;
  }, [props.footer]);

  return (
    <MaterialModal
      open={props.show!}
      maxWidth={props.maxWidth || "md"}
      fullWidth
      onClose={handleClose}
    >
      {title}
      <DialogContent>{props.children}</DialogContent>
      {footer}
    </MaterialModal>
  );
};

export default Modal;
