import React from "react";
import { TextField } from "@mui/material";

import { TableInstanceProps } from "../ITable";

const SearchInput: React.FC<TableInstanceProps> = ({
  instance,
  options,
  mode,
  onTableChange,
}) => {
  const [search, setSearch] = React.useState("");

  const onSearchChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    setSearch(e.target.value);

    if (mode === "local") {
      instance.setGlobalFilter(e.target.value);
    } else {
      onTableChange("filter", { searchText: e.target.value });
    }
  };

  return (
    <TextField
      variant="outlined"
      value={search}
      onChange={onSearchChange}
      label={options.searchInputLabel ?? "Search"}
      size="small"
      sx={{
        maxWidth: "400px",
        width: "100%",
        marginTop: "5px",
        marginLeft: "10px",
      }}
    />
  );
};

export default SearchInput;
