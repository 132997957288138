import React from "react";
import { blake2b } from "@waves/ts-lib-crypto";

import { useAccountScript } from "../providers/KeeperProvider/AccountScriptProvider";

const VERSIONS_DATA_URL =
  "https://raw.githubusercontent.com/skey-network/skey-client-config/master/scriptHashes.json";

type AccountScriptsVersionHashesCollection = {
  [version: string]: string;
};

const useAccountScriptVersionCheck = () => {
  const [fetched, setFetched] = React.useState<boolean>(false);
  const [scriptVersion, setScriptVersion] = React.useState<string>();
  const [versioningData, setVersioningData] =
    React.useState<AccountScriptsVersionHashesCollection>({});

  const { script } = useAccountScript();

  const getScriptHash = (script: string): string => {
    const encodedScriptHash = blake2b(Buffer.from(script));
    return Buffer.from(encodedScriptHash).toString("hex");
  };

  const findScriptVersion = React.useCallback(
    (script: string): string | undefined => {
      const scriptHash = getScriptHash(script);

      const versionEntry = Object.entries(versioningData).find(
        ([_version, hash]) => {
          return hash === scriptHash;
        }
      );

      return versionEntry?.[0];
    },
    [versioningData]
  );

  React.useEffect(() => {
    if (fetched) return;

    setScriptVersion(undefined);

    fetch(VERSIONS_DATA_URL)
      .then((res) => res.json())
      .then((data) => {
        setVersioningData(data.organisation);
      })
      .finally(() => {
        setFetched(true);
      });
  }, [fetched]);

  React.useEffect(() => {
    if (!fetched) return;

    if (!script) {
      setScriptVersion(undefined);
      return;
    }

    setScriptVersion(findScriptVersion(script));
  }, [fetched, findScriptVersion, script, versioningData]);

  return { scriptVersion, findScriptVersion };
};

export default useAccountScriptVersionCheck;
