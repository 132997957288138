import { useEffect, useState } from "react";
import { useKeeper } from "../providers/KeeperProvider/KeeperProvider";
import useDebounce from "./useDebounce";

const usePublicAddress = () => {
  const [publicAddress, setPublicAddress] = useState<string | undefined>();
  const { publicState } = useKeeper();

  const debouncedPublicAddress = useDebounce(publicAddress, 200);

  useEffect(() => {
    const currentAddress = publicState?.account?.address;
    if (currentAddress === publicAddress) return;

    setPublicAddress(currentAddress);
  }, [publicState, publicAddress]);

  return debouncedPublicAddress;
};

export default usePublicAddress;
