import { useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  FormGroup,
  ButtonGroup,
} from "@mui/material";
import { QrCodeScanner } from "@mui/icons-material";
import { Formik, FormikHelpers } from "formik";
import Yup from "../../../helpers/yup-extended";
import { useTranslation } from "react-i18next";

import QrScannerModal from "../../Modals/QrScannerModal/QrScannerModal.component";
import { useKeeper } from "../../../providers/KeeperProvider/KeeperProvider";
import useSnackbarNotifications from "../../../hooks/useSnackbarNotifications";

export interface ITransferForm {
  address: string;
}

const TransferForm = (props: {
  perform: (
    values: ITransferForm,
    formikProps: FormikHelpers<ITransferForm>
  ) => Promise<any>;
  onClose?: () => void;
}) => {
  const { t } = useTranslation();
  const [scannerEnabled, setScannerEnabled] = useState<boolean>(false);
  const snackbar = useSnackbarNotifications();
  const { publicState } = useKeeper();

  const validationSchema = Yup.object().shape({
    address: Yup.string()
      .required(
        t("forms.validation.required", {
          field: t("forms.transferKey.fields.address.label"),
        })
      )
      .blockchainAddress(
        t("forms.validation.blockchainAddress.invalid", {
          field: t("forms.transferKey.fields.address.label"),
        })
      )
      .addressChainId(
        publicState?.network.code as string,
        t("forms.sendToken.errors.address.invalidChainId")
      ),
  });

  const onSubmit = (
    values: ITransferForm,
    formikProps: FormikHelpers<ITransferForm>
  ) => {
    formikProps.setSubmitting(true);

    props.perform(values, formikProps).catch((e) => {
      snackbar.error(`Transfer failed: ${e.message}`);
    });

    formikProps.setSubmitting(false);
  };

  const onQrScanButtonClick = () => {
    setScannerEnabled(!scannerEnabled);
  };

  const onQrScanError = (error: any) => {
    snackbar.error(t("messages.qrScanner.failed", { message: error.message }));
  };

  const onQrScanCompleted = (result: string, setFieldValue: any) => {
    setFieldValue("address", result);
    snackbar.info(t("messages.qrScanner.completed"));
    setScannerEnabled(false);
  };

  const buildScanner = (setFieldValue: any) => {
    if (!scannerEnabled) return <></>;

    return (
      <QrScannerModal
        onScanned={(result) => onQrScanCompleted(result, setFieldValue)}
        onError={onQrScanError}
        onClose={onQrScanButtonClick}
      />
    );
  };

  return (
    <Formik
      initialValues={{ address: "" }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        isSubmitting,
        handleChange,
        handleSubmit,
        dirty,
        isValid,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <FormControl variant="outlined">
              <InputLabel>
                {t("forms.sendToken.fields.address.label")}
              </InputLabel>
              <OutlinedInput
                name="address"
                type="text"
                value={values.address}
                onChange={handleChange}
                error={!!errors.address}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={onQrScanButtonClick}>
                      <QrCodeScanner />
                    </IconButton>
                  </InputAdornment>
                }
                label={t("forms.sendToken.fields.address.label")}
                placeholder={t("forms.sendToken.fields.address.placeholder")}
              />
              {errors.address && (
                <FormHelperText error>{errors.address}</FormHelperText>
              )}
            </FormControl>
          </FormGroup>

          {buildScanner(setFieldValue)}
          <br />
          <ButtonGroup>
            <Button
              type="submit"
              variant="contained"
              disabled={!dirty || !isValid || isSubmitting}
            >
              {t("forms.transferKey.buttons.submit")}
            </Button>
            <Button variant="contained" color="error" onClick={props.onClose}>
              {t("forms.transferKey.buttons.cancel")}
            </Button>
          </ButtonGroup>
        </form>
      )}
    </Formik>
  );
};

export default TransferForm;
