import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import * as Crypto from "@waves/ts-lib-crypto";

import { IRootReducer } from "../../../reducers";
import { devicesFetched } from "../../../actions/devicesActions";
import Renderer from "./Renderer";
import LoadingNotification from "../../UI/LoadingNotification/LoadingNotification.component";
import DataServiceDisconnectedNotification from "../../UI/Notifications/DataServiceDisconnectedNotification.component";

import useStatusVerifier from "../../../hooks/useStatusVerifier";
import { useBlockchainNetwork } from "../../../providers/BlockchainNetworkProvider/BlockchainNetworkProvider";
import { useOrgManager } from "../../../providers/OrgManagerProvider/OrgManagerProvider";
import { useKeeper } from "../../../providers/KeeperProvider/KeeperProvider";
import useSnackbarNotifications from "../../../hooks/useSnackbarNotifications";

const DevicesTable = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dsReadService } = useBlockchainNetwork();
  const { publicState } = useKeeper();
  const [fetched, setFetched] = useState<boolean>(false);
  const snackbar = useSnackbarNotifications();

  const { data } = useSelector((state: IRootReducer) => state.devices);

  const { organisationAddress } = useOrgManager();
  const orgVerified = useStatusVerifier();

  const [currentAddress, setCurrentAddress] =
    useState<string | undefined>(organisationAddress);

  const fetchDevices = useCallback(
    (addr: string) => {
      if (!dsReadService) {
        setFetched(true);
        return;
      }

      const { request, controller } = dsReadService.getDevices({
        page: 1,
        perPage: 10,
        order: "asc",
        orderBy: "address",
        keysOwner: addr,
      });

      request
        .then(({ devices, meta }) => {
          dispatch(devicesFetched(devices, meta));
          setFetched(true);
        })
        .catch((e) => {
          snackbar.error(
            t("messages.devices.fetchFailed", {
              message: e.message,
            })
          );
        })
        .finally(() => {
          setFetched(true);
        });

      return () => {
        controller.abort();
      };
    },
    [dispatch, t, dsReadService, snackbar]
  );

  useEffect(() => {
    if (!orgVerified || !currentAddress) return;

    // validate if address is from this network
    const chainId = publicState?.network.code;
    if (!chainId || !Crypto.verifyAddress(currentAddress, { chainId })) return;

    fetchDevices(currentAddress);

    // eslint-disable-next-line
  }, [orgVerified, currentAddress, publicState?.network]);

  useEffect(() => {
    if (organisationAddress === currentAddress) return;

    setCurrentAddress(organisationAddress);
  }, [organisationAddress, currentAddress]);

  let content: JSX.Element = <LoadingNotification />;

  if (!dsReadService) {
    content = <DataServiceDisconnectedNotification />;
  } else if (fetched) {
    content = <Renderer devices={Object.values(data)} />;
  }

  return content;
};

export default DevicesTable;
