import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const useSkeyKeeper = !!process.env.REACT_APP_USE_SKEY_KEEPER;
const keeperName = useSkeyKeeper ? "Skey" : "Waves";

const Rejected: React.FC = (props) => {
  const { t } = useTranslation();

  return (
    <div style={{ textAlign: "center" }}>
      <Typography variant="h3">
        {t("keeperDetector.notFound.header", { keeper: keeperName })}
      </Typography>
      <Typography variant="h6">
        {t("keeperDetector.notFound.content1", { keeper: keeperName })}
      </Typography>
      {useSkeyKeeper ?? (
        <>
          <Typography variant="h6">
            {t("keeperDetector.notFound.content2", { keeper: keeperName })}
          </Typography>
          <a href="https://addons.mozilla.org/pl/firefox/addon/waves-keeper/">
            Firefox
          </a>
          <br />
          <a href="https://chrome.google.com/webstore/detail/waves-keeper/lpilbniiabackdjcionkobglmddfbcjo">
            Chrome
          </a>
        </>
      )}
    </div>
  );
};

export default Rejected;
