import CryptoJS from "crypto-js";

interface EncryptionParams {
  encryptionKey: string;
  iv?: string;
}

export const aes256encrypt = (message: string, params: EncryptionParams) => {
  const encryptionKey = CryptoJS.enc.Utf8.parse(params.encryptionKey);
  const iv = params.iv ? CryptoJS.enc.Utf8.parse(params.iv) : undefined;
  const enc = CryptoJS.AES.encrypt(message, encryptionKey, { iv: iv });
  return enc.ciphertext.toString(CryptoJS.enc.Base64);
};

export const aes256decrypt = (encMessage: string, params: EncryptionParams) => {
  const encryptionKey = CryptoJS.enc.Utf8.parse(params.encryptionKey);
  const iv = params.iv ? CryptoJS.enc.Utf8.parse(params.iv) : undefined;
  return CryptoJS.AES.decrypt(encMessage, encryptionKey, { iv: iv }).toString(
    CryptoJS.enc.Utf8
  );
};
