import React from "react";
import { useTranslation } from "react-i18next";

import DashboardItem from "./DashboardItem.component";
import DevicesMap from "./DevicesMap/DevicesMap.component";
import DevicesCard from "./Stats/DevicesCard/DevicesCard.component";
import KeysCard from "./Stats/KeysCard/KeysCard.component";
import UsersCard from "./Stats/UsersCard/UsersCard.component";
import { StatsProvider } from "../../providers/StatsProvider/StatsProvider";

const Dashboard = (props: any) => {
  const { t } = useTranslation();

  return (
    <StatsProvider>
      <DashboardItem xs={12} lg={6} xl={4}>
        <DevicesCard />
      </DashboardItem>
      <DashboardItem xs={12} lg={6} xl={4}>
        <KeysCard />
      </DashboardItem>
      <DashboardItem xs={12} lg={6} xl={4}>
        <UsersCard />
      </DashboardItem>
      <DashboardItem
        title={t("dashboard.devicesMap.title") ?? "Devices Map"}
        xs={12}
      >
        <DevicesMap />
      </DashboardItem>
    </StatsProvider>
  );
};

export default Dashboard;
