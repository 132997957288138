import { useMemo } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import usePublicAddress from "../../../../../hooks/usePublicAddress";

interface AccountVerifiedProps {
  onOrganisationSelected: (newAddress: string) => void;
}

const AccountVerified: React.FC<AccountVerifiedProps> = ({
  onOrganisationSelected,
}) => {
  const address = usePublicAddress();
  const { t } = useTranslation();

  const canContinue = useMemo(() => !!address, [address]);

  const onClickContinue = () => {
    if (!address) return;
    onOrganisationSelected(address);
  };

  return (
    <Grid container direction="column" justifyItems="stretch" spacing={1}>
      <Grid item>
        <Typography variant="body1">
          {t("orgSelector.accountStatus.verified.body1")}
        </Typography>
        <Typography variant="body1">
          {t("orgSelector.accountStatus.verified.body2")}
        </Typography>
      </Grid>

      <Grid item>
        <Button
          variant={canContinue ? "contained" : "outlined"}
          disabled={!canContinue}
          onClick={onClickContinue}
        >
          {canContinue
            ? t("orgSelector.accountStatus.verified.proceedButton")
            : t("common.loading")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default AccountVerified;
