import React from "react";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useEncryptionKey } from "../../../providers/OrgManagerProvider/EncryptionKeyProvider";
import useDebounce from "../../../hooks/useDebounce";
import { useOrgManager } from "../../../providers/OrgManagerProvider/OrgManagerProvider";
import useConcealEncryptionKey from "../../../hooks/useConcealEncryptionKey";
import useSnackbarNotifications from "../../../hooks/useSnackbarNotifications";

const EncryptionKeyCard = () => {
  const [newEncKeyPerforming, setNewEncKeyPerforming] =
    React.useState<boolean>(false);
  const { loading, keeperLocked, entriesPresent } = useEncryptionKey();
  const { accountType } = useOrgManager();
  const { saveNewEncryptionKey } = useConcealEncryptionKey();
  const { t } = useTranslation();
  const snackbar = useSnackbarNotifications();

  const debouncedLoading = useDebounce(loading, 500);

  const shouldDisplay: boolean = React.useMemo(() => {
    return !entriesPresent && !debouncedLoading;
  }, [debouncedLoading, entriesPresent]);

  const handleNewEncryptionKeyClicked = () => {
    setNewEncKeyPerforming(true);
    saveNewEncryptionKey()
      .then((res) => {
        snackbar.success("Encryption key set");
      })
      .catch((e) => {
        console.log("e", e);
        snackbar.error(e.message);
      })
      .finally(() => setNewEncKeyPerforming(false));
  };

  if (!shouldDisplay) return <></>;

  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          {t("encryptionKeyCard.title")}
        </Typography>
        <Typography variant="body1">{t("encryptionKeyCard.body")}</Typography>
        <Typography variant="body1">
          {t(`encryptionKeyCard.${accountType}.description`)}
        </Typography>
        {accountType === "admin" && (
          <>
            <br />
            <Button
              variant="outlined"
              disabled={newEncKeyPerforming || keeperLocked}
              onClick={handleNewEncryptionKeyClicked}
            >
              {t(
                keeperLocked
                  ? "messages.keeper.locked"
                  : "encryptionKeyCard.admin.setButtonLabel"
              )}
            </Button>
          </>
        )}
      </Paper>
    </Grid>
  );
};

export default EncryptionKeyCard;
