import { Tooltip, IconButton } from "@mui/material";
import { Send } from "@mui/icons-material";

import { Token } from "../../Token";
import { sendTokenInit } from "../../../../actions/tokensActions";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const SendToken: React.FC<{ token: Token }> = ({ token }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClicked = () => {
    dispatch(sendTokenInit(token));
  };

  return (
    <Tooltip title={t("buttons.tokens.send") || "Send"}>
      <IconButton
        key="send"
        color="primary"
        disabled={token.balance === 0}
        onClick={handleClicked}
      >
        <Send />
      </IconButton>
    </Tooltip>
  );
};

export default SendToken;
