import { useState, useEffect, useCallback, useMemo } from "react";
import { useBlockchainNetwork } from "../providers/BlockchainNetworkProvider/BlockchainNetworkProvider";
import { useKeeper } from "../providers/KeeperProvider/KeeperProvider";
import { useOrgManager } from "../providers/OrgManagerProvider/OrgManagerProvider";

const useDAppFatherVerification = (): {
  loading: boolean;
  verified: boolean;
} => {
  const [loading, setLoading] = useState<boolean>(true);
  const [verified, setVerified] = useState<boolean>(false);
  const { network } = useBlockchainNetwork();
  const { readService, publicState } = useKeeper();
  const { organisationAddress } = useOrgManager();

  const currentAddress = useMemo(() => {
    return organisationAddress;
  }, [organisationAddress]);

  const dAppFatherAddress = useMemo(() => {
    return network?.dappFather.address;
  }, [network]);

  const publicStateChainId = useMemo(() => {
    return publicState?.network?.code;
  }, [publicState?.network]);

  const verify = useCallback(() => {
    // Everything should be present
    if (!currentAddress || !dAppFatherAddress || !readService) {
      setLoading(false);
      setVerified(true);
      return;
    }

    // Selected network and network in publicState must match
    if (publicStateChainId !== network?.chainId) {
      return;
    }

    readService
      .getDAppFatherOrgEntry(dAppFatherAddress, currentAddress)
      .then((res: boolean) => {
        setVerified(res);
      })
      .catch((e: any) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    publicStateChainId,
    network,
    dAppFatherAddress,
    currentAddress,
    readService,
  ]);

  useEffect(() => {
    verify();
  }, [verify]);

  return { loading, verified };
};

export default useDAppFatherVerification;
