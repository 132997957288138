import { useCallback } from "react";
import { useKeeper } from "../providers/KeeperProvider/KeeperProvider";
import { useEncryptionKey } from "../providers/OrgManagerProvider/EncryptionKeyProvider";
import { aes256decrypt, aes256encrypt } from "../helpers/aes256";
import { useTranslation } from "react-i18next";
import { useOrgManager } from "../providers/OrgManagerProvider/OrgManagerProvider";

export const BASE_64_REGEXP = new RegExp(
  "^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$"
);
export const KEEPER_MESSAGE_REGEXP = new RegExp("^(?:[A-Za-z0-9]{0,})$");

type AvailableFormat = "base64" | "keeper";

export interface DecryptionResponse {
  format: AvailableFormat;
  message?: string;
  error?: any;
}

const useUserNoteEncryption = () => {
  const { encParams, keeperLocked, entriesPresent } = useEncryptionKey();
  const { encryptor } = useKeeper();
  const { organisationPublicKey } = useOrgManager();
  const { t } = useTranslation();

  const decryptBase64 = useCallback(
    (encryptedMessage: string): DecryptionResponse => {
      const response: DecryptionResponse = { format: "base64" };

      if (!encParams) {
        return {
          ...response,
          error: { message: t("messages.encryptionKey.notFound") },
        };
      }

      try {
        const message = aes256decrypt(encryptedMessage, {
          encryptionKey: encParams.encKey,
          iv: encParams.encIv,
        });

        return { ...response, message };
      } catch (e) {
        return { ...response, error: e };
      }
    },
    [encParams, t]
  );

  const decryptWithKeeper = useCallback(
    async (encryptedMessage: string): Promise<DecryptionResponse> => {
      const response: DecryptionResponse = { format: "keeper" };

      if (!encryptor) {
        return {
          ...response,
          error: { message: t("messages.keeper.unavailable") },
        };
      }

      try {
        const message = await encryptor.decrypt(encryptedMessage);

        return { ...response, message };
      } catch (e) {
        return { ...response, error: e };
      }
    },
    [encryptor, t]
  );

  const decrypt = useCallback(
    async (encryptedMessage: string): Promise<DecryptionResponse> => {
      if (keeperLocked) {
        return {
          format: "keeper",
          error: { message: t("messages.keeper.locked") },
        };
      }

      if (BASE_64_REGEXP.test(encryptedMessage)) {
        return decryptBase64(encryptedMessage);
      } else if (KEEPER_MESSAGE_REGEXP.test(encryptedMessage)) {
        return await decryptWithKeeper(encryptedMessage);
      } else {
        throw new Error("Unknown message format");
      }
    },
    [keeperLocked, t, decryptBase64, decryptWithKeeper]
  );

  const encryptWithKeeper = useCallback(
    async (message: string) => {
      if (!encryptor) throw new Error("Keeper not available");

      return await encryptor.encrypt(message, organisationPublicKey);
    },
    [encryptor, organisationPublicKey]
  );

  // Encrypt the message
  const encrypt = useCallback(
    async (message: string): Promise<string> => {
      if (!encParams) {
        // Encryption key is not available
        if (!entriesPresent) {
          // Encryption key is not set, use Keeper
          return await encryptWithKeeper(message);
        } else {
          // Encryption key is set but it's invalid, throw an error
          throw new Error("Invalid encryption key");
        }
      } else {
        // Encryption key is available
        return aes256encrypt(message, {
          encryptionKey: encParams.encKey,
          iv: encParams.encIv,
        });
      }
    },
    [encParams, encryptWithKeeper, entriesPresent]
  );

  return { decrypt, encrypt };
};

export default useUserNoteEncryption;
