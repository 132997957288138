import React from "react";
import { Button, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { TableOptions } from "../ITable";

const AddItemButton: React.FC<TableOptions> = (options) => {
  const {
    onAddItem,
    addItemButtonDisabled,
    addItemButtonLabel,
    addItemButtonTooltip,
  } = options;

  const button = (
    <Button
      variant="outlined"
      endIcon={<AddIcon />}
      size="large"
      sx={{ height: "40px", minWidth: "max-content", marginTop: "5px" }}
      onClick={onAddItem}
      disabled={addItemButtonDisabled}
    >
      {addItemButtonLabel ?? "Add Item"}
    </Button>
  );

  if (addItemButtonTooltip) {
    return (
      <Tooltip title={addItemButtonTooltip}>
        <span>{button}</span>
      </Tooltip>
    );
  }

  return button;
};

export default AddItemButton;
