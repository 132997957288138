import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import { Grid } from "@mui/material";
import { useLayout } from "../../../../providers/LayoutProvider/LayoutProvider";

interface Dataset {
  label: string;
  count?: number;
}

interface DevicesBooleanChartProps {
  compliant: Dataset;
  nonCompliant: Dataset;
}

const DevicesBooleanChart: React.FC<DevicesBooleanChartProps> = ({
  compliant,
  nonCompliant,
}) => {
  const { chartColors } = useLayout();

  const colors = chartColors.slice(0, 2);

  const data = {
    labels: [compliant.label, nonCompliant.label],
    datasets: [
      {
        data: [compliant.count ?? 0, nonCompliant.count ?? 0],
        backgroundColor: colors,
        borderColor: colors,
      },
    ],
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      <Grid item>
        <Doughnut
          data={data}
          options={{ responsive: true, maintainAspectRatio: false }}
        />
      </Grid>
    </Grid>
  );
};

export default DevicesBooleanChart;
