import { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { IRootReducer } from "../../../reducers";
import { keysFetched } from "../../../actions/keysActions";
import useStatusVerifier from "../../../hooks/useStatusVerifier";

import Renderer from "./Renderer";
import { useBlockchainNetwork } from "../../../providers/BlockchainNetworkProvider/BlockchainNetworkProvider";
import DataServiceDisconnectedNotification from "../../UI/Notifications/DataServiceDisconnectedNotification.component";
import { Key } from "../Key";
import LoadingNotification from "../../UI/LoadingNotification/LoadingNotification.component";
import { useOrgManager } from "../../../providers/OrgManagerProvider/OrgManagerProvider";

const KeysTable = () => {
  const dispatch = useDispatch();
  const { dsReadService } = useBlockchainNetwork();
  const [fetched, setFetched] = useState<boolean>(false);
  const [keys, setKeys] = useState<Key[]>([]);

  const { data } = useSelector((state: IRootReducer) => state.keys);
  const { organisationAddress } = useOrgManager();
  const statusVerified = useStatusVerifier();

  const handleFetchError = (e: any) => {
    setFetched(true);
  };

  const fetchKeysRequest = useCallback(() => {
    if (!dsReadService) return;

    const { request } = dsReadService.getKeys({
      page: 1,
      perPage: 10,
      order: "asc",
      orderBy: "assetId",
      filter: {
        owner: organisationAddress,
      },
    });

    return request;
  }, [organisationAddress, dsReadService]);

  const fetchKeys = useCallback(() => {
    if (!statusVerified) return;

    try {
      if (!fetchKeysRequest) return;
      // @ts-ignore
      fetchKeysRequest()
        .then(({ keys, meta }) => {
          dispatch(keysFetched(keys, meta));
          setFetched(true);
        })
        .catch((e) => {
          handleFetchError(e);
        });
    } catch (e) {
      handleFetchError(e);
      setFetched(true);
    }
  }, [fetchKeysRequest, statusVerified, dispatch]);

  useEffect(() => {
    fetchKeys();
  }, [fetchKeys]);

  useEffect(() => {
    setKeys(Object.values(data));
  }, [data]);

  let content: JSX.Element = <LoadingNotification />;

  if (!dsReadService) {
    content = <DataServiceDisconnectedNotification />;
  } else if (fetched) {
    content = <Renderer rows={keys} fetchKeys={fetchKeys} />;
  }

  return content;
};

export default KeysTable;
