import { Whatshot } from "@mui/icons-material";

import { useOrgManager } from "../../../../providers/OrgManagerProvider/OrgManagerProvider";
import { tokenBurned } from "../../../../actions/tokensActions";

import TokenAction from "./TokenAction.component";
import { Token } from "../../Token";

const BurnToken: React.FC<{ token: Token }> = ({ token }) => {
  const { writeService } = useOrgManager();

  const onClicked = async () => {
    return await writeService?.burnToken(token.assetId, token.balance);
  };

  return (
    <TokenAction
      actionKey="burn"
      onClicked={onClicked}
      onSuccessTlKey="messages.tokens.tokenBurned"
      onSuccessDispatchAction={tokenBurned(token)}
      tooltipTlKey="buttons.tokens.burn"
      color="error"
      Icon={Whatshot}
    />
  );
};

export default BurnToken;
