import { Grid, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { verifierChanged } from "../../../../../actions/wavesKeeperActions";

const AccountScriptOutdated: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onButtonClicked = () => {
    dispatch(verifierChanged("pendingTransaction"));
  };

  return (
    <Grid container direction="column" justifyItems="stretch" spacing={1}>
      <Grid item>
        <Typography variant="body1">
          {t("orgSelector.accountStatus.scriptOutdated.body")}
        </Typography>
      </Grid>

      <Grid item>
        <Button variant="contained" onClick={onButtonClicked}>
          {t("orgSelector.accountStatus.scriptOutdated.proceedButton")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default AccountScriptOutdated;
