import { Typography } from "@mui/material";
import { FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";

import TransferManyKeysForm, {
  ITransferForm,
} from "../../../Forms/TransferManyKeysForm/TransferManyKeysForm.component";

interface FormProps {
  onClose?: () => any;
  onFormSubmit: (
    values: ITransferForm,
    formikProps: FormikHelpers<ITransferForm>
  ) => Promise<any>;
}

const Form: React.FC<FormProps> = ({ onClose, onFormSubmit }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h5" sx={{ marginBottom: "10px" }}>
        {t("keys.modals.transferManyKeys.form.title") ?? "Transfer many keys"}
      </Typography>
      <TransferManyKeysForm onClose={onClose} perform={onFormSubmit} />
    </>
  );
};

export default Form;
