import { Typography, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useUserNoteEncryption, {
  DecryptionResponse,
} from "../../../../hooks/useUserNoteDecryption";
import { useEncryptionKey } from "../../../../providers/OrgManagerProvider/EncryptionKeyProvider";
import { User } from "../../User";

const UserNoteFormatter: React.FC<{ user: User }> = ({ user }) => {
  const { note, encNote } = user;
  const { t } = useTranslation();

  const [noteLoading, setNoteLoading] = useState<boolean>(false);
  const [decryptedNote, setDecryptedNote] = useState<DecryptionResponse>();

  const { decrypt } = useUserNoteEncryption();

  const { encParams } = useEncryptionKey();

  useEffect(() => {
    if (note || !encNote) return;

    setNoteLoading(true);

    decrypt(encNote)
      .then((response) => {
        setDecryptedNote(response);
        if (response.message) {
          user.note = response.message;
        }
      })
      .finally(() => {
        setNoteLoading(false);
      });
  }, [note, encNote, t, user, decrypt, encParams]);

  const content = React.useMemo(() => {
    if (noteLoading) {
      return (
        <Typography variant="subtitle2">
          <Skeleton />
        </Typography>
      );
    } else if (decryptedNote?.error) {
      return (
        <Typography variant="subtitle2" color="error">
          {t("messages.users.decryptFailed", {
            message: decryptedNote.error.message,
          })}
        </Typography>
      );
    } else if (user.note) {
      return <Typography variant="subtitle2">{user.note}</Typography>;
    } else {
      return <></>;
    }
  }, [decryptedNote?.error, noteLoading, t, user.note]);

  return content;
};

export default UserNoteFormatter;
