import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
  TablePagination,
  Box,
  IconButton,
  IconButtonProps,
  LabelDisplayedRowsArgs,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { TableInstanceProps } from "./ITable";

const CustomTableBottom: React.FC<TableInstanceProps> = (props) => {
  const { instance, mode, state } = props;
  const { t } = useTranslation();

  const previousPageProps = (): IconButtonProps => {
    if (mode === "local") {
      return {
        onClick: instance.previousPage,
        disabled: !instance.canPreviousPage,
      };
    } else {
      return {
        onClick: () =>
          props.onTableChange("pagination", {
            page: state.page - 1,
          }),
        disabled: state.page === 1,
      };
    }
  };

  const nextPageProps = (): IconButtonProps => {
    if (mode === "local") {
      return {
        onClick: instance.nextPage,
        disabled: !instance.canNextPage,
      };
    } else {
      return {
        onClick: () =>
          props.onTableChange("pagination", {
            page: state.page + 1,
          }),
        disabled: state.page * state.sizePerPage >= state.totalSize,
      };
    }
  };

  const onRowsPerPageChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    const num = Number(e.target.value);

    if (mode === "local") {
      instance.setPageSize(num);
    } else {
      props.onTableChange("pagination", { sizePerPage: num });
    }
  };

  const paginationProps = () => {
    if (mode === "local") {
      return {
        count: instance.rows.length,
        rowsPerPage: instance.state.pageSize,
        page: instance.state.pageIndex,
      };
    } else {
      return {
        count: state.totalSize,
        rowsPerPage: state.sizePerPage,
        page: state.totalSize === 0 ? 0 : state.page - 1,
      };
    }
  };

  const renderLabelDisplayedRows = (paginationInfo: LabelDisplayedRowsArgs) => {
    const { from, to, count } = paginationInfo;

    return `${from}-${to} ${t("tables.pagination.of")} ${
      count !== -1 ? count : t("tables.pagination.moreThan")
    }`;
  };

  return (
    <TablePagination
      {...paginationProps()}
      rowsPerPageOptions={[10, 25, 50, 100]}
      component="div"
      sx={{ "& p": { margin: 0 } }} // Override bootstrap styles
      ActionsComponent={() => (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
          <IconButton {...previousPageProps()}>
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton {...nextPageProps()}>
            <KeyboardArrowRight />
          </IconButton>
        </Box>
      )}
      onPageChange={() => {}}
      onRowsPerPageChange={onRowsPerPageChange}
      labelRowsPerPage={t("tables.pagination.rowsPerPage")}
      labelDisplayedRows={renderLabelDisplayedRows}
    />
  );
};

export default CustomTableBottom;
