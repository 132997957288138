import { useState, useMemo } from "react";
import { FormikHelpers, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { User } from "../../User";
import { userUpdated } from "../../../../actions/usersActions";
import { IRootReducer } from "../../../../reducers";
import QrScannerModal from "../../../Modals/QrScannerModal/QrScannerModal.component";
import { useKeeper } from "../../../../providers/KeeperProvider/KeeperProvider";
import { Box, Button, IconButton, TextField } from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import {
  buildValidationSchema,
  buildEditValidationSchema,
} from "./ValidationSchema";
import { useOrgManager } from "../../../../providers/OrgManagerProvider/OrgManagerProvider";
import { useEncryptionKey } from "../../../../providers/OrgManagerProvider/EncryptionKeyProvider";
import useUserNoteEncryption from "../../../../hooks/useUserNoteDecryption";
import useSnackbarNotifications from "../../../../hooks/useSnackbarNotifications";

const AddUserForm = (props: { handleClose: () => void }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data, user } = useSelector((state: IRootReducer) => state.users);
  const [scannerEnabled, setScannerEnabled] = useState<boolean>(false);
  const { publicState } = useKeeper();
  const { writeService } = useOrgManager();
  const snackbar = useSnackbarNotifications();
  const { encParams, entriesPresent, keeperLocked } = useEncryptionKey();
  const { encrypt } = useUserNoteEncryption();

  const action = user && user.address.length > 0 ? "edit" : "add";

  const chainId = useMemo(() => {
    return publicState?.network.code as string;
  }, [publicState?.network]);

  const validationSchema =
    action === "edit"
      ? buildEditValidationSchema(t, chainId)
      : buildValidationSchema(t, data, chainId);

  const encryptUserNote = async (
    values: User
  ): Promise<string | null | undefined> => {
    if (action === "add") {
      // When an User is added

      // Encrypt the note
      if (encParams && values.note && values.note.length > 0) {
        return await encrypt(values.note);
      } else {
        return undefined;
      }
    } else {
      // If encryption key is not set,
      if (!encParams) return undefined;

      if (typeof values.note !== "string") {
        return undefined;
      } else if (user?.note === values.note) {
        // note hasn't changed
        return user.encNote ?? undefined;
      } else if (values.note.length === 0) {
        // note has been emptied - that means user wants to delete the record
        return null;
      } else {
        // note has changed - return encrypted new note
        return await encrypt(values.note);
      }
    }
  };

  const prepareUserForm = async (values: User): Promise<User> => {
    // Default value of mobileId equals '?'
    if (!values.mobileId || values.mobileId === "") {
      values.mobileId = "?";
    }

    values.encNote = await encryptUserNote(values);

    return values;
  };

  const onSubmit = async (values: User, formikProps: FormikHelpers<User>) => {
    if (!writeService) return;
    if (values.note !== user?.note && keeperLocked) {
      // User wants to change note, but keeper is locked and note cannot be encrypted
      snackbar.error(t("messages.keeper.locked"));
      return;
    }
    formikProps.setSubmitting(true);

    try {
      values = await prepareUserForm(values);
    } catch (e) {
      formikProps.setSubmitting(false);
      return;
    }

    writeService
      .addUser(values)
      .then((res: User) => {
        snackbar.success(
          t(`messages.users.user${action === "add" ? "Added" : "Updated"}`)
        );
        dispatch(userUpdated(res));
        props.handleClose();
      })
      .catch((e: any) => {
        snackbar.error(
          t("messages.keeper.broadcastFailed", {
            message: e.message,
          })
        );
      });

    formikProps.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: user || { address: "", mobileId: "", note: "" },
    onSubmit,
    validationSchema,
  });

  const onQrScanCompleted = (result: string, setFieldValue: any) => {
    setFieldValue("address", result);
    snackbar.info(t("messages.qrScanner.completed"));
    setScannerEnabled(false);
  };

  const onQrScanError = (error: Error) => {
    snackbar.error(t("messages.qrScanner.failed", { message: error.message }));
    setScannerEnabled(false);
  };

  const onQrScanButtonClick = () => {
    setScannerEnabled(!scannerEnabled);
  };

  const buildScanner = (setFieldValue: any) => {
    if (!scannerEnabled) return <></>;

    return (
      <QrScannerModal
        onScanned={(result) => onQrScanCompleted(result, setFieldValue)}
        onError={onQrScanError}
        onClose={onQrScanButtonClick}
      />
    );
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      {action === "add" && (
        <Box sx={{ display: "flex" }}>
          <TextField
            fullWidth
            label={t("forms.user.fields.address.label")}
            placeholder={t("forms.user.fields.address.placeholder")}
            name="address"
            type="text"
            value={formik.values.address}
            onChange={formik.handleChange}
            error={!!formik.errors.address}
            helperText={formik.errors.address}
            sx={{ marginTop: "5px", display: "flex" }}
          ></TextField>
          <IconButton
            onClick={onQrScanButtonClick}
            sx={{ alignSelf: "flex-start", marginTop: "13px" }}
          >
            <QrCodeScannerIcon />
          </IconButton>
          {buildScanner(formik.setFieldValue)}
        </Box>
      )}

      <TextField
        label={t("forms.user.fields.mobileId.label")}
        placeholder={t("forms.user.fields.mobileId.placeholder")}
        name="mobileId"
        type="string"
        onChange={formik.handleChange}
        value={formik.values.mobileId}
        fullWidth
        sx={{ marginTop: "15px" }}
        error={!!formik.errors.mobileId}
        helperText={formik.errors.mobileId}
      />

      <TextField
        label={t("forms.user.fields.note.label")}
        placeholder={t("forms.user.fields.note.placeholder")}
        rows={3}
        minRows={3}
        name="note"
        type="string"
        onChange={formik.handleChange}
        value={formik.values.note}
        fullWidth
        sx={{ marginTop: "15px" }}
        error={!!formik.errors.note}
        disabled={!entriesPresent}
        helperText={
          !entriesPresent
            ? t("forms.user.fields.note.notAvailable")
            : formik.errors.note
        }
      />

      <Button
        type="submit"
        disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
        onClick={formik.submitForm}
        onSubmit={(e: React.FormEvent<HTMLButtonElement>) => e.preventDefault()}
        variant="outlined"
        sx={{ marginTop: "40px" }}
      >
        {t(`forms.${action}User.buttons.submit`)}
      </Button>
      <Button
        onClick={props.handleClose}
        variant="outlined"
        sx={{ marginTop: "40px" }}
      >
        {t(`buttons.close`)}
      </Button>
    </form>
  );
};

export default AddUserForm;
