// styles
import "./App.css";

// external libraries
import { Switch, Route, Redirect } from "react-router-dom";

// components
import Layout from "./components/Layout/Layout.component";
import Modals from "./components/Modals/Modals";
import Tokens from "./components/Tokens/Tokens.component";
import Users from "./components/Users/Users.component";
import Keys from "./components/Keys/Keys.component";
import Devices from "./components/Devices/Devices.component";
import Dashboard from "./components/Dashboard/Dashboard.component";
import Managers from "./components/Managers/Managers.component";
import { useOrgManager } from "./providers/OrgManagerProvider/OrgManagerProvider";
import OrganisationSelector from "./components/OrganisationSelector/OrganisationSelector";

function App() {
  const { organisationAddress, accountType } = useOrgManager();

  let content;

  if (organisationAddress) {
    content = (
      <Switch>
        <Route path="/tokens">
          <Tokens />
        </Route>

        <Route path="/users">
          <Users />
        </Route>

        <Route path="/keys">
          <Keys />
        </Route>

        <Route path="/devices">
          <Devices />
        </Route>

        <Route path="/managers">
          {accountType === "admin" ? <Managers /> : <Redirect to="/" />}
        </Route>

        <Route path="/" exact>
          <Dashboard />
        </Route>
      </Switch>
    );
  } else {
    content = <OrganisationSelector />;
  }

  return (
    <>
      <Modals />
      <div className="App">
        <Layout>{content}</Layout>
      </div>
    </>
  );
}

export default App;
