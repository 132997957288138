import React from "react";

import { Grid, Typography, Paper } from "@mui/material";
import { Organisation } from "../Organisations/Organisation";
import usePublicAddress from "../../hooks/usePublicAddress";
import { useBlockchainNetwork } from "../../providers/BlockchainNetworkProvider/BlockchainNetworkProvider";

import AccountStatus from "./components/AccountStatus/AccountStatus";
import ManualInput from "./components/ManualInput";
import OrganisationSelector from "./components/OrganisationSelector";
import { useOrgManager } from "../../providers/OrgManagerProvider/OrgManagerProvider";
import { useTranslation } from "react-i18next";

const OrganisationSelectorContainer: React.FC = () => {
  const { t } = useTranslation();

  const [organisations, setOrganisations] = React.useState<Organisation[]>([]);
  const [fetching, setFetching] = React.useState<boolean>(false);

  const manager = usePublicAddress();
  const { dsReadService } = useBlockchainNetwork();
  const { organisationAddress, setOrganisationAddress } = useOrgManager();

  const onOrganisationSelected = React.useCallback(
    (newAddress: string) => {
      if (newAddress === organisationAddress) return;
      setOrganisationAddress(newAddress);
    },
    [organisationAddress, setOrganisationAddress]
  );

  React.useEffect(() => {
    if (!dsReadService || !manager) return;
    setFetching(true);

    dsReadService
      .getManagedOrgainsation({
        page: 1,
        perPage: 20,
        manager,
        order: "asc",
        orderBy: "address",
      })
      .then(({ organisations }) => {
        setOrganisations(organisations);
      })
      .catch((e) => {
        console.error("Failed to fetch Organisations: ", e);
      })
      .finally(() => {
        setFetching(false);
      });
  }, [manager, dsReadService]);

  return (
    <Grid item xs={12}>
      <Grid container direction="column" alignItems="stretch" rowSpacing={2}>
        <Grid item>
          <Typography variant="h4">{t("orgSelector.title")}</Typography>
        </Grid>
        <Grid item>
          <Paper sx={{ p: 3 }}>
            <OrganisationSelector
              fetching={fetching}
              organisations={organisations}
              onOrganisationSelected={onOrganisationSelected}
            />
          </Paper>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            alignItems="stretch"
            spacing={2}
            justifyContent="center"
          >
            <Grid item xs={12} sm={6}>
              <Paper sx={{ p: 3 }}>
                <ManualInput
                  fetching={fetching}
                  onOrganisationSelected={onOrganisationSelected}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper sx={{ p: 3 }}>
                <AccountStatus
                  fetching={fetching}
                  onOrganisationSelected={onOrganisationSelected}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrganisationSelectorContainer;
