import { MoveDown } from "@mui/icons-material";

import { useOrgManager } from "../../../../providers/OrgManagerProvider/OrgManagerProvider";
import { tokenEntryDeactivated } from "../../../../actions/tokensActions";

import TokenAction from "../../TokensTable/Actions/TokenAction.component";
import { TokenEntry } from "../../Token";

const DeactivateTokenEntry: React.FC<{ entry: TokenEntry }> = ({ entry }) => {
  const { writeService } = useOrgManager();

  const onClicked = async () => {
    return await writeService?.setTokenStatus(entry.assetId, false);
  };

  return (
    <TokenAction
      key="deactivate"
      actionKey="deactivate"
      onClicked={onClicked}
      onSuccessTlKey="messages.tokens.tokenDeactivated"
      onSuccessDispatchAction={tokenEntryDeactivated(entry)}
      tooltipTlKey="buttons.tokens.deactivate"
      color="warning"
      Icon={MoveDown}
    />
  );
};

export default DeactivateTokenEntry;
