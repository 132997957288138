import React from "react";
import useConcealEncryptionKey from "../../hooks/useConcealEncryptionKey";
import useOrgEncryptionKeyChecker, {
  OrgEncryptionKeyCheckerResponse,
} from "../../hooks/useOrgEncryptionKeyChecker";
import { useKeeper } from "../KeeperProvider/KeeperProvider";

interface EncryptionKeyContextProps {
  entriesPresent: boolean;
  encParams?: { encKey: string; encIv?: string };
  keeperLocked: boolean;
  loading: boolean;
  checker: OrgEncryptionKeyCheckerResponse;
}

const EncryptionKeyContext = React.createContext<EncryptionKeyContextProps>(
  null as any
);

export const EncryptionKeyProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [encryptedEntries, setEncryptedEntries] =
    React.useState<{ encKey?: string; encIv?: string }>();
  const [encParams, setEncParams] =
    React.useState<{ encKey: string; encIv?: string }>();

  const { getEncryptedEncryptionKey, decryptEncryptionKeys } =
    useConcealEncryptionKey();
  const { publicState } = useKeeper();

  const keeperLocked = React.useMemo(() => {
    if (!publicState) return true;

    return publicState.locked;
  }, [publicState]);

  const checker = useOrgEncryptionKeyChecker({
    encParams,
    loading,
    keeperLocked,
  });

  const reset = () => {
    setLoading(false);
    setEncParams(undefined);
    setEncryptedEntries(undefined);
  };

  React.useEffect(() => {
    if (!encryptedEntries || !encryptedEntries.encIv) return;
    const { encKey, encIv } = encryptedEntries;

    decryptEncryptionKeys({ encKey, encIv })
      .then((res) => {
        setEncParams(res as any);
      })
      .catch((e) => {
        console.debug("[EncryptionKeyProvider] failed to decrypt: ", e);
        setEncParams(undefined);
      });
  }, [decryptEncryptionKeys, encryptedEntries]);

  React.useEffect(() => {
    setLoading(true);

    getEncryptedEncryptionKey()
      .then(({ encKey, encIv }) => {
        setEncryptedEntries({ encKey, encIv });
      })
      .catch((e) => {
        reset();
      })
      .finally(() => {
        setLoading(false);
      });
  }, [decryptEncryptionKeys, getEncryptedEncryptionKey]);

  return (
    <EncryptionKeyContext.Provider
      value={{
        encParams,
        loading,
        keeperLocked,
        checker,
        entriesPresent: !!encryptedEntries?.encKey,
      }}
    >
      {children}
    </EncryptionKeyContext.Provider>
  );
};

export const useEncryptionKey: () => EncryptionKeyContextProps = () =>
  React.useContext(EncryptionKeyContext);
