import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Token } from "../Token";
import { tokensToCollection } from "../helpers";
import {
  tokensFetched,
  tokenEntriesFetched,
} from "../../../actions/tokensActions";
import { IRootReducer } from "../../../reducers";
import { useTranslation } from "react-i18next";
import { useKeeper } from "../../../providers/KeeperProvider/KeeperProvider";
import { Column } from "react-table";
import { CellArgument } from "../../UI/MUITable/ITable";
import CustomTable from "../../UI/MUITable/CustomTable";
import { useOrgManager } from "../../../providers/OrgManagerProvider/OrgManagerProvider";
import TokenActions from "./TokenActions.component";
import ActiveChip from "../../UI/Chips/ActiveChip";

const TokensTable = (props: any) => {
  const { t } = useTranslation();

  const { tokens, tokenEntries } = useSelector(
    (state: IRootReducer) => state.tokens
  );
  const { organisationAddress } = useOrgManager();
  const dispatch = useDispatch();
  const { readService } = useKeeper();

  useEffect(() => {
    if (!organisationAddress || !readService) return;

    readService
      .fetchAllTokens(organisationAddress)
      .then(async (activeTokens) => {
        //fetch details about entries

        await readService
          .fetchAssetsInfo(Object.keys(activeTokens))
          .then((detailsArray) => {
            detailsArray.forEach((details) => {
              activeTokens[details.assetId].details = details;
            });
          });

        dispatch(tokenEntriesFetched(activeTokens));
      });

    readService
      .fetchAssets(organisationAddress)
      .then(async (tokens: Token[]) => {
        const collection = tokensToCollection(tokens);

        // fetch details about tokens

        await readService
          .fetchAssetsInfo(Object.keys(collection))
          .then((detailsArray) => {
            detailsArray.forEach((details) => {
              collection[details.assetId].details = details;
            });
          });

        dispatch(tokensFetched(collection));
      });
  }, [organisationAddress, dispatch, readService]);

  // const accessor = new Accessor<Token>();

  const columns: Column<Token>[] = [
    {
      id: "name",
      accessor: (token, _) => token.details?.name,
      Header: t("tables.tokens.columns.name") ?? "name",
    },
    {
      id: "description",
      accessor: (token, _) => token.details?.description,
      Header: t("tables.tokens.columns.description") ?? "description",
    },
    {
      accessor: "balance",
      Header: t("tables.tokens.columns.balance") ?? "balance",
    },
    {
      accessor: (token, _) => !!tokenEntries[token.assetId]?.active,
      id: "active",
      Header: t("tables.tokens.columns.active") ?? "active",
      Cell: (arg: CellArgument<Token>) => (
        <ActiveChip active={!!tokenEntries[arg.row.original.assetId]?.active} />
      ),
    },
    {
      id: "actions",
      Header: t("tables.tokens.columns.actions") ?? "actions",
      Cell: (arg: CellArgument<Token>) => (
        <TokenActions token={arg.row.original} />
      ),
    },
  ];

  return (
    <CustomTable
      mode="local"
      data={Object.values(tokens)}
      columns={columns}
      options={{
        addItemButton: true,
        addItemButtonLabel: t("buttons.tokens.create"),
        onAddItem: props.onAddItem,
      }}
    />
  );
};

export default TokensTable;
