import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip, ButtonGroup } from "@mui/material";
import { Whatshot, Send } from "@mui/icons-material";

import { Key } from "../../Key";
import { keyTransferInit } from "../../../../actions/keysActions";
import { keyBurned } from "../../../../actions/keysActions";
import { useOrgManager } from "../../../../providers/OrgManagerProvider/OrgManagerProvider";
import useSnackbarNotifications from "../../../../hooks/useSnackbarNotifications";

const ActionsFormatter = (props: { row: Key }): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { writeService } = useOrgManager();
  const snackbar = useSnackbarNotifications();

  const handleTransferButton = (): void => {
    dispatch(keyTransferInit(props.row));
  };

  const handleBurnButton = (): void => {
    if (!writeService) return;

    writeService
      .burnToken(props.row.assetId, 1)
      .then((res: any) => {
        dispatch(keyBurned(props.row));
        snackbar.success(t("messages.keys.keyBurned"));
      })
      .catch((e) => {
        snackbar.error(
          t("messages.keeper.broadcastFailed", { message: e.message })
        );
      });
  };

  const transferButton = (): JSX.Element => {
    return (
      <Tooltip
        id={`transfer-${props.row.assetId}`}
        key="transfer"
        title={t("buttons.keys.transfer") || "Transfer"}
      >
        <IconButton color="primary" onClick={handleTransferButton}>
          <Send />
        </IconButton>
      </Tooltip>
    );
  };

  const burnButton = (): JSX.Element => {
    return (
      <Tooltip
        id={`burn-${props.row.assetId}`}
        key="burn"
        title={t("buttons.keys.burn") || "Burn"}
      >
        <IconButton color="error" onClick={handleBurnButton}>
          <Whatshot />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <ButtonGroup size="small">{[transferButton(), burnButton()]}</ButtonGroup>
  );
};

export default ActionsFormatter;
