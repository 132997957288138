import { User } from "../../../components/Users/User";
import { AddManagerParams, TokenForm } from "..";
import * as Crypto from "@waves/ts-lib-crypto";
import * as CONSTANTS from "./constants";

export const DEFAULT_TRANSACTION_FEE = 0.009;

const defaultFee = () => ({
  tokens: DEFAULT_TRANSACTION_FEE,
  assetId: "",
});

export const burnTransactionData = (
  assetId: string,
  amount: number
): WavesKeeper.TBurnTxData => {
  return {
    type: 6,
    data: {
      assetId: assetId,
      amount: amount,
      fee: defaultFee(),
    },
  };
};

export interface TransferParams {
  assetId: string;
  amount: number;
  address: string;
}

export const dataTransactionData = (
  data: WavesKeeper.TData[]
): WavesKeeper.TDataTxData => {
  return {
    type: 12,
    data: {
      data,
      version: 2,
      fee: defaultFee(),
    },
  } as any;
};

export const transferTransactionData = (
  params: TransferParams
): WavesKeeper.TTransferTxData => {
  const { assetId, amount, address } = params;
  return {
    type: 4,
    data: {
      amount: {
        assetId: assetId,
        tokens: amount,
      },
      recipient: address,
      fee: defaultFee(),
    },
  };
};

export const setTokenStatus = (
  assetId: string,
  active: boolean
): WavesKeeper.TDataTxData => {
  return dataTransactionData([
    {
      type: "string",
      key: `token_${assetId}`,
      value: active ? "active" : "inactive",
    },
  ]);
};

export const activateUserData = (
  address: string,
  active: boolean
): WavesKeeper.TDataTxData => {
  return dataTransactionData([
    {
      key: `user_${address}`,
      value: active ? "active" : "inactive",
      type: "string",
    },
  ]);
};

export const addUserData = (user: User): WavesKeeper.TDataTxData => {
  const entries: WavesKeeper.TData[] = [
    {
      key: `user_${user.address}`,
      type: "string",
      value: user.mobileId,
    },
  ];

  switch (typeof user.encNote) {
    case "string":
      entries.push({
        key: `user_note_${user.address}`,
        type: "string",
        value: user.encNote as string,
      });
      break;
    case "object":
      if (user.encNote === null) {
        entries.push({
          key: `user_note_${user.address}`,
          value: null,
        } as any);
      }
      break;
  }

  return dataTransactionData(entries);
};

export const updateUserNote = (user: User): WavesKeeper.TDataTxData => {
  const entries: WavesKeeper.TData[] = [];

  return dataTransactionData(entries);
};

export const removeUserData = (address: string): WavesKeeper.TDataTxData => {
  return dataTransactionData([
    {
      key: `user_${address}`,
      value: null,
    },
    {
      key: `user_note_${address}`,
      value: null,
    },
  ] as any);
};

export const setScript = (script: string): WavesKeeper.TSetScriptTxData => {
  return {
    type: 13,
    data: {
      script,
      fee: {
        tokens: 0.014,
        assetId: "WAVES",
      },
    },
  };
};

export const issueToken = (form: TokenForm): WavesKeeper.TIssueTxData => {
  const { name, quantity, description, reissuable } = form;
  const fee: number = quantity === 1 && !reissuable ? 0.005 : 1.004;

  return {
    type: 3,
    data: {
      name,
      description,
      quantity,
      reissuable,
      precision: 0,
      fee: {
        assetId: "WAVES",
        tokens: fee,
      },
    },
  };
};

export const reissueToken = (
  token: string,
  amount: number
): WavesKeeper.TReissueTxData => {
  return {
    type: 5,
    data: {
      assetId: token,
      quantity: amount,
      reissuable: true,
      fee: {
        assetId: "WAVES",
        tokens: 0.001,
      },
    },
  };
};

export const addManager = (
  address: string,
  params: AddManagerParams
): WavesKeeper.TDataTxData => {
  const managerData: WavesKeeper.TData[] = [
    {
      key: `manager_${address}`,
      type: "binary",
      value: `base64:${Crypto.base64Encode(
        Crypto.base58Decode(params.publicKey)
      )}`,
    },
    {
      key: CONSTANTS.MANAGER_ENC_KEY_PREFIX + address,
      type: "string",
      value: params.encKey,
    },
  ];

  if (params.encIv) {
    managerData.push({
      key: CONSTANTS.MANAGER_ENC_IV_PREFIX + address,
      type: "string",
      value: params.encIv,
    });
  }

  return {
    type: 12,
    data: {
      data: managerData,
      fee: {
        assetId: "WAVES",
        tokens: 0.005,
      },
    },
  };
};

export const removeManager = (address: string): WavesKeeper.TDataTxData => {
  const keysToRemove: string[] = [
    `manager_${address}`,
    CONSTANTS.MANAGER_ENC_KEY_PREFIX + address,
    CONSTANTS.MANAGER_ENC_IV_PREFIX + address,
  ];

  return {
    type: 12,
    data: {
      version: 2,
      data: keysToRemove.map((key) => ({
        key,
        value: null,
      })) as any,
      fee: {
        assetId: "WAVES",
        tokens: 0.005,
      },
    } as any,
  };
};

export const exposePublicKey = (publicKey: string): WavesKeeper.TDataTxData => {
  console.log("[publishPublicKey] pk: ", publicKey);
  console.log(
    "[publishPublicKey] hashed pk: ",
    Crypto.base64Encode(Crypto.base58Decode(publicKey))
  );
  return {
    type: 12,
    data: {
      data: [
        {
          key: CONSTANTS.PUBLIC_KEY_DATA_ENTRY_KEY,
          type: "binary",
          value: `base64:${Crypto.base64Encode(
            Crypto.base58Decode(publicKey)
          )}`,
        },
      ],
      fee: {
        assetId: "WAVES",
        tokens: 0.005,
      },
    },
  };
};

export const send = (
  address: string,
  amount: number,
  assetId?: string
): WavesKeeper.TTransferTxData => {
  return {
    type: 4,
    data: {
      recipient: address,
      amount: {
        tokens: amount,
        assetId: assetId || "WAVES",
      },
      fee: defaultFee(),
    },
  };
};

export const massTransfer = (
  recipients: { address: string; amount: number }[],
  assetId?: string
): WavesKeeper.TMassTransferTxData => {
  const fee = 0.009 + recipients.length * 0.0005;

  const totalAmount = recipients
    .map((rec) => rec.amount as number)
    .reduce((acc, curr) => acc + curr);

  return {
    type: 11,
    data: {
      transfers: recipients.map((rec) => ({
        recipient: rec.address,
        amount: rec.amount,
      })),
      totalAmount: {
        assetId: assetId || "WAVES",
        amount: totalAmount,
      },
      fee: {
        tokens: fee,
        assetId: "WAVES",
      },
    },
  };
};

export const removeToken = (assetId: string): WavesKeeper.TDataTxData => {
  return dataTransactionData([
    {
      key: `token_${assetId}`,
      value: null,
    } as any,
  ]);
};

export const setEncryptionKeyData = (encKey: string, encIv?: string) => {
  const dataEntries: WavesKeeper.TData[] = [
    {
      key: CONSTANTS.ORG_ENC_KEY_DATA_ENTRY_KEY,
      type: "string",
      value: encKey,
    },
  ];

  if (encIv) {
    dataEntries.push({
      key: CONSTANTS.ORG_ENC_IV_DATA_ENTRY_KEY,
      type: "string",
      value: encIv,
    });
  }

  return dataTransactionData(dataEntries);
};

export const buildUpdateManagerEncryptionKeyDataEntries = (
  address: string,
  encKey: string,
  encIv?: string
): WavesKeeper.TData[] => {
  const entries: WavesKeeper.TData[] = [];

  entries.push({
    key: CONSTANTS.MANAGER_ENC_KEY_PREFIX + address,
    type: "string",
    value: encKey,
  });

  if (encIv) {
    entries.push({
      key: CONSTANTS.MANAGER_ENC_IV_PREFIX + address,
      type: "string",
      value: encIv,
    });
  }

  return entries;
};
