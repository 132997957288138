import { useSelector } from "react-redux";
import { ButtonGroup } from "@mui/material";

import { Token } from "../Token";
import { IRootReducer } from "../../../reducers";

import ActivateToken from "./Actions/ActivateToken.component";
import DeactivateToken from "./Actions/DeactivateToken.component";
import ReissueToken from "./Actions/ReissueToken.component";
import SendToken from "./Actions/SendToken.component";
import BurnToken from "./Actions/BurnToken.component";

interface TokenActionsProps {
  token: Token;
}

const TokenActions: React.FC<TokenActionsProps> = ({ token }) => {
  const { tokenEntries } = useSelector((state: IRootReducer) => state.tokens);
  const buttons: JSX.Element[] = [];

  const isActive = !!tokenEntries[token.assetId]?.active;

  buttons.push(<SendToken key="send" token={token} />);
  if (isActive) {
    buttons.push(<DeactivateToken key="deactivate" token={token} />);
  } else {
    buttons.push(<ActivateToken key="activate" token={token} />);
  }

  if (token.reissuable) {
    buttons.push(<ReissueToken key="reissue" token={token} />);
  }

  buttons.push(<BurnToken key="burn" token={token} />);

  return <ButtonGroup size="small">{buttons}</ButtonGroup>;
};

export default TokenActions;
