import React from "react";
import { ReportProblemOutlined } from "@mui/icons-material";

import Notification from "../Notification/Notification.component";

interface WarningNotificationProps {
  title: string;
}

const WarningNotification: React.FC<WarningNotificationProps> = ({ title }) => {
  return (
    <Notification
      title={title}
      Icon={ReportProblemOutlined}
      iconColor="warning"
    />
  );
};

export default WarningNotification;
