import { Account, createAccount } from "../../helpers/accountUtils";
import * as Transactions from "@waves/waves-transactions";
import * as Crypto from "@waves/ts-lib-crypto";

import * as CONSTANTS from "./helpers/constants";

export default class SkeyLibService {
  public nodeUrl: string;
  public chainId: string;

  constructor(public network: WavesKeeper.TPublicStateNetwork) {
    this.nodeUrl = network.server;
    this.chainId = network.code;
  }

  generateAccount(): Account {
    return createAccount(this.chainId);
  }

  async broadcast(tx: Transactions.TTx): Promise<string> {
    return await Transactions.broadcast(tx, this.nodeUrl, {
      credentials: "omit",
    }).then(async (tx) => {
      return await this.waitForConfirm(tx.id);
    });
  }

  async waitForConfirm(txId: string): Promise<string> {
    console.log("[waitForConfirm] init");
    return await Transactions.waitForTx(
      txId,
      {
        apiBase: this.nodeUrl,
      },
      { credentials: "same-origin" }
    )
      .then(() => {
        return txId;
      })
      .catch((e) => {
        console.error("[waitForConfirm] error: ", e);
        return Promise.reject(e);
      });
  }

  async exposePublicKey(account: Account) {
    const dataTx = Transactions.data(
      {
        data: [
          {
            key: CONSTANTS.PUBLIC_KEY_DATA_ENTRY_KEY,
            type: "binary",
            value: Crypto.base64Encode(Crypto.base58Decode(account.publicKey)),
          },
        ],
        chainId: this.chainId,
      },
      account.seed
    );

    return await this.broadcast(dataTx);
  }
}
