import {
  Button,
  ButtonGroup,
  FormGroup,
  TextField,
  // FormControlLabel,
  // Checkbox,
  // FormHelperText,
} from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Token } from "../../Token";
import { IRootReducer } from "../../../../reducers";
import buildValidationSchema from "./validationSchema";
import { tokenReissued } from "../../../../actions/tokensActions";

import { useTranslation } from "react-i18next";
import { useOrgManager } from "../../../../providers/OrgManagerProvider/OrgManagerProvider";
import useSnackbarNotifications from "../../../../hooks/useSnackbarNotifications";

export interface IReissueTokenForm {
  assetId: string;
  amount: number;
  reissuable: boolean;
}

function SendTokenForm(props: {
  handleSubmit?: () => void;
  handleClose?: () => void;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { writeService } = useOrgManager();
  const snackbar = useSnackbarNotifications();

  const reissueToken = useSelector(
    (state: IRootReducer) => state.tokens.reissueToken as Token
  );

  function onSubmit(
    values: IReissueTokenForm,
    formikProps: FormikHelpers<IReissueTokenForm>
  ) {
    if (!writeService) return;
    formikProps.setSubmitting(true);
    writeService
      .reissueToken(values.assetId, values.amount)
      .then((res: any) => {
        snackbar.success(t("messages.forms.reissueToken.tokenReissued"));
        if (props.handleSubmit) {
          props.handleSubmit();
        }
        const updadedToken: Token = {
          ...reissueToken,
          balance: reissueToken.balance + values.amount,
          reissuable: values.reissuable,
        };
        dispatch(tokenReissued(updadedToken));
      })
      .catch((e) => {
        snackbar.error(
          t("messages.keeper.broadcastFailed", { message: e.message })
        );
      });
    formikProps.setSubmitting(false);
  }

  return (
    <div>
      <Formik
        initialValues={{
          assetId: reissueToken.assetId,
          amount: 0,
          reissuable: true,
        }}
        onSubmit={onSubmit}
        validationSchema={buildValidationSchema(t)}
      >
        {({
          values,
          errors,
          isSubmitting,
          handleChange,
          handleSubmit,
          dirty,
          isValid,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <TextField
                  margin="normal"
                  name="amount"
                  type="number"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  label={t("forms.reissueToken.fields.amount.label")}
                  placeholder={t(
                    "forms.reissueToken.fields.amount.placeholder"
                  )}
                  value={values.amount}
                  onChange={handleChange}
                  error={!!errors.amount}
                  helperText={errors.amount}
                />
              </FormGroup>

              {/* <FormGroup>
                <FormControlLabel
                  label={t(
                    "forms.reissueToken.fields.reissuable.label"
                  ).toString()}
                  control={
                    <Checkbox
                      name="reissuable"
                      checked={values.reissuable}
                      onChange={(e) => {
                        setFieldValue("reissuable", e.target.checked);
                      }}
                    />
                  }
                />
                {errors.reissuable && (
                  <FormHelperText error>{errors.reissuable}</FormHelperText>
                )}
              </FormGroup> */}

              {/* <Form.Group as={Row} className="mb-3" controlId="amount">
                <Form.Label column sm={2}>
                  {t("forms.reissueToken.fields.amount.label")}
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="number"
                    placeholder={t(
                      "forms.reissueToken.fields.amount.placeholder"
                    )}
                    value={values.amount}
                    onChange={handleChange}
                    isInvalid={!!errors.amount}
                  />
                  {errors.amount && (
                    <Form.Control.Feedback type="invalid">
                      {errors.amount}
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="reissuable">
                <Col sm={{ span: 10, offset: 2 }}>
                  <Form.Check
                    type="checkbox"
                    checked={values.reissuable}
                    onChange={(e) =>
                      setFieldValue("reissuable", e.target.checked)
                    }
                    isInvalid={!!errors.reissuable}
                    label={t("forms.reissueToken.fields.reissuable.label")}
                  />
                  {errors.reissuable && (
                    <Form.Control.Feedback type="invalid">
                      {errors.reissuable}
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Form.Group> */}
              <br />
              <ButtonGroup>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!dirty || !isValid || isSubmitting}
                >
                  {t("forms.reissueToken.buttons.submit")}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={props.handleClose}
                >
                  {t("forms.reissueToken.buttons.close")}
                </Button>
              </ButtonGroup>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

export default SendTokenForm;
