import { AnyAction } from "redux";

import {
  USERS_FETCHED,
  USER_UPDATED,
  USER_REMOVED,
  USER_EDIT_INIT,
  USER_EDIT_CLOSED,
  USER_NOTES_CONVERTED,
} from "../actions/types";
import { User } from "../components/Users/User";

export interface IUsersReducer {
  data: { [key: string]: User };
  user?: User;
}

const initialValue: IUsersReducer = {
  data: {},
};

const updateUser = (state: IUsersReducer, updatedUser: User): IUsersReducer => {
  const usersCopy = { ...state.data };
  usersCopy[updatedUser.address] = updatedUser;

  return { ...state, data: usersCopy };
};

const removeUser = (state: IUsersReducer, removedUser: User): IUsersReducer => {
  const usersCopy = { ...state.data };
  delete usersCopy[removedUser.address];

  return { ...state, data: usersCopy };
};

const updateConvertedUserNotes = (
  state: IUsersReducer,
  result: {
    [address: string]: { encrypted: string; decrypted: string };
  }
): IUsersReducer => {
  const usersCopy = { ...state.data };

  Object.entries(result).forEach(([address, { encrypted, decrypted }]) => {
    if (!usersCopy[address]) return;

    const userCopy: User = { ...usersCopy[address] };

    userCopy.note = decrypted;
    userCopy.encNote = encrypted;

    usersCopy[address] = userCopy;
  });

  return { ...state, data: usersCopy };
};

const usersReducer = (state = initialValue, action: AnyAction) => {
  switch (action.type) {
    case USERS_FETCHED:
      return {
        ...state,
        data: action.payload.users,
      };
    case USER_UPDATED:
      return updateUser(state, action.payload.user);
    case USER_REMOVED:
      return removeUser(state, action.payload.user);
    case USER_EDIT_INIT:
      return { ...state, user: action.payload.user };
    case USER_EDIT_CLOSED:
      return { ...state, user: undefined };
    case USER_NOTES_CONVERTED:
      return updateConvertedUserNotes(state, action.payload.converted);
    default:
      return state;
  }
};

export default usersReducer;
