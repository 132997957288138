import { Grid } from "@mui/material";

interface TokenInfoTabProps {
  key: string;
  children?: React.ReactNode[];
}

const TokenInfoTab: React.FC<TokenInfoTabProps> = ({ children, key }) => {
  return (
    <Grid
      key={key}
      container
      direction="column"
      spacing={2}
      justifyContent="flex-start"
      alignItems="stretch"
      sx={{ minHeight: "800px" }}
    >
      {children?.map((child, index) => (
        <Grid item key={`tab-${index}`} xs={12}>
          {child}
        </Grid>
      ))}
    </Grid>
  );
};

export default TokenInfoTab;
