import { Box } from "@mui/material";
import React from "react";
import { TableInstanceProps } from "./ITable";
import AddItemButton from "./components/AddItemButton";
import SearchInput from "./components/SearchInput";

const CustomTableTop: React.FC<TableInstanceProps> = (props) => {
  const { options } = props;

  if (!options.addItemButton && !options.searchInput) return null;

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      {options.header}
      {options.addItemButton ? <AddItemButton {...options} /> : <span />}
      {options.searchInput ? <SearchInput {...props} /> : <span />}
    </Box>
  );
};

export default CustomTableTop;
