import React from "react";
import usePublicAddress from "../../hooks/usePublicAddress";
import { useKeeper } from "./KeeperProvider";
import { validateAddressChainId } from "../../helpers/helpers";

export interface AccountScriptContextState {
  script?: string;
  fetched: boolean;
  loading: boolean;
}

export const AccountScriptContext =
  React.createContext<AccountScriptContextState>({
    fetched: false,
    loading: false,
  });

export const AccountScriptProvider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<AccountScriptContextState>({
    fetched: false,
    loading: false,
  });

  const publicAddress = usePublicAddress();
  const { readService, publicState } = useKeeper();

  // Run this when PublicAddress or network changes
  React.useEffect(() => {
    setState({ script: undefined, fetched: false, loading: true });

    // make sure both PublicAddress and network are selected
    if (!readService || !publicAddress) {
      setState((oldState) => ({ ...oldState, loading: false }));
      return;
    }

    // Make sure current PublicAddress is in correct network
    if (
      publicState?.network &&
      !validateAddressChainId(publicAddress, publicState.network.code)
    ) {
      setState((oldState) => ({ ...oldState, loading: false }));
      return;
    }

    // Fetch the script
    readService
      .fetchAccountScript(publicAddress)
      .then((script) => {
        setState({
          script: script ?? undefined,
          loading: false,
          fetched: true,
        });
      })
      .catch((e) => {
        setState({ script: undefined, loading: false, fetched: true });
      });
    //eslint-disable-next-line
  }, [readService, publicAddress]);

  return (
    <AccountScriptContext.Provider value={state}>
      {children}
    </AccountScriptContext.Provider>
  );
};

export const useAccountScript = () => React.useContext(AccountScriptContext);
