// PREFIXES
export const USER_PREFIX = "user_";
export const USER_NOTE_PREFIX = "user_note_";
export const TOKEN_PREFIX = "token_";
export const MANAGER_PREFIX = "manager_";
export const MANAGER_ENC_KEY_PREFIX = "manager_enc_key_";
export const MANAGER_ENC_IV_PREFIX = "manager_enc_iv_";

// ENTRY KEYS
export const PUBLIC_KEY_DATA_ENTRY_KEY = "publicKey";
export const ORG_ENC_KEY_DATA_ENTRY_KEY = "enc_key";
export const ORG_ENC_IV_DATA_ENTRY_KEY = "enc_key_iv";
