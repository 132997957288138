import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import * as Crypto from "@waves/ts-lib-crypto";

import {
  TokenEntry,
  TokenEntryCollection,
} from "../../components/Tokens/Token";
import { DataEntry } from "../../api/blockchain/blockchain";
import { User } from "../../components/Users/User";
import { AssetDetails } from "../../components/Tokens/Token";
import {
  MANAGER_PREFIX,
  PUBLIC_KEY_DATA_ENTRY_KEY,
} from "../OrgManagerProvider/helpers/constants";
import { base58BinaryEntryToString } from "../../helpers/helpers";

export default class KeeperReadService {
  private serverUrl: string;
  private axiosInstance: AxiosInstance;

  constructor(serverUrl: string) {
    this.serverUrl = serverUrl;
    this.axiosInstance = axios.create({
      baseURL: this.nodeUrl,
    });
  }

  get nodeUrl(): string {
    return this.serverUrl + (this.serverUrl.match("/$") ? "" : "/");
  }

  async fetchAddressData(
    address: string,
    matches?: string,
    options?: AxiosRequestConfig
  ) {
    return this.axiosInstance
      .get(`addresses/data/${address}`, {
        ...options,
        params: { matches },
      })
      .then((res: any) => {
        const dataEntries: DataEntry[] = res.data;

        return dataEntries;
      });
  }

  async fetchBalance(address: string): Promise<number | undefined> {
    return await this.axiosInstance
      .get(`addresses/balance/${address}`)
      .then((res) => {
        return res.data.balance as number;
      })
      .catch((e) => {
        return undefined;
      });
  }

  async fetchAssetInfo(assetId: string): Promise<AssetDetails> {
    return await this.axiosInstance
      .get(`assets/details/${assetId}`)
      .then((res: AxiosResponse<AssetDetails>) => res.data);
  }

  async fetchAssetsInfo(assetIds: string[]): Promise<AssetDetails[]> {
    const params = new URLSearchParams();

    assetIds.forEach((assetId) => {
      params.append("id", assetId);
    });

    return await this.axiosInstance
      .get("assets/details", { params })
      .then((res: AxiosResponse<AssetDetails[]>) => res.data);
  }

  async fetchAccountScript(address: string): Promise<string | null> {
    return await this.axiosInstance
      .get(`addresses/scriptInfo/${address}`)
      .then((res) => {
        return res.data.script;
      });
  }

  async fetchAssets(address: string): Promise<any[]> {
    return await this.axiosInstance
      .get(`assets/balance/${address}`)
      .then((res) => {
        return res.data.balances;
      });
  }

  async fetchAllTokens(address: string): Promise<TokenEntryCollection> {
    return await this.axiosInstance
      .get(`addresses/data/${address}`, {
        params: {
          matches: "token_.{32,44}",
        },
      })
      .then((res: any) => {
        const dataEntries: DataEntry[] = res.data;

        const collection: TokenEntryCollection = {};

        dataEntries.forEach((entry: DataEntry) => {
          const tokenEntry: TokenEntry = {
            assetId: entry.key.replace("token_", ""),
            active: entry.value === "active",
          };
          collection[tokenEntry.assetId] = tokenEntry;
        });

        return collection;
      });
  }

  async getActiveTokens(address: string): Promise<TokenEntryCollection> {
    return await this.fetchAllTokens(address).then(
      (res: TokenEntryCollection) => {
        const collection: TokenEntryCollection = {};
        Object.values(res)
          .filter((rec) => rec.active)
          .forEach((rec) => {
            collection[rec.assetId] = rec;
          });

        return collection;
      }
    );
  }

  async fetchCurrentHeight(): Promise<number> {
    return await this.axiosInstance.get(`blocks/height`).then((res: any) => {
      return res.data.height;
    });
  }

  async getAssetDistribution(assetId: string, after?: string): Promise<object> {
    const currentHeight = await this.fetchCurrentHeight();

    const url = `assets/${assetId}/distribution/${
      currentHeight - 1
    }/limit/1000`;

    return await this.axiosInstance
      .get(url, { params: { after } })
      .then((res: any) => {
        return res.data.items;
      });
  }

  async getUsers(address: string): Promise<{ [key: string]: User }> {
    return await this.fetchAddressData(
      address,
      "user_.{35}|user_note_.{35}"
    ).then(async (dataEntries) => {
      const users: { [key: string]: User } = {};
      const notes: { [key: string]: string } = {};

      dataEntries.forEach((value: DataEntry) => {
        if (value.key.match("user_note")) {
          const address = value.key.replace("user_note_", "");
          notes[address] = String(value.value);
        } else {
          const address = value.key.replace("user_", "");
          users[address] = {
            address,
            mobileId: String(value.value),
          };
        }
      });

      Object.entries(users).forEach(([address, user]) => {
        if (notes[address]) {
          user.encNote = notes[address];
        }
      });

      return users;
    });
  }

  async getUserNotes(address: string): Promise<{ [key: string]: string }> {
    return await this.fetchAddressData(address, "user_note_.{35}").then(
      (dataEntries) => {
        const notes: { [key: string]: string } = {};

        dataEntries.forEach((value: DataEntry) => {
          const address = value.key.replace("user_note_", "");
          notes[address] = value.value as string;
        });

        return notes;
      }
    );
  }

  async getDAppFatherOrgEntry(
    dappFatherAddress: string,
    orgAddress: string
  ): Promise<boolean> {
    return await this.fetchAddressData(
      dappFatherAddress,
      `org_${orgAddress}`
    ).then((dataEntries) => {
      return dataEntries.length > 0;
    });
  }

  async findManager(input: string, managerAddress: string): Promise<boolean> {
    return (
      (await this.findManagerFromAddress(input, managerAddress)) ||
      (await this.findManagerFromAlias(input, managerAddress))
    );
  }

  async findManagerFromAddress(
    orgAddress: string,
    managerAddress: string
  ): Promise<boolean> {
    return await this.fetchAddressData(
      orgAddress,
      MANAGER_PREFIX + managerAddress
    )
      .then((dataEntries) => {
        return dataEntries.length > 0;
      })
      .catch((e) => {
        return false;
      });
  }

  async findManagerFromAlias(
    orgAlias: string,
    managerAddress: string
  ): Promise<boolean> {
    const aliasAddress = await this.findAddressFromAlias(orgAlias);
    if (!aliasAddress) return false;

    return await this.findManagerFromAddress(aliasAddress, managerAddress);
  }

  /**
   * Get addresses of managers of an Organisation
   *
   * @param orgAddress - Organisation address
   *
   * @return Addresses of managers
   */
  async fetchManagers(
    orgAddress: string,
    options?: AxiosRequestConfig
  ): Promise<{ [key: string]: string }> {
    return await this.fetchAddressData(
      orgAddress,
      `${MANAGER_PREFIX}.{35}`,
      options
    )
      .then((dataEntries) => {
        return Object.fromEntries(
          dataEntries.map((e: DataEntry) => {
            if (e.type !== "binary") return [];

            return [
              e.key.replace(MANAGER_PREFIX, ""),
              base58BinaryEntryToString(e.value as BigInt64Array),
            ];
          })
        );
      })
      .catch((e) => {
        return {};
      });
  }

  async findAddressFromAlias(alias: string): Promise<string | undefined> {
    return await this.axiosInstance
      .get(`alias/by-alias/${alias}`)
      .then((res) => {
        const { address } = res.data;
        return address;
      })
      .catch((e) => {
        return undefined;
      });
  }

  async fetchPublicKey(address: string): Promise<string | undefined> {
    return await this.fetchAddressData(
      address,
      `^${PUBLIC_KEY_DATA_ENTRY_KEY}$`
    ).then((dataEntries) => {
      if (dataEntries.length === 0) return undefined;

      const entry = dataEntries[0];

      if (entry.type !== "binary") return undefined;

      const value = entry.value.toString().replace("base64:", "");
      return Crypto.base58Encode(Crypto.base64Decode(value));
    });
  }
}
