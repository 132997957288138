import Modal from "../../UI/Modal/Modal.component";
import { useSelector, useDispatch } from "react-redux";
import { IRootReducer } from "../../../reducers";
import { reissueTokenCancel } from "../../../actions/tokensActions";
import ReissueTokenForm from "../../Tokens/Forms/ReissueTokenForm/ReissueTokenForm.component";
import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

const ReissueTokenModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { reissueToken } = useSelector((state: IRootReducer) => state.tokens);

  let content = null;
  let footer = null;

  const onClose = () => {
    dispatch(reissueTokenCancel());
  };

  if (reissueToken) {
    content = (
      <React.Fragment>
        <Typography variant="h4">
          {t("forms.reissueToken.header.title")}
        </Typography>
        <Typography variant="h6">
          {t("forms.reissueToken.header.description", {
            token: reissueToken.details?.name ?? "",
          })}
        </Typography>
        <ReissueTokenForm handleClose={onClose} />
      </React.Fragment>
    );
  }

  return (
    <Modal
      closable
      show={reissueToken !== undefined}
      footer={footer}
      handleClose={onClose}
    >
      {content}
    </Modal>
  );
};

export default ReissueTokenModal;
